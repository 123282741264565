import React, { createContext, useState } from 'react'

export const ErrorContext = createContext()

const ErrorProvider = ({ children }) => {
  const [error, setError] = useState({})

  const clearError = errorKey => {
    setError({
      ...error,
      data: {
        ...(error.data ? error.data : {}),
        data: {
          ...(error.data && error.data.data ? error.data.data : {}),
          [errorKey]: null
        }
      }
    })
  }

  const addError = (errorKey, message) => {
    setError({
      ...error,
      data: {
        ...(error.data ? error.data : {}),
        data: {
          ...(error.data && error.data.data ? error.data.data : {}),
          [errorKey]: message
        }
      }
    })
  }

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
        clearError,
        addError
      }}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
