import './EventResult.scss'
import React, { useState } from 'react'
import moment from 'moment-timezone'

// Hooks
import { useCheckIn } from 'misc/api'
import { Trans, useTranslation } from 'react-i18next'

// Components
import ActiveEventHeader from 'components/ActiveEventHeader'
import AccordionTable from 'components/AccordionTable'
import EmptyState from 'components/AccordionTable/EmptyState'
import Wrapper from 'components/Wrapper'

// Component specific hook
const useSearch = () => {
  const [search, setSearch] = useState('')

  const checkGuests = guests => {
    const matchingGuests = guests.filter(guest => checkGuestData(guest))
    return matchingGuests.length > 0
  }

  const checkGuestData = guest =>
    Object.values(guest.guest_data).some(value =>
      typeof value === 'string'
        ? value.toLowerCase().includes(search.toLowerCase())
        : false
    )

  const filterGuests = parties =>
    search.length === 0
      ? parties
      : parties.filter(party => checkGuests(party.guests))

  const state = { search }
  const handlers = { setSearch, filterGuests }
  return [state, handlers]
}

// Component
const EventResult = ({
  deactivateEvent,
  event,
  token,
  lead_capture_token,
  publishEvent,
  fetchEvent
}) => {
  const { t } = useTranslation()

  const [
    { parties, checkinUrl, fields },
    { updateParty, cancelParty }
  ] = useCheckIn(event, fetchEvent.bind(null, event.event))
  const [{ search }, { setSearch, filterGuests }] = useSearch()

  const updateState = party =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => updateParty(party))

  const updateCancelledState = party =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(party)
      }, 150)
    }).then(party => cancelParty(party))

  const toggleCancelGuest = (guestId, partyId) => {
    const party = parties.find(p => p.id === partyId)
    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    // Handles cancel and undo state on guest
    const guest = party.guests[guestIndex]
    party.guests[guestIndex].canceled = !guest.canceled
    updateCancelledState(party)
  }

  const deleteGuest = (guestId, partyId) => {
    const party = parties.find(party => party.id === partyId)
    const guestIndex = party.guests.map(guest => guest.id).indexOf(guestId)

    party.guests.splice(guestIndex, 1)
    updateState(party)
  }

  const cancelPartyHandler = partyId => {
    const party = parties.find(party => party.id === partyId)

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: true
      }))
    })
  }

  const restorePartyHandler = partyId => {
    const party = parties.find(party => party.id === partyId)

    updateCancelledState({
      ...party,
      guests: party.guests.map(guest => ({
        ...guest,
        canceled: false
      }))
    })
  }

  const emptyStateMessage =
    event.state === 'DRAFT' ? (
      <Trans
        i18nKey="eventNotActive"
        values={{
          draft: t('states.DRAFT.headingActive').toUpperCase(),
          open: t('states.ACTIVE.headingDraft').toUpperCase()
        }}>
        The event is inactive, change event status from{' '}
        <span className="EventMark">draft</span> to{' '}
        <span className="EventMark">open</span> to start collecting sign-ups
      </Trans>
    ) : parties.length === 0 ? (
      t('noSignupsText')
    ) : (
      t('noMatchingGuestsText')
    )

  const EventResultSection = ({ guestlist }) => {
    const partiesInGuestlist = parties.filter(
      party => party.guestlist === guestlist.id
    )
    const filteredGuests = filterGuests(partiesInGuestlist)
    const numberOfAttendingGuests = partiesInGuestlist.reduce(
      (acc, party) => acc + party.guests.filter(g => !g.canceled).length,
      0
    )

    return (
      <section className="EventResultSection">
        {event.timeslots_enabled ? (
          <header className="EventResultHeader">
            <div className="EventResultData">
              {guestlist.name ? (
                <h3 className="EventResultName">{guestlist.name}</h3>
              ) : null}

              <div>
                <time
                  className="EventResultTime"
                  dateTime={guestlist.start_time}>
                  {moment
                    .parseZone(guestlist.start_time)
                    .format('YYYY-MM-DD, HH:mm')}
                </time>
                –
                <time className="EventResultTime" dateTime={guestlist.end_time}>
                  {moment.parseZone(guestlist.end_time).format('HH:mm')}
                </time>
              </div>
            </div>

            <div className="EventResultMeta">
              <div className="EventResultMetaColumn">
                <div className="EventResultMetaNumber">
                  {numberOfAttendingGuests}
                </div>
                {t('guests')}
              </div>
              <div className="EventResultMetaColumn">
                <div className="EventResultMetaNumber">
                  {guestlist.guest_max_limit ||
                    '–' /* null and 0 should render a dash */}
                </div>
                {t('guest limit')}
              </div>
            </div>
          </header>
        ) : null}

        {filteredGuests.length > 0 ? (
          <AccordionTable
            event={event}
            fields={fields}
            parties={filteredGuests}
            toggleCancelGuest={toggleCancelGuest}
            cancelParty={cancelPartyHandler}
            restoreParty={restorePartyHandler}
            deleteGuest={deleteGuest}
          />
        ) : (
          <EmptyState
            message={t(
              partiesInGuestlist.length > 0 && search
                ? 'no results'
                : 'empty guestlist'
            )}
          />
        )}
      </section>
    )
  }

  return (
    <div className="EventResult">
      <ActiveEventHeader
        event={event}
        token={token}
        lead_capture_token={lead_capture_token}
        parties={parties}
        checkinUrl={checkinUrl}
        deactivateEvent={deactivateEvent}
        publishEvent={publishEvent}
        handleSearchInput={e => setSearch(e.target.value)}
        search={search}
      />
      <Wrapper className="EventResultWrapper">
        {parties.length === 0 ? (
          <EmptyState message={emptyStateMessage} />
        ) : (
          <>
            <header className="AccordionHeader">
              <h1>{t('guestlist')}</h1>
            </header>

            {event.guestlists.map((guestlist, i) => (
              <EventResultSection key={i} guestlist={guestlist} />
            ))}
          </>
        )}
      </Wrapper>
    </div>
  )
}

export default EventResult
