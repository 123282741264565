import React from 'react'
import './ErrorPage.scss'
import { useTranslation } from 'react-i18next'

function ErrorPage({ status, message }) {
  const { t } = useTranslation()

  return (
    <section className="ErrorPage">
      <div className="ErrorPageBody">
        <h1 className="ErrorPageHeading">{status || t('errorPage.heading')}</h1>

        <p className="ErrorPageDescription">
          {message || t('errorPage.description')}
        </p>
      </div>
    </section>
  )
}

export default ErrorPage
