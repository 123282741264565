import React, { useState, useEffect } from 'react'
import './Authorization.scss'

import { Redirect } from 'react-router-dom'

import { useAuth } from 'components/Contexts/AuthProvider'

import Loader from 'components/Loader'

export const Authorization = () => {
  const [state, setState] = useState({
    loading: true,
    isAuthenticated: false
  })

  const { fetchBearerToken } = useAuth()

  useEffect(() => {
    fetchBearerToken(document.location.search)
      .then(({ status }) => {
        setState({
          ...state,
          loading: false,
          isAuthenticated: true
        })
      })
      .catch(e => {
        setState({
          ...state,
          loading: false
        })
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, isAuthenticated } = state

  if (loading) {
    return (
      <div className="Authorization">
        <Loader size="medium" />
      </div>
    )
  }

  return <Redirect to={isAuthenticated ? '/' : '/login'} />
}

export default Authorization
