import './EventSettings.scss'
import React from 'react'
import { NavLink, Route, Switch, Redirect, withRouter } from 'react-router-dom'

// Settings routes
import SettingsGeneral from 'components/Settings/General'
import SettingsConfirmationEmail from 'components/Settings/ConfirmationEmail'
import SettingsSmsDashboard from 'components/Settings/SmsDashboard'
import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import { UndoContext } from 'components/Contexts/UndoProvider'

import { useTranslation } from 'react-i18next'

const MenuItem = ({ to, children }) => (
  <NavLink activeClassName="active" className="MenuItem" to={to}>
    {children}
  </NavLink>
)

const withContext = Component => (
  <UndoContext.Consumer>
    {props => <Component {...props} />}
  </UndoContext.Consumer>
)

const EventSettings = ({ id, event }) => {
  const { t } = useTranslation()
  const { settings } = React.useContext(OrganizationContext)

  const settingsRoutes = [
    {
      to: `/event/${id}/settings/general`,
      label: t('general'),
      component: withContext(SettingsGeneral)
    },
    {
      to: `/event/${id}/settings/confirmation-email`,
      label: t('confirmation email'),
      component: withContext(SettingsConfirmationEmail)
    },
    ...(settings?.sms?.enabled
      ? [
          {
            to: `/event/${id}/settings/sms`,
            label: t('SMS'),
            component: <SettingsSmsDashboard eventId={id} event={event} />
          }
        ]
      : [])
  ]

  return (
    <div className="EventSettings">
      <div className="Sidebar">
        <div className="HorizontalMenu">
          {settingsRoutes &&
            settingsRoutes.map(r => (
              <MenuItem key={r.to} to={r.to}>
                {r.label}
              </MenuItem>
            ))}
        </div>
      </div>
      <div className="SettingsContainer">
        <div className="track">
          <Switch>
            {settingsRoutes &&
              settingsRoutes.map(r => (
                <Route key={r.to} path={r.to} render={() => r.component} />
              ))}
            <Route
              exact
              path={`/event/${id}/settings`}
              render={() => <Redirect to={`/event/${id}/settings/general`} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withRouter(EventSettings)
