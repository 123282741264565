import './InputContainer.scss'
import clsx from 'clsx'
import React from 'react'

const InputContainer = ({ id, label, children, className }) => (
  <div className={clsx('InputContainer', className)}>
    <label htmlFor={id}>{label}</label>
    {children}
  </div>
)

export default InputContainer
