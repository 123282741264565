import './TimeSlot.scss'
import React, { useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import { GuestCount } from 'components/GuestToggle'
import Text from 'components/Inputs/Text'
import DatePicker from 'components/DateTimePicker/DatePicker'
import TimePicker from 'components/DateTimePicker/TimePicker'
import { TIME_STEP } from 'misc/calendar'

const TimeSlot = ({
  className,
  guestlist,
  updateGuestlist,
  handleChange,
  handleClickTime,
  handleDateClick,
  isOnly,
  removeTimeSlot
}) => {
  const { t } = useTranslation()

  const [dateOpen, setDateOpen] = useState(false)
  const [startTimeOpen, setStartTimeOpen] = useState(false)
  const [endTimeOpen, setEndTimeOpen] = useState(false)

  const createTimeSlotColumn = ([label, component], i) => (
    <div className="TimeSlotColumn" key={i}>
      <label className="TimeSlotLabel">{t(label)}</label>
      {component}
    </div>
  )

  const onStartTimeChange = value => {
    handleClickTime('start_time')(value)

    const startUtc = moment.parseZone(value).unix()
    const endUtc = moment(guestlist.end_time).unix()

    if (startUtc >= endUtc) {
      updateGuestlist({
        start_time: value,
        end_time: moment
          .parseZone(value)
          .add(TIME_STEP, 'minutes')
          .format()
      })
    }
  }

  const onEndTimeChange = value => {
    handleClickTime('end_time')(value)

    const endUtc = moment.parseZone(value).unix()
    const startUtc = moment(guestlist.start_time).unix()

    if (startUtc >= endUtc) {
      updateGuestlist({
        start_time: moment
          .parseZone(value)
          .subtract(TIME_STEP, 'minutes')
          .format(),
        end_time: value
      })
    }
  }

  return (
    <div className={clsx('TimeSlot', className)}>
      <button
        type="button"
        className="TimeSlotRemove"
        disabled={isOnly}
        onClick={removeTimeSlot}>
        <svg width="20px" height="20px" viewBox="0 0 20 20">
          <path d="M10,8.67768595 L15.9504132,2.72727273 L17.2727273,4.04958678 L11.322314,10 L17.2727273,15.9504132 L15.9504132,17.2727273 L10,11.322314 L4.04958678,17.2727273 L2.72727273,15.9504132 L8.67768595,10 L2.72727273,4.04958678 L4.04958678,2.72727273 L10,8.67768595 Z" />
        </svg>
      </button>

      {[
        {
          'timeslot name': (
            <div>
              <Text
                name="name"
                value={guestlist.name}
                handleChange={handleChange}
              />
            </div>
          ),
          'guest limit': (
            <GuestCount
              name="guest_max_limit"
              value={
                typeof guestlist.guest_max_limit === 'number'
                  ? guestlist.guest_max_limit
                  : 0
              }
              handleChange={handleChange}
            />
          )
        },
        {
          day: (
            <DatePicker
              className="TimeSlotDatePicker"
              activeDate={moment.parseZone(guestlist.start_time)}
              calendarIsOpen={dateOpen}
              handleDatePickerClick={() => setDateOpen(true)}
              handleCloseDatePicker={() => setDateOpen(false)}
              handleDateClick={handleDateClick}
            />
          ),
          from: (
            <TimePicker
              activeDate={moment.parseZone(guestlist.start_time)}
              timesIsOpen={startTimeOpen}
              handleTimePickerClick={() => setStartTimeOpen(true)}
              handleCloseTimePicker={() => setStartTimeOpen(false)}
              handleClickTime={onStartTimeChange}
            />
          ),
          to: (
            <TimePicker
              activeDate={moment.parseZone(guestlist.end_time)}
              timesIsOpen={endTimeOpen}
              handleTimePickerClick={() => setEndTimeOpen(true)}
              handleCloseTimePicker={() => setEndTimeOpen(false)}
              handleClickTime={onEndTimeChange}
            />
          )
        }
      ].map((row, i) => (
        <div className="TimeSlotRow" key={i}>
          {Object.entries(row).map(createTimeSlotColumn)}
        </div>
      ))}
    </div>
  )
}

export default TimeSlot
