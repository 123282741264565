import './Preview.scss'
import React from 'react'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'
import { UndoContext } from 'components/Contexts/UndoProvider'

const host =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''
const baseUrl = `${host}/api/registration-service`

class Preview extends React.PureComponent {
  state = {
    counter: 0,
    loaded: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hasUnsavedChanges && !nextProps.hasUnsavedChanges) {
      this.setState({
        counter: this.state.counter + 1
      })
    }
  }

  handleFrameLoad = () => this.setState({ loaded: true })

  render() {
    return (
      <div
        className="Preview"
        data-fixed={this.props.fixed}
        data-loaded={this.state.loaded}>
        <div className="loading" />
        {this.props.url ? (
          <iframe
            title="preview"
            src={`${baseUrl}/${this.props.url}?iteration=${this.state.counter}`}
            frameBorder={0}
            onLoad={this.handleFrameLoad}
          />
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

const ContextedPreview = props => (
  <UndoContext.Consumer>
    {({ hasUnsavedChanges }) => (
      <Preview {...props} hasUnsavedChanges={hasUnsavedChanges} />
    )}
  </UndoContext.Consumer>
)

export default ContextedPreview

Preview.propTypes = {
  url: PropTypes.string,
  fixed: PropTypes.bool
}
