import './Months.scss'
import React from 'react'
import PropTypes from 'prop-types'

const ArrowIcon = ({ handleClick, direction }) => (
  <button
    className="ArrowIcon"
    onClick={handleClick}
    data-direction={direction}>
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g>
        <path
          d="M20.4620813,4.5514481 C20.2148535,4.2948562 19.9217922,4.16666667 19.5831709,4.16666667 L2.08342739,4.16666667 C1.74466928,4.16666667 1.45181316,4.2948562 1.20438019,4.5514481 C0.956947209,4.80832376 0.833333333,5.11202016 0.833333333,5.46310484 C0.833333333,5.81411858 0.956947209,6.11781499 1.20438019,6.37447783 L9.95428612,15.4482681 C10.2019927,15.70486 10.4948489,15.8333333 10.8333333,15.8333333 C11.1718178,15.8333333 11.4649476,15.70486 11.7121753,15.4482681 L20.4620813,6.37440689 C20.7092406,6.11781499 20.8333333,5.81411858 20.8333333,5.4630339 C20.8333333,5.11202016 20.7092406,4.80832376 20.4620813,4.5514481 Z"
          transform="translate(10.833333, 10.000000) rotate(-90.000000) translate(-10.833333, -10.000000) "
        />
      </g>
    </svg>
  </button>
)

export const Months = ({
  date,
  handleClickPrevMonth,
  handleClickNextMonth
}) => (
  <div className="Months">
    <ArrowIcon handleClick={handleClickPrevMonth} direction="left" />
    <span className="MonthsLabel">{date.format('MMMM - YYYY')}</span>
    <ArrowIcon handleClick={handleClickNextMonth} />
  </div>
)

export default Months

Months.propTypes = {
  date: PropTypes.object.isRequired,
  handleClickPrevMonth: PropTypes.func.isRequired,
  handleClickNextMonth: PropTypes.func.isRequired
}

ArrowIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
  direction: PropTypes.string
}
