import React, { useState, useEffect } from 'react'

import GuestToggle from 'components/GuestToggle'
import { SettingsModule } from 'components/Settings/SettingsModule'

import { useTranslation } from 'react-i18next'

const GuestLimit = ({ guestLimit, handleGuestCountUpdate }) => {
  const [guestCount, setGuestCount] = useState(guestLimit ? guestLimit : '')
  const { t } = useTranslation()

  const guestLimitIsEnabled = guestLimit !== null

  const handleGuestCountChange = ({ target }) => {
    const onlyNumbersRegex = /^(\s*|\d+)$/
    if (onlyNumbersRegex.test(target.value)) {
      const valueNum = Number(target.value)

      setGuestCount(valueNum)
      handleGuestCountUpdate(valueNum)
    }
  }

  const handleToggleGuest = () => {
    !guestLimitIsEnabled
      ? setDefaultNumberOfGuests()
      : handleGuestCountUpdate(null)
  }

  const setDefaultNumberOfGuests = () => {
    handleGuestCountUpdate(100)
  }

  const handleGuestCountBlur = () => {
    handleGuestCountUpdate(guestCount)
  }

  useEffect(() => {
    setGuestCount(guestLimit ? guestLimit : '')
  }, [guestLimit])

  return (
    <SettingsModule
      title={t('guest limit')}
      description={t('helpTexts.guestLimit')}>
      <GuestToggle
        id="guestToggle"
        maxNumberOfFollowers={guestCount}
        guestIsEnabled={guestLimitIsEnabled}
        handleToggleGuest={handleToggleGuest}
        handleGuestCountChange={handleGuestCountChange}
        handleOnBlur={handleGuestCountBlur}
      />
    </SettingsModule>
  )
}

export default GuestLimit
