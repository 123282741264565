import './Text.scss'
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Text = ({
  icon,
  handleChange,
  handleBlur,
  handleFocus,
  value = '',
  inputRef,
  errored = false,
  readOnly = false,
  ...props
}) => {
  return (
    <div
      className={clsx('Text', { 'is-errored': errored })}
      data-icon={icon ? true : false}>
      <input
        {...props}
        onBlur={handleBlur}
        value={value}
        onFocus={handleFocus}
        type="text"
        onChange={handleChange}
        autoComplete="disabled"
        readOnly={readOnly}
        ref={inputRef}
      />
      {icon}
    </div>
  )
}

export default Text

Text.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  icon: PropTypes.object
}
