import './PreviewSmsModal.scss'
import React from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

const formatTime = (timestamp, timezone) => {
  let date = moment.parseZone(timestamp)
  if (timezone) {
    date = date.tz(timezone)
  }
  return date.format('D MMMM HH:mm')
}

export const PreviewSmsModal = ({ sms, timezone, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Modal modalName="PreviewSmsModal">
      <Header>{t('sms.preview.title')}</Header>
      <Content>
        <p
          dangerouslySetInnerHTML={{
            __html: t('sms.preview.info', {
              date: formatTime(sms.date, timezone),
              count: sms.recipients.length
            })
          }}
        />

        <hr className="Separator" />

        <p>{sms.name}</p>
        <p className="Message">{sms.message}</p>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
      />
    </Modal>
  )
}

export default PreviewSmsModal
