import './ExtendedLandingPagesList.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Button from 'components/Button'
import Text from 'components/Inputs/Text'
import { PlusIcon } from 'components/FormFactory/Icons'

const DragIcon = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" className="DragIcon">
    <rect width="16" height="2" rx="1" fill="black" />
    <rect y="4" width="16" height="2" rx="1" fill="black" />
    <rect y="8" width="16" height="2" rx="1" fill="black" />
  </svg>
)

const CrossIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" className="CrossIcon">
    <path
      d="M11.1726 1.7208L10.1293 0.677469C9.85946 0.407641 9.44573 0.407641 9.1759 0.677469L5.88399 3.98736L2.5741 0.677469C2.30427 0.407641 1.89054 0.407641 1.62071 0.677469L0.577371 1.7208C0.307543 1.99063 0.307543 2.40437 0.577371 2.6742L3.90525 5.9661L0.59536 9.276C0.325531 9.54583 0.325531 9.95956 0.59536 10.2294L1.6387 11.2727C1.90852 11.5426 2.32226 11.5426 2.59209 11.2727L5.88399 7.96283L9.19389 11.2727C9.46372 11.5426 9.87745 11.5426 10.1473 11.2727L11.1906 10.2294C11.4604 9.95956 11.4604 9.54583 11.1906 9.276L7.86274 5.9661L11.1726 2.65621C11.4425 2.40437 11.4425 1.99063 11.1726 1.7208Z"
      fill="#76797F"
    />
  </svg>
)

const ExtendedLandingPagesList = ({
  pages,
  handleReordering,
  handlePageTitleChange,
  handleRemovePage,
  handleCreatePage
}) => {
  return (
    <div className="ExtendedLandingPagesList">
      <DragDropContext onDragEnd={handleReordering}>
        <Droppable
          droppableId="ExtendedLandingPages"
          type="EXTENDED_LANDING_PAGE">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {pages.map((page, i) => (
                <Draggable
                  draggableId={`ExtendedLandingPages-${i}`}
                  index={i}
                  key={i}>
                  {(provided, snapshot) => (
                    <div
                      className="Page"
                      data-dragging={snapshot.isDragging}
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                      <div {...provided.dragHandleProps}>
                        <DragIcon />
                      </div>
                      <Text
                        value={page.title}
                        handleChange={event =>
                          handlePageTitleChange(page, event)
                        }
                        maxLength={25}
                        required
                      />
                      {pages.length > 1 && (
                        <Button
                          buttonType="button"
                          onClick={() => handleRemovePage(page)}>
                          <CrossIcon />
                        </Button>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {pages.length < 7 && (
        <div className="CreatorButton">
          <button onClick={handleCreatePage} type="button">
            <PlusIcon />
          </button>
        </div>
      )}
    </div>
  )
}

export default ExtendedLandingPagesList

ExtendedLandingPagesList.propTypes = {
  pages: PropTypes.array.isRequired,
  handleReordering: PropTypes.func.isRequired,
  handlePageTitleChange: PropTypes.func.isRequired,
  handleRemovePage: PropTypes.func.isRequired,
  handleCreatePage: PropTypes.func.isRequired
}
