import './Pagination.scss'
import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const CheIcon = () => (
  <svg className="CheIcon" width="20px" height="20px" viewBox="0 0 20 20">
    <g>
      <path d="M19.6287479,5.38478143 C19.3815202,5.12818954 19.0884588,5 18.7498375,5 L1.25009406,5 C0.911335947,5 0.61847983,5.12818954 0.371046853,5.38478143 C0.123613876,5.64165709 0,5.9453535 0,6.29643818 C0,6.64745192 0.123613876,6.95114832 0.371046853,7.20781116 L9.12095279,16.2816015 C9.3686594,16.5381934 9.66151552,16.6666667 10,16.6666667 C10.3384845,16.6666667 10.6316142,16.5381934 10.878842,16.2816015 L19.6287479,7.20774022 C19.8759073,6.95114832 20,6.64745192 20,6.29636724 C20,5.9453535 19.8759073,5.64165709 19.6287479,5.38478143 Z" />
    </g>
  </svg>
)

const PageNumber = ({ active, index, handleChange }) => (
  <li
    onClick={handleChange}
    className="PageNumber"
    data-index={index}
    data-active={active}>
    {index + 1}
  </li>
)

const PageNumbers = ({ perPage, recordCount, handleChange, page }) => {
  const pageCount = Math.ceil(recordCount / perPage)
  const pages = []

  for (var i = 0; i < pageCount; i++) {
    pages.push(i)
  }

  if (perPage === 'ALL' || pageCount === 1) {
    return false
  }

  return (
    <div className="PageNumbers" data-page={page}>
      <ul className="track">
        {pages &&
          pages.map((int, i) => {
            const active = page === i ? true : false
            return (
              <PageNumber
                handleChange={handleChange}
                key={i}
                index={i}
                active={active}
              />
            )
          })}
      </ul>
    </div>
  )
}

const ResultCountSelector = ({ value, handleChange }) => {
  const { t } = useTranslation()
  const options = [10, 20, 30, 'ALL']

  return (
    <div className="ResultCountSelector">
      <select onChange={handleChange} value={value}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {t('showResults', { value: t(option) })}
          </option>
        ))}
      </select>

      <CheIcon />
    </div>
  )
}

const Pagination = ({
  page,
  perPage,
  data,
  handlePageChange,
  handlePerPageChange,
  debug
}) => {
  return (
    <div className="Pagination" data-debug={debug}>
      <span />
      <PageNumbers
        page={page}
        perPage={perPage}
        handleChange={handlePageChange}
        recordCount={data.length}
      />
      {data.length > 10 ? (
        <ResultCountSelector
          handleChange={handlePerPageChange}
          value={perPage}
        />
      ) : null}
    </div>
  )
}

export default Pagination

Pagination.propTypes = {
  data: PropTypes.array.isRequired,
  perPage: PropTypes.any.isRequired
}
