import React from 'react'
import Textarea from 'components/Inputs/Textarea'
import { useTranslation } from 'react-i18next'
import { SettingsModule } from 'components/Settings/SettingsModule'
import { ErrorContext } from 'components/Contexts/ErrorProvider'

const LegalText = ({ value, updateHandler }) => {
  const { t } = useTranslation()
  const { error, clearError, addError } = React.useContext(ErrorContext)
  const { data: errors } = error.data ? error.data : {}
  const isInvalid = !!(errors && errors.legal_text ? errors.legal_text : null)

  const handleChange = ({ target }) => {
    updateHandler(target.value)

    if (target.value.length > 1500) {
      addError('legal_text', 'invalid value')
    } else if (isInvalid) {
      clearError('legal_text')
    }
  }
  return (
    <SettingsModule
      title={t('additional_legal_text')}
      description={t('helpTexts.legalText')}>
      <Textarea
        resize="vertical"
        value={value}
        handleChange={handleChange}
        placeholder={t('additional_legal_text')}
        id="legalText"
      />
      {isInvalid && (
        <p className="SettingsModuleDescription error">
          {t('helpTexts.invalidValue')}
        </p>
      )}
    </SettingsModule>
  )
}

export default LegalText
