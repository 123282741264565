import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import resources from 'translations'
import moment from 'moment-timezone'
import 'moment/locale/sv'
import 'moment/locale/de'

Object.keys(resources).forEach(lang => {
  moment.updateLocale(lang, {
    week: {
      dow: 1, // always use monday as first day of week
      doy: 4 // This configuration means that week one will be the week containing 4th of January (ISO-8601).
      // check details here: https://momentjs.com/docs/#/customization/dow-doy/
    }
  })
})

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    load: 'languageOnly',
    whitelist: Object.keys(resources),
    keySeperator: false,
    interpolation: {
      escapeValue: false
    }
  })

export const setLanguage = lang => {
  if (Object.keys(resources).includes(lang)) {
    moment.locale(lang)
    i18next.changeLanguage(lang)
  }
}

export const setTimezone = timezone => {
  if (timezone) {
    moment.tz.setDefault(timezone)
  }
}
