import './FormFactory.scss'
import React, { useState, useEffect } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import Creator from 'components/FormFactory/Creator'
import DraggableItem from 'components/FormFactory/DraggableItem'

import GuestToggle from 'components/GuestToggle'
import { PlusIcon } from 'components/FormFactory/Icons'

import { useTranslation } from 'react-i18next'

const FactoryContent = ({
  fields,
  id,
  factoryId,
  handleFactoryFieldChange,
  handleFactoryFieldRequiredChange,
  handleFactoryFieldTextareaChange,
  handleFactoryFieldDelete,
  handleInputBlur,
  handleCreateField,
  handleOpenCreator,
  handleCloseCreator,
  creatorIsVisible
}) => (
  <div>
    {fields && fields.length > 0 ? (
      <Droppable droppableId={id} type={`FIELD-${id}`}>
        {provided => (
          <div ref={provided.innerRef} className="body">
            {fields.map((field, i) => (
              <DraggableItem
                index={i}
                parent={id}
                key={i}
                field={field}
                factoryId={factoryId}
                fieldIndex={i}
                handleFactoryFieldChange={handleFactoryFieldChange}
                handleFactoryFieldRequiredChange={
                  handleFactoryFieldRequiredChange
                }
                handleFactoryFieldTextareaChange={
                  handleFactoryFieldTextareaChange
                }
                handleFactoryFieldDelete={handleFactoryFieldDelete}
                handleInputBlur={handleInputBlur}
              />
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ) : null}

    <div className="footer">
      <div className="CreatorButton">
        <button onClick={handleOpenCreator}>
          <PlusIcon />
        </button>
      </div>

      {creatorIsVisible && (
        <Creator
          id={id}
          factoryId={factoryId}
          handleCreateField={handleCreateField}
          handleCloseCreator={handleCloseCreator}
        />
      )}
    </div>
  </div>
)

export const FormFactory = ({
  title,
  setDefaultNumberOfFollowers,
  handleGuestCountChange,
  isPrimaryGuest,
  maxNumberOfFollowers,
  ...props
}) => {
  const [nrOfFollowers, setNrOfFollowers] = useState(maxNumberOfFollowers)
  const [creatorIsVisible, setCreatorIsVisible] = useState(false)
  const { t } = useTranslation()

  const guestIsEnabled = maxNumberOfFollowers > 0

  const handleOnBlur = () => {
    handleGuestCountChange(nrOfFollowers)
  }

  const handleOpenCreator = () => {
    setCreatorIsVisible(true)
  }

  const handleCloseCreator = () => {
    setCreatorIsVisible(false)
  }

  const handleToggleGuest = () => {
    !guestIsEnabled ? setDefaultNumberOfFollowers() : handleGuestCountChange(0)
  }

  useEffect(() => {
    setNrOfFollowers(maxNumberOfFollowers)
  }, [maxNumberOfFollowers])

  return (
    <div className="FormFactory">
      <div className="header">
        <h3>{t(title)}</h3>
        {!isPrimaryGuest && (
          <GuestToggle
            id={props.id}
            factoryId={props.factoryId}
            handleToggleGuest={handleToggleGuest}
            handleGuestCountChange={({ target }) =>
              setNrOfFollowers(target.value)
            }
            guestIsEnabled={guestIsEnabled}
            maxNumberOfFollowers={nrOfFollowers}
            handleOnBlur={handleOnBlur}
          />
        )}
      </div>

      {(isPrimaryGuest || guestIsEnabled) && (
        <FactoryContent
          {...props}
          creatorIsVisible={creatorIsVisible}
          handleOpenCreator={handleOpenCreator}
          handleCloseCreator={handleCloseCreator}
        />
      )}
    </div>
  )
}

export default FormFactory

FormFactory.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  guest: PropTypes.bool,
  handleCreateField: PropTypes.func.isRequired,
  handleFactoryFieldDelete: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func.isRequired
}

FactoryContent.propTypes = {
  fields: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  creatorIsVisible: PropTypes.bool.isRequired,
  handleFactoryFieldChange: PropTypes.func.isRequired,
  handleFactoryFieldDelete: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func.isRequired,
  handleCreateField: PropTypes.func.isRequired,
  handleOpenCreator: PropTypes.func.isRequired,
  handleCloseCreator: PropTypes.func.isRequired
}
