import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import { useTranslation } from 'react-i18next'
import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import { ErrorContext } from '../../Contexts/ErrorProvider'

const PromptModal = ({
  isActive,
  onCancel,
  onConfirm,
  handleResetChanges,
  handleSaveChanges,
  isLoading
}) => {
  const { t } = useTranslation()
  const { setError } = React.useContext(ErrorContext)

  const handleDontSave = () => {
    handleResetChanges()
    setError({})
    onConfirm()
  }

  const handleSave = () => {
    handleSaveChanges().then(resp => {
      onConfirm()
      return resp
    })
  }

  return (
    isActive && (
      <Modal>
        <Header>{t('unsaved changes')}</Header>
        <Content>
          <p>{t('leaveUnsaved')}</p>
        </Content>
        <Actions
          leftActions={
            <Button type="secondary" onClick={onCancel} disabled={isLoading}>
              {t('cancel')}
            </Button>
          }
          rightActions={
            <div className="ModalRightActions">
              <Button
                type="secondary"
                onClick={handleDontSave}
                disabled={isLoading}>
                {t('dont save')}
              </Button>
              <Button type="primary" onClick={handleSave} disabled={isLoading}>
                {t('save')}
              </Button>
            </div>
          }
        />
      </Modal>
    )
  )
}

const UnsavedChangesModal = ({ hasUnsavedChanges, ...props }) => (
  <NavigationPrompt when={hasUnsavedChanges}>
    {navigationPromptProps => (
      <PromptModal {...props} {...navigationPromptProps} />
    )}
  </NavigationPrompt>
)

export default UnsavedChangesModal
