import './StateToggler.scss'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { compose } from 'lodash/fp'

import Dropdown from 'components/Dropdown'
import ChevronIcon from './icons/Chevron.js'

const removeValuesWhen = (values, bool) => states =>
  bool ? states.filter(s => !values.includes(s.value)) : states

const StateToggler = ({ className, disabled, onSelect, value, ...props }) => {
  const { t } = useTranslation()

  const STATES = ['CLOSED', 'ACTIVE', 'DRAFT', 'FULL'].map(state => ({
    value: state,
    headingActive: t(`states.${state}.headingActive`),
    headingInactive:
      state === 'ACTIVE' && value === 'DRAFT'
        ? t(`states.${state}.headingDraft`)
        : t(`states.${state}.headingInactive`),
    textActive: t(`states.${state}.textActive`),
    textInactive: t(`states.${state}.textInactive`)
  }))

  const selectedState = STATES.find(state => state.value === value)

  const selectableStates = compose(
    removeValuesWhen(['CLOSED'], value === 'DRAFT'),
    removeValuesWhen(['ACTIVE'], value === 'FULL'),
    removeValuesWhen([value], true),
    removeValuesWhen(['DRAFT', 'FULL'], true)
  )(STATES)

  const selectTypeValue = ({ value }) => onSelect(value)

  return (
    <Dropdown
      {...props}
      items={selectableStates}
      onSelect={selectTypeValue}
      className={clsx('StateToggler', className)}>
      {({
        handleSelect,
        isOpen,
        selectedIndex,
        selectItem,
        selectedItem,
        setIsOpen
      }) => (
        <div
          className={clsx('StateTogglerInner', {
            isOpen,
            isDisabled: disabled
          })}>
          <button
            type="button"
            className="StateTogglerButton StateTogglerToggler"
            data-state={value}
            disabled={disabled}
            onClick={() => setIsOpen(!isOpen)}>
            {selectedState.headingActive ? (
              <h6 className="StateTogglerHeading">
                {selectedState.headingActive}
              </h6>
            ) : null}

            {selectedState.textActive ? (
              <p className="StateTogglerText">{selectedState.textActive}</p>
            ) : null}

            <ChevronIcon className={clsx('StateTogglerIcon', { isOpen })} />
          </button>

          {isOpen ? (
            <ul className="StateTogglerList">
              {selectableStates.map((state, i) => (
                <li key={i} className="StateTogglerItem">
                  <button
                    type="button"
                    className={clsx(
                      'StateTogglerButton',
                      'StateTogglerItemButton',
                      {
                        isSelected: i === selectedIndex
                      }
                    )}
                    data-state={state.value}
                    onClick={() => handleSelect(state)}>
                    {state.headingInactive ? (
                      <h6 className="StateTogglerHeading">
                        {state.headingInactive}
                      </h6>
                    ) : null}

                    {state.textInactive ? (
                      <p className="StateTogglerText">{state.textInactive}</p>
                    ) : null}
                  </button>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Dropdown>
  )
}

export default StateToggler

StateToggler.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  value: PropTypes.string
}
