import './SmsTable.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { useTranslation } from 'react-i18next'

import { EditIcon, PreviewIcon, TrashCan } from 'components/Icons'

const formatTime = (timestamp, timezone) => {
  let date = moment.parseZone(timestamp)
  if (timezone) {
    date = date.tz(timezone)
  }
  return date.format('D MMMM HH:mm')
}

const timeLeft = timestamp => {
  const date = moment.parseZone(timestamp)
  const now = moment()
  const duration = moment.duration(date.diff(now))
  const days = Math.floor(duration.asDays())
  const hours = Math.floor(duration.hours())
  const minutes = duration.minutes()
  if (days > 0 && hours > 0) {
    return `${days}d ${hours}h ${minutes}min`
  } else if (days > 0 && hours === 0) {
    return `${days}d ${minutes}min`
  } else if (hours > 0) {
    return `${hours}h ${minutes}min`
  } else if (minutes > 0) {
    return `${minutes}min`
  } else {
    return '-'
  }
}

const SmsStatusBullet = ({ status }) => {
  return <span className={`SmsStatus SmsStatus--${status}`}></span>
}

const SmsTable = ({
  eventId,
  smsList,
  timezone,
  handleDeleteSms,
  handlePreviewSms,
  handleSmsRecipientsCountClick
}) => {
  const { t } = useTranslation()

  return (
    <table className="SmsTable">
      <thead>
        <tr>
          <th>{t('sms.settings.table.name')}</th>
          <th>{t('sms.settings.table.date')}</th>
          <th>{t('sms.settings.table.recipients')}</th>
          <th>{t('sms.settings.table.timeLeft')}</th>
          <th>{t('sms.settings.table.status')}</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {smsList?.length > 0 ? (
          smsList.map(sms => (
            <tr key={sms.id}>
              <td>{sms.name}</td>
              <td className="nowrap">
                {sms.date ? formatTime(sms.date, timezone) : '-'}
              </td>
              <td
                className="nowrap pointer"
                onClick={() => handleSmsRecipientsCountClick(sms)}>
                {sms.recipients.length}
              </td>
              <td className="nowrap">
                {sms.date && sms.status !== 'sent' ? timeLeft(sms.date) : '-'}
              </td>
              <td className="nowrap">
                <SmsStatusBullet status={sms.status} />
                {t(`sms.settings.table.statusEnum.${sms.status}`)}
              </td>
              <td className="nowrap right">
                {sms.status === 'sent' ? (
                  <>
                    <button
                      className="SmsTableButton"
                      type="button"
                      onClick={() => handlePreviewSms(sms)}>
                      <PreviewIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      className="SmsTableButton"
                      to={`/event/${eventId}/sms/${sms.id}`}>
                      <EditIcon />
                    </Link>

                    <button
                      className="SmsTableButton"
                      type="button"
                      onClick={() => handleDeleteSms(sms)}>
                      <TrashCan />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr className="NoResult">
            <td colSpan="6">{t('sms.settings.table.noResult')}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default SmsTable

SmsTable.propTypes = {
  eventId: PropTypes.string.isRequired,
  smsList: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  handleDeleteSms: PropTypes.func.isRequired,
  handlePreviewSms: PropTypes.func.isRequired
}
