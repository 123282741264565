import './EditExtendedLandingPagesModal.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import clsx from 'clsx'

import Button from 'components/Button'
import FileInput from 'components/FileInput'
import { ExtendedLandingPagesContext } from 'components/Contexts/ExtendedLandingPagesProdiver'
import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import ExtendedLandingPagesList from '../ExtendedLandingPagesList'

const NavItem = ({ title, tab, currentTab, setCurrentTab }) => (
  <div className="NavItem">
    <button
      className={clsx({ active: currentTab === tab })}
      type="button"
      onClick={() => setCurrentTab(tab)}>
      {title}
    </button>
  </div>
)

const acceptedHeaderImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp'
]

const EditExtendedLandingPagesModal = ({ eventId, handleCloseModal }) => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = React.useState('pages')

  const {
    website,
    page,
    saveWebsitePagesSettings,
    setWebsiteHeader,
    fetchPage
  } = React.useContext(ExtendedLandingPagesContext)
  const [pages, setPages] = React.useState([])
  const [pagesDeleted, setPagesDeleted] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState([])

  const [header, setHeader] = React.useState(null)

  React.useEffect(() => {
    setPages(
      website ? cloneDeep(website.pages.sort((a, b) => a.index - b.index)) : []
    )
  }, [website])

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      setErrors([])

      await Promise.all([
        saveWebsitePagesSettings(pages, pagesDeleted),
        header ? setWebsiteHeader(header) : null
      ])
      // Reload current page
      fetchPage(page.page_id, false)

      handleCloseModal()
    } catch (e) {
      const errorData = e?.response?.data
      if (errorData?.errors) {
        const newErrors = []
        for (const errorKey in errorData.errors) {
          newErrors.push([...errorData.errors[errorKey]])
        }
        setErrors(newErrors)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCreatePage = () => {
    const newPages = [...pages]
    newPages.push({
      id: null,
      title: '',
      index: pages.length ? pages[pages.length - 1].index + 1 : 0
    })
    setPages(newPages)
  }

  const reorderPages = pages => {
    for (let i = 0; i < pages.length; ++i) {
      pages[i].index = i + 1
    }
    return pages
  }

  const handleReordering = ({ source, destination }) => {
    const newPages = [...pages]
    const pagesRemoved = newPages.splice(source.index, 1)
    const page = pagesRemoved[0]
    newPages.splice(destination.index, 0, page)

    reorderPages(newPages)
    setPages(newPages)
  }

  const handlePageTitleChange = (page, { target }) => {
    page.title = target.value
    setPages([...pages])
  }

  const handleRemovePage = page => {
    const index = pages.indexOf(page)
    const newPages = [...pages]
    const deleted = newPages.splice(index, 1)

    reorderPages(newPages)

    setPages(newPages)
    setPagesDeleted([
      ...pagesDeleted,
      ...deleted.filter(page => page.id !== null)
    ])
  }

  const handleOnHeaderChange = file => {
    setHeader(file)
  }

  return (
    <Modal modalName="EditExtendedLandingPagesModal">
      <form className="EditExtendedLandingPagesModal" onSubmit={handleSubmit}>
        <Header>{t('extendedLandingPages.modalSettings.title')}</Header>
        <Content>
          <nav className="Nav">
            <NavItem
              title={t('extendedLandingPages.modalSettings.pages')}
              tab="pages"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <NavItem
              title={t('extendedLandingPages.modalSettings.headerImage')}
              tab="header_image"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </nav>

          {currentTab === 'pages' && (
            <ExtendedLandingPagesList
              pages={pages}
              handleCreatePage={handleCreatePage}
              handlePageTitleChange={handlePageTitleChange}
              handleReordering={handleReordering}
              handleRemovePage={handleRemovePage}
            />
          )}

          {currentTab === 'header_image' && (
            <div>
              <p className="FileInputDescription">
                {t('extendedLandingPages.modalSettings.headerImageLabel')}
              </p>
              <FileInput
                file={header}
                accept={acceptedHeaderImageTypes}
                maxSize={10240000}
                onFileChange={handleOnHeaderChange}
              />
            </div>
          )}

          {errors.map((error, index) => (
            <p className="EditExtendedLandingPagesModalError" key={index}>
              {error}
            </p>
          ))}
        </Content>
        <Actions
          leftActions={
            <Button
              type="secondary"
              buttonType="button"
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          }
          rightActions={
            <Button type="primary" buttonType="submit" loading={loading}>
              {t('save')}
            </Button>
          }
        />
      </form>
    </Modal>
  )
}

export default EditExtendedLandingPagesModal

EditExtendedLandingPagesModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired
}
