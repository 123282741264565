import './EventEdit.scss'
import React, { useState } from 'react'

import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import clsx from 'clsx'

import Button from 'components/Button'
import Nav from 'components/Nav'
import EditForm from 'components/EditForm'
import Preview from 'components/Preview'
import { UndoContext } from 'components/Contexts/UndoProvider'

import { useTranslation } from 'react-i18next'

const IconCollapse = () => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m5 2h-2c-.55228475 0-1 .44771525-1 1v10c0 .5522847.44771525 1 1 1h2zm2 0v12h6c.5522847 0 1-.4477153 1-1v-4h-1.7573593v3.2426407l-4.2426407-4.2426407 4.2426407-4.24264069v3.24264069h1.7573593v-4c0-.55228475-.4477153-1-1-1zm-4-2h10c1.6568542 0 3 1.34314575 3 3v10c0 1.6568542-1.3431458 3-3 3h-10c-1.65685425 0-3-1.3431458-3-3v-10c0-1.65685425 1.34314575-3 3-3z" />
  </svg>
)

const IconExpand = () => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m5 2h-2c-.55228475 0-1 .44771525-1 1v10c0 .5522847.44771525 1 1 1h2zm2 0v5h2v-3.24264069l4.2426407 4.24264069-4.2426407 4.2426407v-3.2426407h-2v5h6c.5522847 0 1-.4477153 1-1v-10c0-.55228475-.4477153-1-1-1zm-4-2h10c1.6568542 0 3 1.34314575 3 3v10c0 1.6568542-1.3431458 3-3 3h-10c-1.65685425 0-3-1.3431458-3-3v-10c0-1.65685425 1.34314575-3 3-3z" />
  </svg>
)

const PictureIcon = () => (
  <svg
    fill="currentColor"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m3 0h10c1.6568542 0 3 1.34314575 3 3v10c0 1.6568542-1.3431458 3-3 3h-10c-1.65685425 0-3-1.3431458-3-3v-10c0-1.65685425 1.34314575-3 3-3zm0 1c-1.1045695 0-2 .8954305-2 2v10c0 1.1045695.8954305 2 2 2h10c1.1045695 0 2-.8954305 2-2v-10c0-1.1045695-.8954305-2-2-2zm12 7.80761184v1.41421356l-3.5-3.49999999-4.85355339 4.85355339-2.64644661-2.64644661-3 3.00000001v-1.4142136l2.29289322-2.29289319c.39052429-.39052429 1.02368927-.39052429 1.41421356 0l1.93933983 1.93933979 4.14644659-4.14644657c.3905243-.3905243 1.0236893-.3905243 1.4142136 0zm-10-2.80761184c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1 1 .44771525 1 1-.44771525 1-1 1z" />
  </svg>
)

const EventEdit = ({
  id,
  backgrounds,
  location,
  previewUrls,
  isEventLoading
}) => {
  const { t } = useTranslation()
  const [sidebarHidden, hideSidebar] = useState(false)
  const [backgroundModalIsOpen, setBackgroundModalIsOpen] = useState(false)
  const [willExpand, setWillExpand] = useState(false)

  const ContexedEditForm = ({ title }) => (
    <UndoContext.Consumer>
      {props => (
        <EditForm
          {...props}
          backgrounds={backgrounds}
          title={title}
          backgroundModalIsOpen={backgroundModalIsOpen}
          setBackgroundModalIsOpen={setBackgroundModalIsOpen}
        />
      )}
    </UndoContext.Consumer>
  )

  const editRoutes = [
    {
      to: `/event/${id}/edit/signup`,
      label: t('sign up'),
      exact: true,
      form: props => <ContexedEditForm {...props} title="signup" />,
      preview: props => (
        <Preview {...props} url={previewUrls && previewUrls.signup} />
      )
    },
    {
      to: `/event/${id}/edit/confirm`,
      label: t('confirm'),
      exact: true,
      form: props => <ContexedEditForm {...props} title="confirm" />,
      preview: props => (
        <Preview {...props} url={previewUrls && previewUrls.confirm} />
      )
    },
    {
      to: `/event/${id}/edit/closed`,
      label: t('closed'),
      exact: true,
      form: props => <ContexedEditForm {...props} title="closed" />,
      preview: props => (
        <Preview {...props} url={previewUrls && previewUrls.closed} />
      )
    },
    {
      to: `/event/${id}/edit/full`,
      label: t('full'),
      exact: true,
      form: props => <ContexedEditForm {...props} title="full" />,
      preview: props => (
        <Preview {...props} url={previewUrls && previewUrls.full} />
      )
    }
  ]

  if (location.pathname === `/event/${id}/edit`) {
    return <Redirect to={`/event/${id}/edit/signup`} />
  }

  return (
    <div className="EventEdit">
      <div className="Editor">
        <div
          className={clsx([
            'Sidebar',
            { 'is-hidden': sidebarHidden, 'will-hide': willExpand }
          ])}>
          <Nav items={editRoutes} />
          <Button
            disabled={isEventLoading}
            className="backgroundButton"
            type="primary"
            onClick={() => setBackgroundModalIsOpen(true)}>
            <PictureIcon />
            {t('backgrounds')}
          </Button>
          <Switch location={location}>
            {editRoutes &&
              editRoutes.map((route, index) => (
                <Route
                  key={`editor_${index}`}
                  path={route.to}
                  render={route.form}
                />
              ))}
          </Switch>
          <button
            type="button"
            onClick={() => hideSidebar(!sidebarHidden)}
            onMouseOver={() => setWillExpand(true)}
            onMouseLeave={() => setWillExpand(false)}
            className="SidebarToggle">
            {sidebarHidden ? <IconExpand /> : <IconCollapse />}
          </button>
        </div>
        <div
          className={clsx([
            'PreviewContainer',
            { 'is-expanded': sidebarHidden, 'will-expand': willExpand }
          ])}>
          <Switch>
            {editRoutes &&
              editRoutes.map((route, index) => (
                <Route
                  path={route.to}
                  key={`preview_${index}`}
                  render={route.preview}
                />
              ))}
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withRouter(EventEdit)
