import './Thumbnail.scss'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Loader from 'components/Loader'
import DefaultPreviewImage from 'components/DefaultPreviewImage'

const Thumbnail = ({ className, src, size, width, height, debug }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    !src && setError(true)
  }, [src])

  const style = {
    backgroundImage: `url(${src})`,
    minWidth: width || size || 266,
    height: height || size || 180
  }

  return (
    <div
      className={clsx('Thumbnail', className)}
      style={style}
      data-error={error}
      data-loaded={loaded}>
      <div className="loader-container">
        <Loader size={size < 100 ? 'small' : 'medium'} />
      </div>
      <div className="thumb-placeholder">
        <DefaultPreviewImage />
      </div>
      <img
        onError={() => setError(true)}
        onLoad={() => !debug && setLoaded(true)}
        src={src}
        alt=""
      />
    </div>
  )
}

export default Thumbnail

Thumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  debug: PropTypes.bool,
  size: PropTypes.number
}
