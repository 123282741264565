import {
  fetchAPI,
  GUESTLIST_API,
  REGISTRATION_API,
  EXTENDED_LANDING_PAGES_API,
  SMS_API
} from './util'

export const auth = {
  authorize: params =>
    fetchAPI(GUESTLIST_API)({
      url: `/oauth/authorize${params}`
    }),
  logout: () =>
    fetchAPI(GUESTLIST_API)({
      url: `/oauth/logout`
    })
}

export const organization = {
  getCurrentOrganization: () =>
    fetchAPI(GUESTLIST_API)({
      url: '/organization/current'
    }),
  getOrganization: orgId =>
    fetchAPI(GUESTLIST_API)({
      url: `/organization/${orgId}`
    }),
  getOrganizationSettings: org_id =>
    fetchAPI(GUESTLIST_API)({
      url: `/organization/${org_id}/settings/`
    }),
  postOrganizationSettings: (org_id, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: `/organization/${org_id}/settings/`,
      data
    }),
  putOrganizationSetting: (org_id, settingsKey, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'put',
      url: `/organization/${org_id}/settings/${settingsKey}`,
      data
    }),
  getOrganizationNotifications: () =>
    fetchAPI(GUESTLIST_API)({
      url: '/notifications'
    })
}

export const template = {
  getAllTemplates: (cancelToken = undefined) =>
    fetchAPI(GUESTLIST_API)({
      url: `/templates`,
      cancelToken: cancelToken
    }),
  getTemplate: (org_id, templateId) =>
    fetchAPI(GUESTLIST_API)({
      url: `/organization/${org_id}/template/${templateId}`
    }),
  getTemplateBackgrounds: (templateId, token) =>
    fetchAPI(REGISTRATION_API)({
      url: `/internal/template/${templateId}/backgrounds?access_token=${token}`
    })
}

export const event = {
  getAllEvents: org_id =>
    fetchAPI(GUESTLIST_API)({
      url: `/organization/${org_id}/event`
    }),
  getEvent: eventId =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}`
    }),
  postEvent: (templateId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: `/template/${templateId}/event/`,
      data
    }),
  putEvent: (templateId, eventId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'put',
      url: `/template/${templateId}/event/${eventId}`,
      data
    }),
  deleteEvent: (templateId, eventId) =>
    fetchAPI(GUESTLIST_API)({
      method: 'delete',
      url: `/template/${templateId}/event/${eventId}`
    })
}

export const guestlist = {
  getAllGuestlists: eventId =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}/guestlists`
    }),
  getGuestlist: (eventId, guestlistId) =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}/guestlist/${guestlistId}`
    }),
  postGuestlist: (eventId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: `/event/${eventId}/guestlist/`,
      data
    }),
  putGuestlist: (eventId, guestlistId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: `/event/${eventId}/guestlist/${guestlistId}`,
      data
    }),
  deleteGuestlist: (eventId, guestlistId) =>
    fetchAPI(GUESTLIST_API)({
      method: 'delete',
      url: `/event/${eventId}/guestlist/${guestlistId}`
    })
}

export const party = {
  getAllParties: eventId =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}/parties`
    }),
  getParty: (guestlistId, partyId) =>
    fetchAPI(GUESTLIST_API)({
      url: `/guestlist/${guestlistId}/party/${partyId}`
    }),
  postParty: (guestlistId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: `/guestlist/${guestlistId}/party/`,
      data
    }),
  putParty: (guestlistId, partyId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'put',
      url: `/guestlist/${guestlistId}/party/${partyId}`,
      data
    }),
  cancelParty: (guestlistId, partyId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'patch',
      url: `/guestlist/${guestlistId}/party/${partyId}/cancel`,
      data
    }),
  deleteParty: (guestlistId, partyId) =>
    fetchAPI(GUESTLIST_API)({
      method: 'delete',
      url: `/guestlist/${guestlistId}/party/${partyId}`
    })
}

export const dashboard = {
  getDashboardInfo: () =>
    fetchAPI(GUESTLIST_API)({
      url: '/dashboard/events'
    }),
  getEventDashboardInfo: eventId =>
    fetchAPI(GUESTLIST_API)({
      url: `/dashboard/event/${eventId}`
    }),
  postEventDashboardInfo: (data, cancelToken) =>
    fetchAPI(GUESTLIST_API)({
      method: 'post',
      url: '/dashboard/event/',
      data: data,
      cancelToken: cancelToken
    }),
  putEventDashboardInfo: (eventId, data) =>
    fetchAPI(GUESTLIST_API)({
      method: 'put',
      url: `/dashboard/event/${eventId}`,
      data
    }),
  deleteEventDashboardInfo: eventId =>
    fetchAPI(GUESTLIST_API)({
      method: 'delete',
      url: `/dashboard/event/${eventId}`
    }),
  restoreEvent: eventId =>
    fetchAPI(GUESTLIST_API)({
      method: 'patch',
      url: `/dashboard/event/${eventId}`,
      data: {
        deleted_at: ''
      }
    }),
  getPreviewLink: token =>
    fetchAPI(REGISTRATION_API)({
      url: `/internal/${token}/link`
    }),
  getPreviewToken: (eventId, guestlistId) =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}/guestlist/${guestlistId}/signup_token`
    }),
  getSignupToken: eventId =>
    fetchAPI(GUESTLIST_API)({
      url: `/event/${eventId}/signup_token`
    }),
  getGuestlistEmails: guestlistId =>
    fetchAPI(GUESTLIST_API)({
      url: `/export/guestlist/${guestlistId}/emails`
    }),
  getCheckInAppUrlGuests: (eventId, token) =>
    fetchAPI(GUESTLIST_API)({
      url: `/ipad_check_in/event/${eventId}/link${
        token ? 'access_token=' + token : ''
      }`
    }),
  getEmailPreview: guestlistId =>
    fetchAPI(GUESTLIST_API)({
      url: `/email/${guestlistId}/preview`,
      responseType: 'blob'
    })
}

export const duplicateDashboardInfo = event => {
  const newEvent = {
    ...event,
    published: false
  }
  return dashboard.postEventDashboardInfo(newEvent)
}

export const fields = {
  getFieldTypes: () =>
    fetchAPI(GUESTLIST_API)({
      url: '/datatypes/fields'
    }),
  getVisualPlacingTypes: () =>
    fetchAPI(GUESTLIST_API)({
      url: '/datatypes/visualplacings'
    })
}

export const exporting = {
  getExportData: (guestlistId, params = {}) =>
    fetchAPI(GUESTLIST_API)({
      url: `/export/guestlist/${guestlistId}/export`,
      params
    }),
  getEmails: (eventId, params = {}) =>
    fetchAPI(GUESTLIST_API)({
      url: `/export/event/${eventId}/emails`,
      params
    }),
  downloadSpreadsheet: (eventId, filename) =>
    fetchAPI(GUESTLIST_API)({
      url: `/export/event/${eventId}/export`,
      params: { format_data: 'xlsx' },
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' }
    })
      .then(({ data }) => {
        const blob = new Blob([data])

        if (navigator.msSaveBlob) {
          // IE11 and Edge 12 has Blob, but not [download] attr
          return navigator.msSaveBlob(blob, `${filename}.xlsx`)
        }

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('style', 'display: none;')
        link.setAttribute('href', url)
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()

        setTimeout(() => {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }, 100)
      })
      .catch(console.error)
}

export const extendedLandingPages = {
  getWebsite: (eventId, cancelToken = undefined) =>
    fetchAPI(EXTENDED_LANDING_PAGES_API)({
      url: `/website`,
      params: {
        c5events_event_id: eventId
      },
      cancelToken
    }),
  getPage: (pageId, cancelToken = undefined) =>
    fetchAPI(EXTENDED_LANDING_PAGES_API)({
      url: `/page/${pageId}`,
      cancelToken
    }),
  savePageContent: (pageId, content, cancelToken = undefined) =>
    fetchAPI(EXTENDED_LANDING_PAGES_API)({
      method: 'post',
      url: `/page/save`,
      data: {
        page_id: pageId,
        editor_json: content
      },
      cancelToken
    }),
  syncWebsitePages: (websiteId, createdPages, updatedPages, deletedPages) =>
    fetchAPI(EXTENDED_LANDING_PAGES_API)({
      method: 'post',
      url: `/website/sync-pages`,
      data: {
        website_id: websiteId,
        create: createdPages,
        update: updatedPages,
        delete: deletedPages
      }
    }),
  setWebsiteHeader: (websiteId, file) => {
    const formData = new FormData()
    formData.append('website_id', websiteId)
    formData.append('file', file)

    return fetchAPI(EXTENDED_LANDING_PAGES_API)({
      method: 'post',
      url: `/website/header`,
      data: formData
    })
  }
}

export const sms = {
  getSmsList: (eventId, cancelToken = undefined) =>
    fetchAPI(SMS_API)({
      url: `/sms/list`,
      params: {
        event_id: eventId
      },
      cancelToken
    }),
  getSmsDetail: (id, cancelToken = undefined) =>
    fetchAPI(SMS_API)({
      url: `/sms/get/${id}`,
      cancelToken
    }),
  createSms: data =>
    fetchAPI(SMS_API)({
      method: 'post',
      url: `/sms/create`,
      data
    }),
  saveSms: (id, data) =>
    fetchAPI(SMS_API)({
      method: 'put',
      url: `/sms/save/${id}`,
      data
    }),
  sendSms: id =>
    fetchAPI(SMS_API)({
      method: 'post',
      url: `/sms/send/${id}`
    }),
  scheduleSms: id =>
    fetchAPI(SMS_API)({
      method: 'post',
      url: `/sms/schedule/${id}`
    }),
  cancelSms: id =>
    fetchAPI(SMS_API)({
      method: 'post',
      url: `/sms/cancel/${id}`
    }),
  deleteSms: id =>
    fetchAPI(SMS_API)({
      method: 'delete',
      url: `/sms/delete/${id}`
    })
}
