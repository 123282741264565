import React from 'react'
import clsx from 'clsx'
import './Wrapper.scss'

export default function Wrapper({ children, className, flex }) {
  return (
    <div className={clsx('Wrapper', className)} data-flex={flex}>
      {children}
    </div>
  )
}
