import './Toggle.scss'
import React from 'react'

const Toggle = ({ label, handleChange, disabled, size, ...props }) => (
  <div className="Toggle" data-size={size}>
    <input
      {...props}
      type="checkbox"
      checked={!disabled}
      onChange={handleChange}
    />
    <label htmlFor={props.id}>
      <div className="t">{label}</div>
    </label>
  </div>
)

export default Toggle
