import './SettingsModule.scss'
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

export const SettingsModule = ({
  children,
  className,
  description,
  title,
  rightContent,
  size
}) => {
  return (
    <div className={clsx('SettingsModule', className)} data-size={size}>
      {title && (
        <div className="SettingsModuleHeader SettingsModuleInset">
          <div className="SettingsModuleHeaderInner">
            <h3 className="title">{title}</h3>
            {rightContent && <div className="content">{rightContent}</div>}
          </div>

          {description && (
            <p className="SettingsModuleDescription">{description}</p>
          )}
        </div>
      )}

      {children && (
        <div className="SettingsModuleContent SettingsModuleInset">
          {children}
        </div>
      )}
    </div>
  )
}

export default SettingsModule

SettingsModule.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string
}
