import './Guest.scss'
import React from 'react'
import PropTypes from 'prop-types'

// Components
import {
  IconCheckmark,
  IconCancel
} from 'components/AccordionTable/Accordion/Guest/icons'

export const Guest = ({
  data,
  filteredFields,
  isCanceled,
  handleAccordionClick,
  accordionId,
  children
}) => (
  <div
    className="Guest"
    data-canceled={isCanceled}
    onClick={() => handleAccordionClick && handleAccordionClick(accordionId)}>
    <div className="fields">
      {data &&
        filteredFields.map((field, index) => {
          // console.log(data[field.key])
          // console.log(field)
          // console.log(data)
          let value = data[field.key]
          if (field.type === 'BOOL') {
            if (data[field.key] === true) {
              value = <IconCheckmark />
              // value = "+"
            } else {
              value = <IconCancel fill="#ff0000" />
            }
          }
          return <GuestField key={index}>{value}</GuestField>
        })}
    </div>

    <div className="meta">{children}</div>
  </div>
)

export const GuestTitle = ({ fields }) => (
  <div className="GuestTitle">
    <div className="fields">
      {fields &&
        fields.map((field, index) => {
          return (
            <div key={index} className="label">
              {field.label}
            </div>
          )
        })}
    </div>
    <div className="meta" />
  </div>
)

const GuestField = ({ children }) => (
  <div
    className="GuestField"
    title={typeof children === 'string' ? children : null}>
    {children}
  </div>
)

export const NumberOfGuests = ({ guests }) => (
  <div className="NumberOfGuests">
    <span>{guests}</span>
  </div>
)

export const PartyCheckinStatus = ({ party }) => {
  const checkedInMembers = party.guests.filter(guest => guest.checked_in)
  const isPartyCheckedIn = checkedInMembers.length > 0

  const progress =
    isPartyCheckedIn && checkedInMembers.length < party.guests.length
      ? `${checkedInMembers.length}/${party.guests.length}`
      : ''

  return (
    <div className="PartyCheckinStatus">
      {isPartyCheckedIn && <IconCheckmark />}
      {progress && <span>{progress}</span>}
    </div>
  )
}

Guest.propTypes = {
  data: PropTypes.object.isRequired,
  isCanceled: PropTypes.bool,
  handleAccordionClick: PropTypes.func,
  accordionId: PropTypes.number,
  children: PropTypes.any.isRequired
}

GuestTitle.propTypes = {
  fields: PropTypes.array.isRequired
}

GuestField.propTypes = {
  children: PropTypes.any
}

NumberOfGuests.propTypes = {
  guests: PropTypes.number
}

PartyCheckinStatus.propTypes = {
  party: PropTypes.object.isRequired
}
