import './AccordionTable.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// Components
import TableHeader from 'components/AccordionTable/TableHeader'

import { Accordion, Content } from 'components/AccordionTable/Accordion'
import {
  Guest,
  GuestTitle,
  NumberOfGuests,
  PartyCheckinStatus
} from 'components/AccordionTable/Accordion/Guest'

import {
  GuestActions,
  PartyActions
} from 'components/AccordionTable/Accordion/Guest/Actions'

import { IconCheckmark } from 'components/AccordionTable/Accordion/Guest/icons'

const fieldTypeOrder = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'COMPANY_NAME',
  'PHONE_NUMBER',
  'TEXT',
  'NUMBER',
  'BOOL',
  'SELECT_SINGLE_CHOICE',
  'SELECT_MULTIPLE_CHOICE'
]

const sortFields = fields =>
  fields.sort((a, b) => {
    // Will sort fields in the 'fieldTypeOrder'
    // all fields that are not present in the 'fieldTypeOrder' will be put last
    const indexA = fieldTypeOrder.indexOf(a.type)
    const indexB = fieldTypeOrder.indexOf(b.type)

    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })

const filterGuests = (guestTypeArray, guestType) =>
  guestTypeArray.filter(({ guest_type }) => guest_type === guestType)

const formatAddedDate = timestamp =>
  moment.parseZone(timestamp).format('YYYY-MM-DD, HH:mm')

const AccordionTable = ({
  event,
  fields,
  parties,
  cancelParty,
  restoreParty,
  deleteGuest,
  toggleCancelGuest,
  debug
}) => {
  const [accordionOpen, setAccordionOpen] = useState(null)

  if (parties.length === 0) {
    return null
  }

  const handleAccordionClick = index => {
    setAccordionOpen(accordionOpen === index ? null : index)
  }

  // Sort out current fieldtypes
  const leaderFields = filterGuests(fields, 'leader')
  const followerFields = filterGuests(fields, 'follower')
  const sortedLeaderFields = sortFields(leaderFields).slice(0, 4)
  const sortedFollowerFields = sortFields(followerFields).slice(0, 4)

  return (
    <div className="AccordionTable" data-debug={debug}>
      <TableHeader fields={sortedLeaderFields} />

      {parties.map((party, index) => {
        // Party specific information
        const isActive = accordionOpen === index
        const leader = party.guests.find(
          ({ guest_type }) => guest_type === 'leader'
        )
        const guests = filterGuests(party.guests, 'follower')
        const partyIsCanceled = party.guests.every(guest => guest.canceled)

        return (
          <Accordion key={index} isActive={isActive}>
            <Guest
              data={leader.guest_data}
              filteredFields={sortedLeaderFields}
              isCanceled={partyIsCanceled}
              handleAccordionClick={handleAccordionClick}
              accordionId={index}>
              <div className="date">{formatAddedDate(party.added)}</div>
              <PartyCheckinStatus party={party} />
              {guests.length > 0 && (
                <NumberOfGuests
                  guests={party.guests.filter(guest => !guest.canceled).length}
                />
              )}
            </Guest>

            {isActive && (
              <Content>
                <PartyActions
                  handleCancelParty={cancelParty}
                  handleRestoreParty={restoreParty}
                  partyId={party.id}
                  party={guests}
                  leader={leader}
                  leaderFields={leaderFields}
                  followerFields={followerFields}
                  canceled={partyIsCanceled}
                  canRestore={
                    !event.guest_max_limit ||
                    (event.state !== 'FULL' &&
                      event.nr_of_guests + guests.length <=
                        event.guest_max_limit)
                  }
                />
                <Guest
                  isCanceled={leader.canceled}
                  data={leader.guest_data}
                  filteredFields={sortedLeaderFields}>
                  <div className="date">{formatAddedDate(leader.added)}</div>
                  {leader.checked_in && <IconCheckmark />}
                  <GuestActions
                    guest={leader}
                    guestId={leader.id}
                    partyId={party.id}
                    eventFull={event.state === 'FULL'}
                    isCanceled={leader.canceled}
                    handleToggleGuestCancel={toggleCancelGuest}
                    handledeleteGuest={deleteGuest}
                  />
                </Guest>

                {guests.length > 0 && (
                  <GuestTitle fields={sortedFollowerFields} />
                )}

                {guests &&
                  guests.map((guest, index) => (
                    <Guest
                      isCanceled={guest.canceled}
                      data={guest.guest_data}
                      filteredFields={sortedFollowerFields}
                      key={index}>
                      <div className="date">{formatAddedDate(guest.added)}</div>
                      {guest.checked_in && <IconCheckmark />}
                      <GuestActions
                        guest={guest}
                        guestId={guest.id}
                        partyId={party.id}
                        eventFull={event.state === 'FULL'}
                        isCanceled={guest.canceled}
                        handleToggleGuestCancel={toggleCancelGuest}
                        handleDeleteGuest={deleteGuest}
                      />
                    </Guest>
                  ))}
              </Content>
            )}
          </Accordion>
        )
      })}
    </div>
  )
}

export default AccordionTable

AccordionTable.propTypes = {
  fields: PropTypes.array,
  parties: PropTypes.array,
  cancelParty: PropTypes.func.isRequired,
  deleteGuest: PropTypes.func.isRequired,
  toggleCancelGuest: PropTypes.func.isRequired,
  debug: PropTypes.bool
}
