import './Nav.scss'
import React from 'react'
import clsx from 'clsx'

import { NavLink } from 'react-router-dom'

const NavItem = ({ children, to, disabled, separator }) => (
  <div className="NavItem">
    {separator}

    <NavLink
      activeClassName="active"
      to={to}
      data-disabled={disabled}
      onClick={e => disabled && e.preventDefault()}>
      {children}
    </NavLink>
  </div>
)

const Nav = ({ className, items, separator }) => (
  <div className={clsx('Nav', className)}>
    {items &&
      items.map((item, index) => (
        <NavItem
          key={index}
          to={item.to}
          disabled={item.disabled}
          separator={index > 0 && separator}>
          {item.label}
        </NavItem>
      ))}
  </div>
)

export default Nav
