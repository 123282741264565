import React from 'react'
import PropTypes from 'prop-types'

import Textarea from 'components/Inputs/Textarea'

const OptionsArea = ({
  id,
  value = [''],
  placeholder,
  handleChange,
  handleBlur
}) => {
  const connectedValues = value.map(({ value }) => value).join('\n')

  return (
    <Textarea
      placeholder={placeholder}
      className="Textarea"
      id={id}
      value={connectedValues}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  )
}

export default OptionsArea

OptionsArea.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func
}
