import React, { useContext } from 'react'
import Text from 'components/Inputs/Text'
import { SettingsModule } from 'components/Settings/SettingsModule'
import { useTranslation } from 'react-i18next'
import { ErrorContext } from 'components/Contexts/ErrorProvider'

const CampaignId = ({ campaignId, updateCampaignId }) => {
  const { error, setError } = useContext(ErrorContext)
  const { t } = useTranslation()
  const isInvalid =
    error &&
    error.data && (!error.data.data || Object.keys(error.data.data).indexOf('campaign_id') !== -1)
  const handleError = () => {
    if (error && error.data && error.data.data) {
      delete error.data.data.campaign_id
    }
    setError(error)
  }

  return (
    <SettingsModule
      title={t('campaignId')}
      description={t('helpTexts.campaignId')}>
      <Text
        className={isInvalid ? 'error-input' : ''}
        value={campaignId}
        handleChange={({ target }) => {
          updateCampaignId(target.value)
          handleError()
        }}
        placeholder={t('campaignId')}
        id="campaignId"
      />
      {isInvalid && (
        <p className="SettingsModuleDescription error">
          {t('helpTexts.campaignIdError')}
        </p>
      )}
    </SettingsModule>
  )
}

export default CampaignId
