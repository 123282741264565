import './ConfirmationEmail.scss'
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Inputs
import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import Textarea from 'components/Inputs/Textarea'

import Button from 'components/Button'
import Toggle from 'components/Toggle'
import SettingsModule from 'components/Settings/SettingsModule'
import PreviewEmailModal from 'components/Modals/PreviewEmailModal'
import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import { ErrorContext } from '../../Contexts/ErrorProvider'

const FormInputs = ({
  header,
  headerContent,
  body,
  bodyContent,
  handleInputChange
}) => {
  const { t } = useTranslation()

  return (
    <>
      <InputContainer id={header} label={t('header')}>
        <Text
          value={headerContent}
          handleChange={handleInputChange}
          placeholder={t('header')}
          name="header"
        />
      </InputContainer>
      <InputContainer id={body} label={t('body text')}>
        <Textarea
          value={bodyContent}
          handleChange={handleInputChange}
          placeholder={t('text')}
          name="content"
        />
      </InputContainer>
    </>
  )
}

const EmailSettings = ({
  confirmationEmail,
  startTime,
  guestlistId,
  hasUnsavedChanges,
  endTime,
  emailButtonPreview,
  emailLogoPreview,
  location,
  updateEmailSettings
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const { settings } = useContext(OrganizationContext)
  const { t } = useTranslation()

  const handleToggleChange = ({ target }) => {
    updateEmailSettings({
      ...confirmationEmail,
      [target.name]: target.checked
    })
  }

  const handleInputChange = ({ target }) => {
    updateEmailSettings({
      ...confirmationEmail,
      [target.name]: target.value
    })
  }

  const logotype = settings && settings.email && settings.email.header_image_url

  const {
    header = '',
    content = '',
    enabled = false,
    event_information = false,
    subject = ''
  } = confirmationEmail

  return (
    <>
      <SettingsModule
        title={t('confirmation email')}
        className="EmailSettings"
        rightContent={
          <>
            {enabled ? (
              <Button
                type="secondary"
                disabled={hasUnsavedChanges}
                onClick={() => setPreviewModalOpen(true)}>
                {t('preview')}
              </Button>
            ) : null}
            <div className="SettingsModuleSpacer">
              <Toggle
                size="large"
                id="confirmation-email-enabled"
                name="enabled"
                handleChange={handleToggleChange}
                disabled={!enabled}
              />
            </div>
          </>
        }>
        {enabled && (
          <div className="EmailContent">
            <InputContainer id="emailSubject" label={t('subject')}>
              <Text
                value={subject}
                handleChange={handleInputChange}
                placeholder={t('subject')}
                name="subject"
              />
            </InputContainer>
            <FormInputs
              header="emailTitle"
              headerContent={header}
              body="emailContent"
              bodyContent={content}
              handleInputChange={handleInputChange}
            />
            <div className="EventInformationToggle">
              <Toggle
                size="large"
                id="event_information"
                name="event_information"
                handleChange={handleToggleChange}
                disabled={!event_information}
              />
              <b>{t('event information')}</b> ({t('date, time and location')})
            </div>
          </div>
        )}
      </SettingsModule>

      {previewModalOpen && (
        <PreviewEmailModal
          logotype={logotype}
          title={header}
          content={content}
          eventInformationVisible={event_information}
          startTime={startTime}
          endTime={endTime}
          location={location}
          emailButtonPreview={emailButtonPreview}
          emailLogoPreview={emailLogoPreview}
          guestlistId={guestlistId}
          handleClose={() => setPreviewModalOpen(false)}
        />
      )}
    </>
  )
}

const CalendarSettings = ({
  calendarReminder,
  updateCalendarSettings,
  confirmationEmailActive
}) => {
  const { t } = useTranslation()
  const { error, setError } = useContext(ErrorContext)
  const nameIsInvalid =
    error && error?.data?.detail?.error_type === 'reminder_name'

  const handleToggleChange = ({ target }) => {
    if (confirmationEmailActive) {
      updateCalendarSettings({
        ...calendarReminder,
        enabled: target.checked
      })
    }
  }

  const handleInputChange = ({ target }) => {
    setError('')

    updateCalendarSettings({
      ...calendarReminder,
      [target.name]: target.value
    })
  }

  const handleInputBlur = ({ target }) => {
    updateCalendarSettings({
      ...calendarReminder,
      [target.name]: target.value.trim()
    })
  }

  const {
    name = '',
    header = '',
    content = '',
    enabled = false
  } = calendarReminder

  return (
    <SettingsModule
      title={t('calendar reminder')}
      className="CalendarSettings"
      rightContent={
        <div className="SettingsModuleSpacer">
          <Toggle
            size="large"
            id="calendar"
            handleChange={handleToggleChange}
            disabled={!enabled || !confirmationEmailActive}
          />
        </div>
      }>
      {enabled && (
        <div className="CalendarContent">
          <InputContainer id="emailSubject" label={t('reminderName')}>
            <Text
              value={name}
              handleChange={handleInputChange}
              handleBlur={handleInputBlur}
              placeholder="reminder"
              name="name"
            />
          </InputContainer>
          {nameIsInvalid && (
            <p className="SettingsModuleDescription error">
              {t('helpTexts.invalidReminderName')}
            </p>
          )}
          <FormInputs
            header="calendarTitle"
            headerContent={header}
            body="calendarContent"
            bodyContent={content}
            handleInputChange={handleInputChange}
          />
        </div>
      )}
    </SettingsModule>
  )
}

const ConfirmationEmail = ({
  currentState: {
    start_time,
    end_time,
    email_button_preview,
    email_logo_preview,
    data: { confirmation_email = {}, calendar_reminder = {}, location },
    guestlists_id
  },
  updateEmailSettings,
  updateCalendarSettings,
  hasUnsavedChanges
}) => (
  <>
    <EmailSettings
      confirmationEmail={confirmation_email}
      startTime={start_time}
      endTime={end_time}
      location={location}
      hasUnsavedChanges={hasUnsavedChanges}
      emailButtonPreview={email_button_preview}
      emailLogoPreview={email_logo_preview}
      updateEmailSettings={updateEmailSettings}
      guestlistId={guestlists_id ? guestlists_id[0] : null}
    />
    {confirmation_email.enabled ? (
      <CalendarSettings
        confirmationEmailActive={confirmation_email.enabled}
        calendarReminder={calendar_reminder}
        updateCalendarSettings={updateCalendarSettings}
      />
    ) : null}
  </>
)

export default ConfirmationEmail
