import './Accordion.scss'
import React from 'react'
import PropTypes from 'prop-types'

export const Accordion = ({ children, isActive }) => (
  <div className="Accordion" data-open={isActive}>
    {children}
  </div>
)

export const Content = ({ children }) => (
  <div className="AccordionContent">{children}</div>
)

export default Accordion

Accordion.propTypes = {
  children: PropTypes.any.isRequired,
  isActive: PropTypes.bool
}

Content.propTypes = {
  children: PropTypes.any.isRequired
}
