import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import CopyEmailsModal from 'components/Modals/CopyEmailsModal'
import CopyIcon from '../icons/Copy'

import { useTranslation } from 'react-i18next'

const CopyEmails = ({ eventId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="CopyEmails">
      <Button type="toolbar" onClick={() => setModalIsOpen(true)}>
        <CopyIcon />
        {t('copy email list')}
      </Button>

      {modalIsOpen && (
        <CopyEmailsModal
          handleCloseModal={() => setModalIsOpen(false)}
          eventId={eventId}
        />
      )}
    </div>
  )
}

export default CopyEmails

CopyEmails.propTypes = {
  eventId: PropTypes.number.isRequired
}
