import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

import {
  addHours,
  ceilToMinutes,
  DATE_TODAY,
  isEarlierDate,
  subtractHours
} from 'misc/calendar'

import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import { SettingsModule } from 'components/Settings/SettingsModule'
import DateTimePicker from 'components/DateTimePicker'
import InputContainer from 'components/Inputs/Container'
import TimezonePicker from 'components/TimezonePicker'
import Dropdown from 'components/Dropdown'

import { useTranslation } from 'react-i18next'

const setTimeOffset = (timestamp, offset) =>
  moment(timestamp)
    .utcOffset(offset)
    .format()

const formatTimezoneString = timezone => {
  return timezone.replace('_', ' ')
}

export const TimeZone = ({ timeZoneLocation = '', setIsOpen, ...props }) => {
  const { t } = useTranslation()

  const [location, setLocation] = useState(
    formatTimezoneString(timeZoneLocation)
  )

  const handleLocationChange = ({ target }) => {
    setIsOpen(true)
    setLocation(target.value)
  }

  useEffect(() => {
    setLocation(formatTimezoneString(timeZoneLocation))
  }, [timeZoneLocation])

  return (
    <InputContainer id="timeZone" label={t('timezone')}>
      <TimezonePicker
        {...props}
        value={location}
        handleLocationChange={handleLocationChange}
        handleCloseTimezonePicker={() => setIsOpen(false)}
      />
    </InputContainer>
  )
}

const EventTime = ({
  startTime,
  endTime,
  timeZoneLocation,
  handleStartTimeUpdate,
  handleEndTimeUpdate,
  handleTimeZoneLocationUpdate
}) => {
  const { t } = useTranslation()

  const rootDate = ceilToMinutes(DATE_TODAY, 30)
  const formatedStartTime = startTime ? startTime : rootDate.format()
  const formatedEndTime = endTime ? endTime : rootDate.add(1, 'hours').format()

  const handleStartTimeChange = startTime => {
    handleStartTimeUpdate(startTime)
    if (isEarlierDate(endTime, startTime)) {
      handleEndTimeUpdate(addHours(startTime, 1))
    }
  }

  const handleEndTimeChange = endTime => {
    handleEndTimeUpdate(endTime)
    if (!isEarlierDate(startTime, endTime)) {
      handleStartTimeUpdate(subtractHours(endTime, 1))
    }
  }

  const handleTimeZoneUpdate = timezone => {
    const offset = moment.parseZone(moment.tz(timezone)).format('Z')

    handleTimeZoneLocationUpdate(timezone)
    handleStartTimeUpdate(setTimeOffset(startTime, offset))
    handleEndTimeUpdate(setTimeOffset(endTime, offset))
  }

  return (
    <SettingsModule
      title={t('date and time')}
      description={t('helpTexts.dateTime')}>
      <OrganizationContext.Consumer>
        {({ settings }) => (
          <Dropdown
            className="TimezoneDropdown"
            onSelect={handleTimeZoneUpdate}>
            {dropdownProps => (
              <TimeZone
                {...dropdownProps}
                timeZoneLocation={
                  timeZoneLocation ||
                  (settings && settings.time_preferences.timezone) ||
                  ''
                }
                handleOffsetUpdate={handleTimeZoneUpdate}
              />
            )}
          </Dropdown>
        )}
      </OrganizationContext.Consumer>
      <InputContainer label={t('start date')}>
        <DateTimePicker
          value={formatedStartTime}
          handleChange={handleStartTimeChange}
        />
      </InputContainer>
      <InputContainer label={t('end date')}>
        <DateTimePicker
          value={formatedEndTime}
          handleChange={handleEndTimeChange}
        />
      </InputContainer>
    </SettingsModule>
  )
}

export default EventTime
