import './DatePicker.scss'
import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Calendar from 'components/DateTimePicker/Calendar'
import Picker from 'components/DateTimePicker/Picker'

const CalendarIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      className="CalendarIcon">
      <path d="M13.75,3.5 L6.25,3.5 L6.25,4.125 C6.25,4.47017797 5.97017797,4.75 5.625,4.75 C5.27982203,4.75 5,4.47017797 5,4.125 L5,3.5 L1.25,3.5 L1.25,6 L18.75,6 L18.75,3.5 L15,3.5 L15,4.125 C15,4.47017797 14.720178,4.75 14.375,4.75 C14.029822,4.75 13.75,4.47017797 13.75,4.125 L13.75,3.5 Z M15,2.25 L18.75,2.25 C19.4403559,2.25 20,2.77979638 20,3.43333333 L20,18.8166667 C20,19.4702036 19.4403559,20 18.75,20 L1.25,20 C0.559644063,20 8.45442189e-17,19.4702036 0,18.8166667 L0,3.43333333 C-8.45442189e-17,2.77979638 0.559644063,2.25 1.25,2.25 L5,2.25 L5,1.625 C5,1.27982203 5.27982203,1 5.625,1 C5.97017797,1 6.25,1.27982203 6.25,1.625 L6.25,2.25 L13.75,2.25 L13.75,1.625 C13.75,1.27982203 14.029822,1 14.375,1 C14.720178,1 15,1.27982203 15,1.625 L15,2.25 Z M1.25,7 L1.25,18.8 L18.75,18.8 L18.75,7 L1.25,7 Z M5.5,11 L14.5,11 C14.7761424,11 15,11.2238576 15,11.5 L15,11.75 C15,12.0261424 14.7761424,12.25 14.5,12.25 L5.5,12.25 C5.22385763,12.25 5,12.0261424 5,11.75 L5,11.5 C5,11.2238576 5.22385763,11 5.5,11 Z M5.5,14.75 L9.5,14.75 C9.77614237,14.75 10,14.9738576 10,15.25 L10,15.5 C10,15.7761424 9.77614237,16 9.5,16 L5.5,16 C5.22385763,16 5,15.7761424 5,15.5 L5,15.25 C5,14.9738576 5.22385763,14.75 5.5,14.75 Z" />
    </svg>
  )
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.datePickerContainer = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = e => {
    if (
      this.datePickerContainer &&
      this.datePickerContainer.current &&
      this.datePickerContainer.current.contains(e.target) === false
    ) {
      this.props.handleCloseDatePicker()
    }
  }

  handleKeyDown = e => {
    e.keyCode === 27 && this.props.handleCloseDatePicker()
  }

  handleSelect = date => {
    this.props.handleDateClick(date)
    this.props.handleCloseDatePicker()
  }

  handlePickerClick = () => {
    if (this.props.readOnly) {
      return
    }

    this.props.handleDatePickerClick()
  }

  render() {
    const {
      activeDate,
      calendarIsOpen,
      className,
      readOnly,
      errored
    } = this.props

    return (
      <div
        className={clsx('DatePicker', className, {
          'is-readonly': readOnly,
          'is-errored': errored
        })}
        ref={this.datePickerContainer}
        onKeyDown={this.handleKeyDown}
        tabIndex="-1">
        <Picker
          icon={<CalendarIcon />}
          value={moment.parseZone(activeDate).format('Do MMMM YYYY')}
          handleClick={this.handlePickerClick}
          isOpen={calendarIsOpen}
        />

        {calendarIsOpen && !readOnly && (
          <Calendar
            activeDate={activeDate}
            handleDateClick={this.handleSelect}
          />
        )}
      </div>
    )
  }
}

export default DatePicker

DatePicker.propTypes = {
  active: PropTypes.object,
  calendarIsOpen: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  handleDatePickerClick: PropTypes.func.isRequired,
  handleCloseDatePicker: PropTypes.func.isRequired,
  handleDateClick: PropTypes.func.isRequired
}
