import './SendSmsModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

export const SendSmsModal = ({
  title,
  description,
  handleConfirm,
  handleClose
}) => {
  const { t } = useTranslation()

  return (
    <Modal modalName="SendSmsModal">
      <Header>{title}</Header>
      <Content>
        <p>{description}</p>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('cancel')}
          </Button>
        }
        rightActions={
          <Button onClick={handleConfirm} type="success">
            {t('confirm')}
          </Button>
        }
      />
    </Modal>
  )
}

export default SendSmsModal
