import './Loader.scss'
import React from 'react'

export const Loader = ({ size }) => (
  <div className="Loader" data-size={size}>
    <div className="item" />
    <div className="item" />
    <div className="item" />
    <div className="item" />
  </div>
)

export default Loader
