import './ErrorSmsModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

const WarningIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    stroke="currentColor">
    <path
      d="M14.9999 28.9284C22.6924 28.9284 28.9284 22.6924 28.9284 14.9999C28.9284 7.30732 22.6924 1.07129 14.9999 1.07129C7.30732 1.07129 1.07129 7.30732 1.07129 14.9999C1.07129 22.6924 7.30732 28.9284 14.9999 28.9284Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 7.5V13.9286"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9997 21.4285C15.5914 21.4285 16.0711 20.9488 16.0711 20.3571C16.0711 19.7653 15.5914 19.2856 14.9997 19.2856C14.4079 19.2856 13.9282 19.7653 13.9282 20.3571C13.9282 20.9488 14.4079 21.4285 14.9997 21.4285Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ErrorSmsModal = ({ title, descriptions, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Modal modalName="ErrorSmsModal">
      <Header>
        <WarningIcon />
        {title}
      </Header>
      <Content>
        {descriptions.map((description, index) => (
          <p key={index}>{description}</p>
        ))}
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
      />
    </Modal>
  )
}

export default ErrorSmsModal
