import './DuplicateEventModal.scss'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import DateTimePicker from 'components/DateTimePicker'

import { addHours, isEarlierDate, subtractHours } from 'misc/calendar'

const DuplicateEventModal = withRouter(
  ({ event, duplicateEvent, handleClose, handleNameChange, history }) => {
    const { t } = useTranslation()
    const [newEvent, setNewEventData] = useState(event)
    const [submitting, setSubmitting] = useState(false)

    const handleDuplicateClick = () => {
      if (submitting) return

      setSubmitting(true)

      duplicateEvent(newEvent)
        .then(({ data, status }) => {
          if (status === 201) {
            history.push(`/event/${data.event}/edit`)
          }
        })
        .catch(error => console.error(error))
        .finally(() => {
          setSubmitting(false)
          handleClose()
        })
    }

    const setData = data =>
      setNewEventData({
        ...newEvent,
        ...data
      })

    const handleStartTimeChange = start_time => {
      setData({
        start_time,
        end_time: isEarlierDate(newEvent.end_time, start_time)
          ? addHours(start_time, 1)
          : newEvent.end_time
      })
    }

    const handleEndTimeChange = end_time => {
      setData({
        end_time,
        start_time: !isEarlierDate(newEvent.start_time, end_time)
          ? subtractHours(end_time, 1)
          : newEvent.start_time
      })
    }

    useEffect(() => {
      setData({ name: `${newEvent.name} – ${t('duplication')}` })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Modal size="large" modalName="DuplicateEventModal">
        <Header>{t('duplicate event')}</Header>
        <Content>
          <InputContainer id="event_name" label={t('event name')}>
            <Text
              value={newEvent.name}
              placeholder={t('event name')}
              id="event_name"
              handleChange={event => setData({ name: event.target.value })}
              readOnly={submitting}
            />
          </InputContainer>
          <InputContainer label={t('start date')}>
            <DateTimePicker
              value={newEvent.start_time}
              handleChange={handleStartTimeChange}
              readOnly={submitting}
            />
          </InputContainer>
          <InputContainer label={t('end date')}>
            <DateTimePicker
              value={newEvent.end_time}
              handleChange={handleEndTimeChange}
              readOnly={submitting}
            />
          </InputContainer>
        </Content>
        <Actions
          leftActions={
            <Button onClick={handleClose} type="secondary">
              {t('close')}
            </Button>
          }
          rightActions={
            <Button
              type="primary"
              onClick={handleDuplicateClick}
              disabled={submitting}>
              {t('duplicate')}
            </Button>
          }
        />
      </Modal>
    )
  }
)

export default DuplicateEventModal
