import React from 'react'
import './Logo.scss'
import clsx from 'clsx'

export default class Logo extends React.PureComponent {
  render() {
    return (
      <div className={clsx('Logo', this.props.className)}>
        <svg className="bounds">
          <g className="text">
            <path d="M13.4 27.2C6.1 27.2 0 21.9 0 13.6 0 5.3 6.3 0 13.3 0 20.5 0 24.2 4.4 25.2 8.6L20.5 10.2C19.8 7.7 17.8 4.9 13.3 4.9 9.4 4.9 5.3 7.7 5.3 13.6 5.3 19.2 9.2 22.2 13.4 22.2 17.8 22.2 20 19.3 20.7 16.9L25.4 18.3C24.4 22.3 20.7 27.2 13.4 27.2ZM27.8 19.4L32.9 18.3C33.1 20.7 34.9 22.6 37.6 22.6 40.1 22.6 42 21 42 18.4 42 15.5 39.9 14.1 37.5 14.1 35.7 14.1 34.4 14.9 33.5 15.8 33 15.6 29.1 14.3 28.5 14.1L31.6 1 46.1 1 46.1 5.7 35.3 5.7 34 11.3C35.2 10.3 37 9.7 38.7 9.7 43.8 9.7 47.5 12.8 47.5 18.3 47.5 23.1 43.8 27.3 37.6 27.3 32 27.3 28.1 23.5 27.8 19.4Z" />
          </g>
        </svg>
      </div>
    )
  }
}
