import './TimeSlots.scss'
import React from 'react'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import TimeSlot from 'components/TimeSlot'

const TimeSlots = ({
  addTimeSlot,
  removeTimeSlot,
  updateTimeSlot,
  className,
  guestlists
}) => {
  const { t } = useTranslation()

  const mergeTimeSlotData = (data, keyIndex) =>
    guestlists.map(guestlist => (guestlist.key === keyIndex ? data : guestlist))

  const createChangeHandler = (guestlist, keyIndex) => event => {
    updateTimeSlot(
      mergeTimeSlotData(
        {
          ...guestlist,
          [event.target.name]:
            event.target.value && !isNaN(event.target.value)
              ? event.target.name === 'guest_max_limit' &&
                parseInt(event.target.value, 10) === 0
                ? null
                : parseInt(event.target.value, 10)
              : event.target.value
        },
        keyIndex
      )
    )
  }

  const createDateChangeHandler = (guestlist, keyIndex) => date => {
    const old_end_time = moment(guestlist.end_time)
    const start_time = moment(date).format()
    const end_time = moment(date)
      .set('hours', old_end_time.hours())
      .set('minutes', old_end_time.minutes())
      .format()

    updateTimeSlot(
      mergeTimeSlotData(
        {
          ...guestlist,
          start_time,
          end_time
        },
        keyIndex
      )
    )
  }

  const updateMultiple = (keyIndex, guestlist, data) => {
    updateTimeSlot(
      mergeTimeSlotData(
        {
          ...guestlist,
          ...data
        },
        keyIndex
      )
    )
  }

  const createTimeChangeHandler = (guestlist, keyIndex) => key => date => {
    updateTimeSlot(
      mergeTimeSlotData(
        {
          ...guestlist,
          [key]: date
        },
        keyIndex
      )
    )
  }

  return (
    <div className={clsx('TimeSlots', className)}>
      {guestlists && guestlists.length > 0 ? (
        <ul className="TimeSlotsList">
          {guestlists.map(guestlist => (
            <li className="TimeSlotsItem" key={guestlist.key}>
              <TimeSlot
                guestlist={guestlist}
                updateGuestlist={data => {
                  updateMultiple(guestlist.key, guestlist, data)
                }}
                handleChange={createChangeHandler(guestlist, guestlist.key)}
                handleDateClick={createDateChangeHandler(
                  guestlist,
                  guestlist.key
                )}
                handleClickTime={createTimeChangeHandler(
                  guestlist,
                  guestlist.key
                )}
                removeTimeSlot={() => removeTimeSlot(guestlist.key)}
                isOnly={guestlists.length === 1}
              />
            </li>
          ))}
        </ul>
      ) : null}
      <div>
        <Button type="success" onClick={addTimeSlot}>
          {t('add timeslot')}
        </Button>
      </div>
    </div>
  )
}

export default TimeSlots
