import React from 'react'
import clsx from 'clsx'

import Button from 'components/Button'
import LoadingDots from 'components/LoadingDots'

import { useTranslation } from 'react-i18next'

const TrashCan = () =>
  // prettier-ignore
  <svg
    className="TrashCan"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <g>
      <path d="M8.07908007,5.45454545 L11.9209199,5.45454545 L11.9209199,3.04676155 C11.9209199,2.83764159 11.7550309,2.67112015 11.55521,2.67112015 L8.44479005,2.67112015 C8.24119893,2.67112015 8.07908007,2.84151418 8.07908007,3.04676155 L8.07908007,5.45454545 Z M6.36363636,5.45454545 L6.36363636,3.04676155 C6.36363636,1.86949366 7.29864744,0.909090909 8.44479005,0.909090909 L11.55521,0.909090909 C12.7013526,0.909090909 13.6363636,1.86949366 13.6363636,3.04676155 L13.6363636,5.45454545 L16.3636364,5.45454545 C16.8657134,5.45454545 17.2727273,5.86155932 17.2727273,6.36363636 C17.2727273,6.86571341 16.8657134,7.27272727 16.3636364,7.27272727 L15.4545455,7.27272727 L15.4545455,17.0909091 C15.4545455,18.1954786 14.559115,19.0909091 13.4545455,19.0909091 L6.54545455,19.0909091 C5.44088505,19.0909091 4.54545455,18.1954786 4.54545455,17.0909091 L4.54545455,7.27272727 L3.63636364,7.27272727 C3.13428659,7.27272727 2.72727273,6.86571341 2.72727273,6.36363636 C2.72727273,5.86155932 3.13428659,5.45454545 3.63636364,5.45454545 L6.36363636,5.45454545 Z"/>
    </g>
  </svg>

const CancelIcon = () =>
  // prettier-ignore
  <svg
    className="CancelIcon"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <g>
      <path d="M10,8.67768595 L15.9504132,2.72727273 L17.2727273,4.04958678 L11.322314,10 L17.2727273,15.9504132 L15.9504132,17.2727273 L10,11.322314 L4.04958678,17.2727273 L2.72727273,15.9504132 L8.67768595,10 L2.72727273,4.04958678 L4.04958678,2.72727273 L10,8.67768595 Z"/>
    </g>
  </svg>

const HomeIcon = () => (
  <svg
    className="HomeIcon"
    width="17"
    height="16"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1.297 0h5.406c.219 0 .406.078.563.234A.767.767 0 017.5.797v5.406a.767.767 0 01-.234.563.767.767 0 01-.563.234H1.297a.767.767 0 01-.563-.234.767.767 0 01-.234-.563V.797C.5.578.578.39.734.234A.767.767 0 011.297 0zm9 0h5.406c.219 0 .406.078.563.234a.767.767 0 01.234.563v5.406a.767.767 0 01-.234.563.767.767 0 01-.563.234h-5.406a.767.767 0 01-.563-.234.767.767 0 01-.234-.563V.797c0-.219.078-.406.234-.563A.767.767 0 0110.297 0zm-9 9h5.406c.219 0 .406.078.563.234a.767.767 0 01.234.563v5.406a.767.767 0 01-.234.563.767.767 0 01-.563.234H1.297a.767.767 0 01-.563-.234.767.767 0 01-.234-.563V9.797c0-.219.078-.406.234-.563A.767.767 0 011.297 9zm9 0h5.406c.219 0 .406.078.563.234a.767.767 0 01.234.563v5.406a.767.767 0 01-.234.563.767.767 0 01-.563.234h-5.406a.767.767 0 01-.563-.234.767.767 0 01-.234-.563V9.797c0-.219.078-.406.234-.563A.767.767 0 0110.297 9z" />
  </svg>
)

const RedoUndoIcon = ({ direction }) => (
  <div className="RedoUndoIcon" data-direction={direction}>
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g>
        <path
          d="M11.0342485,2 C7.61225631,2 4.69688544,4.17901953 3.58347184,7.23276172 L2.45293204,5.2630625 L1,6.1068125 L2.85242718,9.33412109 L3.69126214,10.7955664 L5.14419417,9.95178125 L8.35266796,8.0885 L7.51383301,6.62708984 L5.26271456,7.934375 C5.5623534,7.07716016 6.05167379,6.29152344 6.70935534,5.63023438 C7.86453592,4.46796875 9.40061748,3.828125 11.0342485,3.828125 C12.6678796,3.828125 14.2039961,4.46796875 15.3591417,5.63023438 C16.5146369,6.79214844 17.1507534,8.33726563 17.1507534,9.98046875 C17.1507534,11.6236719 16.5146369,13.1687891 15.3591417,14.3307031 C14.2039961,15.4929688 12.6678796,16.1328125 11.0342485,16.1328125 L11.0342485,17.9609375 C15.4161126,17.9609375 18.9682291,14.3880078 18.9682291,9.98046875 C18.9682291,5.57292969 15.4161126,2 11.0342485,2"
          transform="translate(9.984115, 9.980469) scale(-1, 1) translate(-9.984115, -9.980469)"
        />
      </g>
    </svg>
  </div>
)

const DuplicateIcon = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m15 4c1.1045695 0 2 .8954305 2 2v12c0 1.1045695-.8954305 2-2 2h-9c-1.1045695 0-2-.8954305-2-2v-2h-.001v-1.6h.001v-8.4c0-1.1045695.8954305-2 2-2zm-4-4c1.1045695 0 2 .8954305 2 2v.4h-1.6v-.4c0-.2209139-.1790861-.4-.4-.4h-9c-.2209139 0-.4.1790861-.4.4v12c0 .2209139.1790861.4.4.4h.399v1.6h-.399c-1.1045695 0-2-.8954305-2-2v-12c0-1.1045695.8954305-2 2-2z" />
  </svg>
)

export const UndoButtons = ({ disabled, undo, redo, history = [], index }) => (
  <div className="UndoButtons">
    <Button
      onClick={undo}
      disabled={disabled || history.length === 0 || index === 0}>
      <RedoUndoIcon direction="left" />
    </Button>
    <Button
      onClick={redo}
      disabled={
        disabled || history.length === 0 || index + 1 === history.length
      }>
      <RedoUndoIcon />
    </Button>
  </div>
)

export const DeleteButton = ({ className, ...props }) => (
  <Button type="header" className={clsx('DeleteButton', className)} {...props}>
    <TrashCan />
  </Button>
)

export const DuplicateButton = ({ className, ...props }) => (
  <Button
    {...props}
    type="header"
    className={clsx('DuplicateButton', className)}>
    <DuplicateIcon />
  </Button>
)

export const GetLinkButton = props => {
  const { t } = useTranslation()
  return (
    <Button {...props} type="primary" className="GetLinkButton">
      {t('get link')}
    </Button>
  )
}

export const SaveButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button {...props} type="primary" className={clsx('SaveButton', className)}>
      {loading ? <LoadingDots /> : t('save')}
    </Button>
  )
}

export const ResetChangesButton = ({ className, ...props }) => (
  <Button
    {...props}
    type="header"
    className={clsx('ResetChangesButton', className)}>
    <CancelIcon />
  </Button>
)

export const HomeButton = ({ className }) => {
  const { t } = useTranslation()
  return (
    <Button type="dark" className={clsx('HomeButton', className)} to="/">
      <HomeIcon />
      {t('home')}
    </Button>
  )
}
