import moment from 'moment-timezone'
const WEEKS_IN_CALENDAR = 6
export const TIME_STEP = 5
export const DATE_TODAY = moment()

const getFirstWeekOfMonth = (date = DATE_TODAY) =>
  moment(date)
    .startOf('month')
    .week()

const daysInWeek = (date = DATE_TODAY) => {
  return Array(7)
    .fill(0)
    .map((_, index) =>
      moment(date)
        .startOf('week')
        .add(index, 'day')
    )
}

export const getWeeksInMonth = (date = DATE_TODAY) => {
  const firstWeekInMonth = getFirstWeekOfMonth(date)
  const lastWeekInMonth = firstWeekInMonth + WEEKS_IN_CALENDAR

  let firstWeekWithYearAdjust = firstWeekInMonth
  if (moment(date).month() === 0 && moment(date).week() < 7) {
    // console.log("Year bump detected. Adjusting weeks to avoid double year's increment..")
    firstWeekWithYearAdjust =
      lastWeekInMonth > 52 ? firstWeekInMonth - 52 : firstWeekInMonth
  }

  const weeks = Array(lastWeekInMonth - firstWeekInMonth)
    .fill(0)
    .map((_, index) =>
      daysInWeek(moment(date).week(firstWeekWithYearAdjust + index))
    )

  return weeks
}

export const getTimesInDay = (date = DATE_TODAY) => {
  // 24h * 60min / TIME_STEP
  return Array((24 * 60) / TIME_STEP)
    .fill(0)
    .map((_, index) =>
      moment(date)
        .startOf('day')
        .add(index * TIME_STEP, 'minutes')
    )
}

export const isEarlierDate = (firstDate, secondDate) =>
  moment(firstDate).isSameOrBefore(moment(secondDate))

export const addHours = (timestamp, hours = 0) =>
  moment(timestamp)
    .add(hours, 'hours')
    .format()

export const subtractHours = (timestamp, hours = 0) =>
  moment(timestamp)
    .subtract(hours, 'hours')
    .format()

export const ceilToMinutes = (momentObj, minutes = 15) => {
  const remainingMinutes = minutes - (momentObj.minute() % minutes)

  const newMomentObj = moment(momentObj)
    .add(remainingMinutes, 'minutes')
    .seconds(0)

  return newMomentObj
}
