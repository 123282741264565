import { isEqual, cloneDeep, omit } from 'lodash'

export { formFactorys } from 'components/Contexts/UndoProvider/helpers/data'

export const getFormEvent = currentState => {
  const currentEventState = {
    ...currentState,
    fields: currentState.formFactorys.reduce(
      (fields, current) => [...fields, ...current.fields],
      []
    )
  }
  delete currentEventState.formFactorys

  return currentEventState
}

const blacklist = [
  'checkins',
  'last_modified',
  'preview_image',
  'published',
  'sign_ups',
  'state'
]

export const hasFormBeenUpdated = (event, currentState) => {
  if (!event || typeof currentState.event === 'undefined') {
    return false
  }

  const currentEventState = getFormEvent(currentState)

  /*
    If event id doesn't match, clearly there's no need to do
    further comparisons. This could occur during event-to-event
    navigation such as after duplication.
  */
  if (currentState.event !== event.event) {
    return false
  }

  const eventData = {
    ...event,
    data: {
      ...(event && event.data)
    }
  }

  return !isEqual(
    omit(eventData, blacklist),
    omit(currentEventState, blacklist)
  )
}

const createHistoryIndex = (currentState, history, index) => {
  const newHistoryState = cloneDeep(currentState)

  if (index + 1 !== history.length) {
    history.splice(index + 1)
    history.push(newHistoryState)
    return { history, index: history.length - 1 }
  }
  history.push(newHistoryState)

  return { history, index: (index += 1) }
}

const createStateWithFormfactory = (event, currentState) => {
  const filterFields = (fields, guestType) =>
    fields.filter(({ guest_type }) => guest_type === guestType)

  const formFactorys = currentState.formFactorys.map((factory, index) => ({
    ...factory,
    fields: filterFields(
      cloneDeep(event.fields),
      index === 0 ? 'leader' : 'follower'
    )
  }))
  const data = {
    ...event.data
  }

  return {
    ...currentState,
    ...event,
    data,
    formFactorys
  }
}

const removeFieldFromFactory = (formFactorys, factoryId, fieldIndex) => {
  const fields = cloneDeep(formFactorys[factoryId].fields)
  fields.splice(fieldIndex, 1)
  formFactorys[factoryId].fields = fields

  return formFactorys
}

export const reducer = (prevState, action) => {
  const {
    history,
    index,
    currentState,
    currentState: { formFactorys }
  } = prevState
  const {
    type,
    payload: {
      event,
      fieldIndex,
      fieldData,
      factoryId,
      fieldOptionsList,
      page,
      fieldName,
      value,
      checked,
      relativeIndex,
      maxNrOfFollowers,
      attribute
    } = {}
  } = action

  switch (type) {
    case 'FieldCreate':
      const newField = {
        ...fieldData,
        order_index:
          formFactorys[factoryId].fields.length > 0
            ? formFactorys[factoryId].fields
                .filter(field => field.guest_type === fieldData.guest_type)
                .reduce(
                  (maxValue, currentValue) =>
                    maxValue.order_index > currentValue.order_index
                      ? maxValue
                      : currentValue,
                  {}
                ).order_index + 1
            : 0
      }
      formFactorys[factoryId].fields.push(newField)
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex(currentState, history, index)
      }

    case 'FieldDelete':
      const removedFieldFactory = removeFieldFromFactory(
        formFactorys,
        factoryId,
        fieldIndex
      )
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys: removedFieldFactory },
        ...createHistoryIndex(
          { ...currentState, formFactorys: removedFieldFactory },
          history,
          index
        )
      }

    case 'FieldChange':
      formFactorys[factoryId].fields[fieldIndex].label = value
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys }
      }

    case 'FieldRequiredChange':
      formFactorys[factoryId].fields[fieldIndex].required = checked
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex({ ...currentState, formFactorys }, history, index)
      }

    case 'FieldTextAreaChange':
      formFactorys[factoryId].fields[fieldIndex].data.options = fieldOptionsList
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys }
      }

    case 'DescriptionFieldChange':
      const currentPage = currentState.data[page]
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: {
            ...currentState.data,
            [page]: {
              ...currentPage,
              [fieldName]: value
            }
          }
        }
      }

    case 'MultiplePageChange':
      const pages = ['closed', 'confirm', 'full', 'signup']
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: pages.reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                ...currentState.data[key],
                [fieldName]: value
              }
            }),
            currentState.data
          )
        }
      }

    case 'DragEndChange':
      const { source, destination } = action.payload
      const sourceFactoryId = source.droppableId.split('-')[1]
      const destinationFactoryId = destination.droppableId.split('-')[1]
      const field = formFactorys[sourceFactoryId].fields.splice(source.index, 1)
      formFactorys[destinationFactoryId].fields.splice(
        destination.index,
        0,
        field[0]
      )
      formFactorys[destinationFactoryId].fields = formFactorys[
        destinationFactoryId
      ].fields.map((field, index) => (field.order_index = index + 1) && field)
      return {
        ...prevState,
        currentState: { ...currentState, formFactorys },
        ...createHistoryIndex({ ...currentState, formFactorys }, history, index)
      }

    case 'GuestCountChange':
      return {
        ...prevState,
        currentState: {
          ...currentState,
          max_number_of_followers: maxNrOfFollowers
        }
      }

    case 'CreateInitialHistory':
      const eventData = createStateWithFormfactory(event, currentState)
      return {
        ...prevState,
        currentState: eventData,
        history: [cloneDeep(eventData)],
        index: 0
      }

    case 'SetCurrentState':
      return {
        ...prevState,
        currentState: createStateWithFormfactory(event, currentState)
      }

    case 'UpdateHistory':
      return {
        ...prevState,
        ...createHistoryIndex(currentState, history, index)
      }

    case 'MoveHistoryPointer':
      const newIndex = index + relativeIndex
      const newCurrentState = cloneDeep(history[newIndex])
      return {
        ...prevState,
        currentState: newCurrentState,
        index: newIndex
      }

    case 'SetDefaultNrOfFollowers':
      return {
        ...prevState,
        currentState: {
          ...currentState,
          max_number_of_followers: 1
        }
      }

    case 'UpdateEventStateAttribute':
      return {
        ...prevState,
        currentState: { ...currentState, [attribute]: value }
      }

    case 'UpdateEventDataAttribute':
      return {
        ...prevState,
        currentState: {
          ...currentState,
          data: {
            ...currentState.data,
            [attribute]: value
          }
        }
      }

    default:
      return prevState
  }
}
