import './CreateEventModal.scss'
import React from 'react'
import moment from 'moment-timezone'

import { Redirect } from 'react-router-dom'
import { getCancelSource } from 'misc/api/util'

import {
  BasicEventInfo,
  ChooseTemplate
} from 'components/Modals/CreateEventModal/View'

import API from 'misc/api'
import {
  addHours,
  ceilToMinutes,
  isEarlierDate,
  subtractHours
} from 'misc/calendar'

class CreateEventModal extends React.Component {
  state = {
    eventId: null,
    currentPage: 0,
    name: '',
    startTime: ceilToMinutes(moment(), 30).format(),
    endTime: ceilToMinutes(moment(), 30)
      .add(1, 'hours')
      .format(),
    templates: [],
    chosenTemplate: null,
    enableExtendedLandingPages: false
  }

  apiRequest = null
  cancelSource = getCancelSource()

  handleCreateEvent = () => {
    const {
      name,
      chosenTemplate,
      startTime: start_time,
      endTime: end_time,
      templates,
      enableExtendedLandingPages
    } = this.state

    this.setState({ submitting: true })

    const template = chosenTemplate ? chosenTemplate : templates[0].id

    this.apiRequest = API.postEventDashboardInfo(
      {
        template,
        name,
        start_time,
        end_time,
        timeslots_enabled: templates.find(t => t.id === template)
          .supports_timeslots,
        extended_landing_pages_enabled: enableExtendedLandingPages,
        data: {}
      },
      this.cancelSource.token
    )
      .then(response => {
        if (response.status === 201) {
          this.setState({ eventId: response.data.event })
        }
      })
      .catch(error => console.error(error))
      .finally(() => {})
  }

  nextModalPage = () => {
    this.setState({ currentPage: 1 })
  }

  prevModalPage = () => {
    this.setState({ currentPage: 0 })
  }

  handleNameChange = changeEvent => {
    const { value: name } = changeEvent.target
    this.setState({ name })
  }

  handleStartTimeChange = timestamp => {
    this.setState(state => ({
      startTime: moment(timestamp).format(),
      ...(isEarlierDate(state.endTime, timestamp) && {
        endTime: addHours(timestamp, 1)
      })
    }))
  }

  handleEndTimeChange = timestamp => {
    this.setState(state => ({
      endTime: moment(timestamp).format(),
      ...(!isEarlierDate(state.startTime, timestamp) && {
        startTime: subtractHours(timestamp, 1)
      })
    }))
  }

  handleTemplateChoice = chosenTemplate => {
    this.setState({ chosenTemplate })
  }

  handeEnableExtendedLandingPages = () => {
    this.setState({
      enableExtendedLandingPages: !this.state.enableExtendedLandingPages
    })
  }

  componentDidMount() {
    this.apiRequest = API.getAllTemplates(this.cancelSource.token)
      .then(response => {
        const templates = response.data
        this.setState({ templates })
        this.apiRequest = null
      })
      .catch(error => console.error(error))
  }

  componentWillUnmount() {
    if (this.apiRequest) {
      this.cancelSource.cancel()
    }
  }

  render() {
    const { handleClose } = this.props
    const {
      chosenTemplate,
      currentPage,
      endTime,
      eventId,
      name,
      startTime,
      submitting,
      templates,
      enableExtendedLandingPages
    } = this.state

    if (Number.isInteger(eventId)) {
      return <Redirect to={`/event/${eventId}/edit`} />
    }

    if (currentPage === 0) {
      return (
        <BasicEventInfo
          name={name}
          startTime={startTime}
          endTime={endTime}
          templates={templates}
          handleClose={handleClose}
          handleNext={this.nextModalPage}
          handleNameChange={this.handleNameChange}
          handleStartTimeChange={this.handleStartTimeChange}
          handleEndTimeChange={this.handleEndTimeChange}
          handleCreateEvent={this.handleCreateEvent}
          submitting={submitting}
        />
      )
    }

    return (
      <ChooseTemplate
        templates={templates}
        chosenTemplate={chosenTemplate}
        enableExtendedLandingPages={enableExtendedLandingPages}
        handleTemplateChoice={this.handleTemplateChoice}
        handeEnableExtendedLandingPages={this.handeEnableExtendedLandingPages}
        handleCreateEvent={this.handleCreateEvent}
        handleClose={handleClose}
        handlePrev={this.prevModalPage}
        submitting={submitting}
      />
    )
  }
}

export default CreateEventModal
