import './SelectableCard.scss'
import React from 'react'

const CheckmarkIcon = () => (
  <div className="CheckmarkIcon">
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g>
        <path
          d="M3.24882923,9.87529377 L17.7116356,9.87529377 C18.4848343,9.87529377 19.1116356,10.5020951 19.1116356,11.2752938 C19.1116356,12.0484925 18.4848343,12.6752938 17.7116356,12.6752938 L1.84496867,12.6752938 C1.07177,12.6752938 0.444968643,12.0484925 0.444968643,11.2752938 C0.444968643,11.2367214 0.446528556,11.1985134 0.449588738,11.1607293 L0.4457959,4.7419601 C0.445339288,3.96921832 1.07140012,3.34241718 1.8441419,3.34196057 C1.84441749,3.34196041 1.84469308,3.34196032 1.84496867,3.34196032 C2.61830111,3.34196032 3.24533899,3.96862779 3.24579595,4.7419601 L3.24882923,9.87529377 Z"
          transform="translate(9.778302, 8.008627) rotate(-47.000000) translate(-9.778302, -8.008627) "
        />
      </g>
    </svg>
  </div>
)

const PlayIcon = () => (
  <div className="CardIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="32"
      viewBox="0 0 42 32">
      <path
        fill="#FFF"
        d="M4.884 0C2.195 0 0 2.247 0 5v22c0 2.753 2.195 5 4.884 5h32.232C39.805 32 42 29.753 42 27V5c0-2.753-2.195-5-4.884-5H4.884zm.04 2h32.153C38.713 2 40 3.32 40 5v22c0 1.68-1.287 3-2.923 3H4.923C3.287 30 2 28.68 2 27V5c0-1.68 1.287-3 2.923-3zm11.99 6.001c-.496.026-.928.53-.914 1.068v13.866c-.01.373.183.744.481.93.298.186.69.18.984-.017l11.083-6.933c.603-.356.603-1.47 0-1.826L17.465 8.156A.91.91 0 0016.913 8zM18 11l8 5.008L18 21V11z"></path>
    </svg>
  </div>
)

const SelectableCard = ({ alt, icon, isSelected, onClick, src }) => (
  <div className="SelectableCard">
    <button
      type="button"
      className="SelectableCardButton"
      onClick={onClick}
      disabled={isSelected}>
      <img alt={alt} src={src} />
      {icon && icon === 'play' && <PlayIcon />}
      {isSelected && <CheckmarkIcon />}
    </button>
  </div>
)

export default SelectableCard
