import './ActiveEventHeader.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import clsx from 'clsx'

import API from 'misc/api'

import CopyEmails from 'components/ActiveEventHeader/CopyEmails'
import Wrapper from 'components/Wrapper'
import Button from 'components/Button'
import StateToggler from 'components/StateToggler'
import InfoBar from 'components/InfoBar'
import Search from 'components/Search'

import ExportIcon from './icons/Export'
import CheckInIcon from './icons/CheckIn'
import LeadCaptureIcon from './icons/LeadCapture'

import { useTranslation, Trans } from 'react-i18next'
import { OrganizationContext } from '../Contexts/OrganizationProvider'
import { LEAD_CAPTURE_FORM_URL } from '../../misc/api/util'

const formatTime = timestamp =>
  moment.parseZone(timestamp).format('YYYY-MM-DD, HH:mm')

const BigNumber = ({ number, label }) => (
  <div className={clsx('BigNumber', { isMuted: number === 0 })}>
    <div className="number">{number}</div>
    <div className="label">{label}</div>
  </div>
)

const ActiveEventHeader = ({
  checkinUrl,
  event,
  deactivateEvent,
  publishEvent,
  token,
  lead_capture_token,
  handleSearchInput,
  search
}) => {
  const guestLimit = event && event.guest_max_limit ? event.guest_max_limit : 0
  const { t } = useTranslation()

  const name = event && event.name
  const startTime = event && event.start_time
  const endTime = event && event.end_time
  const nrOfGuests = event && event.nr_of_guests ? event.nr_of_guests : 0
  const anonymizedAt = event && event.anonymized_at
  const [selectedState, selectState] = useState(event.state)
  const { settings } = React.useContext(OrganizationContext)
  const renderLeadCaptureButton =
    settings &&
    settings.integrations &&
    settings.integrations.lcf_integration &&
    settings.integrations.lcf_integration.lead_capture_form_link_enabled ===
      true

  useEffect(() => {
    // override local state if a new event state is received
    selectState(event.state)
  }, [event.state, selectState])

  const handleStateSelect = state => {
    // sync with local state for immediate feedback
    selectState(state)

    switch (state) {
      case 'ACTIVE':
        publishEvent()
        break
      case 'CLOSED':
        deactivateEvent()
        break
      case 'DRAFT':
        /* not possible */ break
      default:
    }
  }

  const openCheckinApp = () => {
    checkinUrl && window.open(checkinUrl, '_blank')
  }

  const openLeadCapture = () => {
    let lead_capture_form_domain = LEAD_CAPTURE_FORM_URL

    if (settings?.organization?.lead_capture_form_domain) {
      lead_capture_form_domain = settings.organization.lead_capture_form_domain
    }

    token &&
      window.open(`${lead_capture_form_domain}${lead_capture_token}`, '_blank')
  }

  const downloadSpreadsheet = () => {
    API.downloadSpreadsheet(event.event, `${name.split(' ').join('_')}_guests`)
  }

  return (
    <header className="ActiveEventHeader">
      <div className="ActiveEventHeaderPrimary">
        <Wrapper flex>
          <div className="ActiveEventHeaderColumn">
            <h1 className="title">{name}</h1>
            <div className="timeArea">
              <div className="timeItem">
                <span className="timeLabel">{t('start')}:</span>
                {formatTime(startTime)}
              </div>
              <div className="timeItem">
                <span className="timeLabel">{t('end')}:</span>
                {formatTime(endTime)}
              </div>
            </div>
          </div>
          <div className="ActiveEventHeaderColumn bigNumbers">
            <BigNumber number={guestLimit} label={t('guest limit')} />
            <BigNumber number={nrOfGuests} label={t('guests')} />
            <BigNumber number={event.checkins} label={t('checked in')} />
          </div>
          <div className="ActiveEventHeaderColumn">
            <StateToggler
              value={selectedState}
              onSelect={handleStateSelect}
              disabled={selectedState !== event.state || anonymizedAt}
            />
          </div>
        </Wrapper>
      </div>
      {event.state === 'ARCHIVED' ? (
        <InfoBar className="ActiveEventHeaderInfo">
          <Trans
            i18nKey="eventArchivedInfo"
            values={{
              closed: t('states.CLOSED.headingActive').toUpperCase(),
              open: t('states.ACTIVE.headingInactive').toUpperCase()
            }}>
            Event is closed and archived, re-open the event by changing the
            event status from <span className="EventMark">closed</span> to{' '}
            <span className="EventMark">open</span>
          </Trans>
        </InfoBar>
      ) : null}
      <div className="actions">
        <Wrapper flex>
          <Search handleInputChange={handleSearchInput} value={search} />

          <div className="ActiveEventHeaderActions">
            {renderLeadCaptureButton && (
              <Button type="toolbar" onClick={openLeadCapture}>
                <LeadCaptureIcon />
                {t('lead capture')}
              </Button>
            )}
            <Button
              type="toolbar"
              onClick={downloadSpreadsheet}
              disabled={!nrOfGuests}>
              <ExportIcon />
              {t('export')}
            </Button>
            <CopyEmails eventId={event && event.event} />
            <Button
              type="toolbar"
              onClick={openCheckinApp}
              disabled={!checkinUrl}>
              <CheckInIcon />
              {t('check-in')}
            </Button>
          </div>
        </Wrapper>
      </div>
    </header>
  )
}

export default ActiveEventHeader
