import { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'

const diffDownTo = (v, x) => [v, v - x].find(n => n < x)
const diffTo = to => moment.duration(moment(to).diff(moment()))

const useCountdown = (to, onEnd) => {
  const [timeLeft, setTimeLeft] = useState(diffTo(to))

  const intervalRef = useRef()
  const secondsLeft = moment.relativeTimeRounding()(timeLeft.asSeconds())

  const interval =
    timeLeft.minutes() === 0
      ? 1000
      : (diffDownTo(Math.abs(timeLeft.seconds()), 30) + 1) * 1000 // normalize interval to occur at even 30 seconds, as humanize() has a minute rounding threshold at half minutes

  useEffect(() => {
    if (intervalRef.current) window.clearInterval(intervalRef.current)

    if (secondsLeft > 0) {
      intervalRef.current = window.setInterval(
        () => setTimeLeft(diffTo(to)),
        interval
      )
    } else if (typeof onEnd === 'function') {
      onEnd()
    }

    return () => window.clearInterval(intervalRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEnd, secondsLeft, interval])

  return [timeLeft, secondsLeft]
}

export default useCountdown
