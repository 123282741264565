import './Countdown.scss'
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useCountdown from 'misc/useCountdown'

const Countdown = ({ className, onEnd, label, to, ...props }) => {
  const [timeLeft, secondsLeft] = useCountdown(to, onEnd)

  return secondsLeft > 0 ? (
    <div className={clsx('Countdown', className)}>
      {label ? <span className="CountdownLabel">{label}</span> : null}
      <span className="CountdownNumber">
        {timeLeft.humanize({
          s: 60,
          ss: 0
        })}
      </span>
    </div>
  ) : null
}

export default Countdown

Countdown.propTypes = {
  onEnd: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // TODO: find shape of moment object?
    PropTypes.instanceOf(Date)
  ]).isRequired
}
