import React from 'react'
import PropTypes from 'prop-types'
import './Search.scss'

import { useTranslation } from 'react-i18next'

export const SearchIcon = props => (
  <svg
    {...props}
    fill="currentColor"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <path d="M13.1366,14.3960108 C10.1930893,16.7167329 5.91256119,16.5191629 3.19669914,13.8033009 C0.267766953,10.8743687 0.267766953,6.12563133 3.19669914,3.19669914 C6.12563133,0.267766953 10.8743687,0.267766953 13.8033009,3.19669914 C16.4668,5.86019825 16.7081783,10.0286103 14.5274359,12.9648734 C14.5477933,12.9822159 14.56762,13.000506 14.5868578,13.0197439 L19.5366053,17.9694913 C19.9271296,18.3600156 19.9271296,18.9931806 19.5366053,19.3837049 C19.146081,19.7742292 18.512916,19.7742292 18.1223917,19.3837049 L13.1726443,14.4339574 C13.1602352,14.4215484 13.1482204,14.4088943 13.1366,14.3960108 Z M12.3890873,12.3890873 C14.5369709,10.2412037 14.5369709,6.75879631 12.3890873,4.6109127 C10.2412037,2.4630291 6.75879631,2.4630291 4.6109127,4.6109127 C2.4630291,6.75879631 2.4630291,10.2412037 4.6109127,12.3890873 C6.75879631,14.5369709 10.2412037,14.5369709 12.3890873,12.3890873 Z" />
  </svg>
)

const Search = ({ hidden, value, handleInputChange }) => {
  const { t } = useTranslation()

  return (
    <div className="Search">
      <input
        placeholder={t('searchPlaceholder')}
        id="search"
        type="search"
        value={value}
        onChange={handleInputChange}
        data-hidden={hidden}
      />
      <label htmlFor="search">
        <SearchIcon className="SearchIcon" />
      </label>
    </div>
  )
}

export default Search

Search.propTypes = {
  hidden: PropTypes.bool,
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired
}
