import React from 'react'
import './Header.scss'

import Wrapper from 'components/Wrapper'
import Logo from 'components/Logo'

export default class Header extends React.Component {
  render() {
    const { dark, left, right } = this.props
    return (
      <div className="Header" data-dark={dark}>
        <Wrapper flex>
          <Logo className="HeaderLogo" />
          <div className="NavContainer">
            {left}
            {right}
          </div>
        </Wrapper>
      </div>
    )
  }
}
