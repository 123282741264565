import React, { useEffect, useReducer } from 'react'
import { omit } from 'lodash'
import moment from 'moment-timezone'

import {
  reducer,
  formFactorys,
  getFormEvent,
  hasFormBeenUpdated
} from 'components/Contexts/UndoProvider/helpers'

export const UndoContext = React.createContext()

const onlyNumbersRegex = /^(\s*|\d+)$/

const UndoProvider = ({
  event,
  updateEvent,
  isEventLoading,
  extendedLandingPages,
  children
}) => {
  const [state, dispatch] = useReducer(reducer, {
    history: [],
    index: 0,
    currentState: {
      max_number_of_followers: 0,
      data: {},
      formFactorys
    }
  })

  // Factory events
  const handleCreateField = (fieldData, factoryId) => {
    dispatch({ type: 'FieldCreate', payload: { fieldData, factoryId } })
  }

  const handleFactoryFieldDelete = payload =>
    dispatch({ type: 'FieldDelete', payload })

  // User input events
  const handleFactoryFieldChange = payload =>
    dispatch({ type: 'FieldChange', payload })

  const handleFactoryFieldRequiredChange = payload =>
    dispatch({ type: 'FieldRequiredChange', payload })

  const handleFactoryFieldTextareaChange = payload =>
    dispatch({ type: 'FieldTextAreaChange', payload })

  const handleChange = page => ({ target }) => {
    const { value, id: fieldName } = target
    dispatch({
      type: 'DescriptionFieldChange',
      payload: { page, value, fieldName }
    })
  }

  const handleBackgroundChange = ({ page, value, fieldName }) => {
    dispatch({
      type: 'MultiplePageChange',
      payload: { page, value, fieldName }
    })
  }

  const handleDragEnd = ({ destination, source }) => {
    document.activeElement.blur()

    if (destination) {
      if (destination !== source) {
        dispatch({ type: 'DragEndChange', payload: { destination, source } })
      }
    }
  }

  const handleInputBlur = () =>
    compareHistoryStates() ? false : updateHistory()

  const handleGuestCountChange = nrOfFollowers => {
    if (onlyNumbersRegex.test(nrOfFollowers)) {
      const maxNrOfFollowers = Number(nrOfFollowers)
      dispatch({ type: 'GuestCountChange', payload: { maxNrOfFollowers } })
    }
  }

  // History write methods

  const createInitialHistory = event => {
    dispatch({ type: 'CreateInitialHistory', payload: { event } })
  }

  const updateHistory = () => {
    dispatch({ type: 'UpdateHistory' })
  }

  // Undo / Redo
  const moveHistoryPointer = relativeIndex => {
    dispatch({ type: 'MoveHistoryPointer', payload: { relativeIndex } })
  }

  const undo = () => moveHistoryPointer(-1)

  const redo = () => moveHistoryPointer(1)

  // Util functions
  const setDefaultNumberOfFollowers = () => {
    dispatch({ type: 'SetDefaultNrOfFollowers' })
  }

  const compareHistoryStates = () => {
    const { currentState, history, index } = state
    return JSON.stringify(currentState) === JSON.stringify(history[index])
  }

  // Settings
  const updateEventStateAttribute = (attribute, value) => {
    dispatch({
      type: 'UpdateEventStateAttribute',
      payload: { attribute, value }
    })
  }

  const updateEventName = name => {
    updateEventStateAttribute('name', name)
  }

  const updateCampaignId = campaignId => {
    updateEventDataAttribute('campaign_id', campaignId)
  }

  const updateEventLocation = location => {
    // TODO: currently we need to store both inside and outside of "data", outside because of dirty checks, inside because it's what the backend expects
    updateEventStateAttribute('location', location)
    updateEventDataAttribute('location', location)
  }

  const updateGuestMaxLimit = guestMaxLimit => {
    updateEventStateAttribute('guest_max_limit', guestMaxLimit)
  }

  const updateStartTime = timestamp => {
    updateEventStateAttribute('start_time', timestamp)
  }

  const updateEndTime = timestamp => {
    updateEventStateAttribute('end_time', timestamp)
  }

  const addTimeSlot = () => {
    const { guestlists = [] } = state.currentState

    const prevTimeSlot =
      guestlists.length > 0
        ? guestlists[guestlists.length - 1]
        : null /* we reuse data from the previous timeslot when possible */

    updateEventStateAttribute('guestlists', [
      ...guestlists,
      {
        end_time: prevTimeSlot
          ? prevTimeSlot.end_time
          : state.currentState.end_time,
        event: state.currentState.event,
        guest_max_limit: prevTimeSlot ? prevTimeSlot.guest_max_limit : null,
        organization: state.currentState.organization,
        start_time: prevTimeSlot
          ? prevTimeSlot.start_time
          : state.currentState.start_time,
        name: prevTimeSlot ? prevTimeSlot.name : '',
        key: prevTimeSlot ? prevTimeSlot.key + 1 : 0
      }
    ])
  }

  const removeTimeSlot = key => {
    updateEventStateAttribute(
      'guestlists',
      state.currentState.guestlists.filter(guestlist => guestlist.key !== key)
    )
  }

  const updateTimeSlot = data => {
    updateEventStateAttribute('guestlists', data)
  }

  const updateEventDataAttribute = (attribute, value) => {
    dispatch({
      type: 'UpdateEventDataAttribute',
      payload: { attribute, value }
    })
  }

  const updateEmailSettings = value => {
    updateEventDataAttribute('confirmation_email', value)
    if (!value.enabled) {
      updateCalendarSettings({
        ...state.currentState.data.calendar_reminder,
        enabled: false
      })
    }
  }

  const updateCalendarSettings = value => {
    updateEventDataAttribute('calendar_reminder', value)
  }

  const updateTimeZoneLocation = value => {
    updateEventDataAttribute('timezone_location', value)
    updateEventStateAttribute(
      'guestlists',
      state.currentState.guestlists.map(guestlist => ({
        ...guestlist,
        start_time: moment.tz(guestlist.start_time, value).format(),
        end_time: moment.tz(guestlist.end_time, value).format()
      }))
    )
  }

  const updateLegalText = value => {
    updateEventDataAttribute('legal_text', value)
  }

  const setCurrentState = event => {
    dispatch({ type: 'SetCurrentState', payload: { event } })
  }

  const eventId = event ? event.event : null
  const lastModified = event ? event.last_modified : null

  useEffect(() => {
    event && createInitialHistory(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  // Update our currentState object when the server responds with a new one.
  useEffect(() => {
    // Don't set any data during intitialization, nor when event has failed to load.
    // Note: No need to compare with new modified date as it's in the dependency list.
    // TODO: Maybe find a better indicator?
    if (!state.currentState.last_modified || !lastModified || !event) {
      return
    }

    setCurrentState(event)
  }, [lastModified]) // eslint-disable-line react-hooks/exhaustive-deps

  // make sure every non-optional guest has at least one required field
  const canSave = () =>
    state.currentState.formFactorys.every(
      factory =>
        (state.currentState.max_number_of_followers === 0 &&
          factory.optional) ||
        factory.fields.some(field => field.required)
    )

  const saveKeyBlacklist = ['location']

  const handleResetChanges = () => {
    if (extendedLandingPages?.hasPageChanges) {
      extendedLandingPages.resetPageChanges()
    } else {
      createInitialHistory(event)
    }
  }

  const handleSaveEvent = () => {
    if (extendedLandingPages?.hasPageChanges) {
      return extendedLandingPages.savePage()
    } else {
      saveKeyBlacklist.forEach(key => updateEventStateAttribute(key, null))
      return updateEvent(
        getFormEvent(omit(state.currentState, saveKeyBlacklist))
      )
    }
  }

  const hasUnsavedChanges =
    extendedLandingPages?.hasPageChanges ||
    hasFormBeenUpdated(event, state.currentState)

  const isLoading = isEventLoading || extendedLandingPages?.isLoading

  return (
    <UndoContext.Provider
      value={{
        ...state,
        hasUnsavedChanges,
        isLoading,
        setDefaultNumberOfFollowers,
        canSave,
        compareHistoryStates,
        updateHistory,
        undo,
        redo,
        handleInputBlur,
        handleChange,
        handleBackgroundChange,
        handleFactoryFieldChange,
        handleFactoryFieldRequiredChange,
        handleFactoryFieldTextareaChange,
        handleCreateField,
        handleFactoryFieldDelete,
        handleGuestCountChange,
        handleDragEnd,
        handleResetChanges,
        handleSaveEvent,
        updateCampaignId,
        updateEventName,
        updateEventLocation,
        updateGuestMaxLimit,
        updateStartTime,
        updateEndTime,
        updateEmailSettings,
        updateCalendarSettings,
        updateTimeZoneLocation,
        addTimeSlot,
        removeTimeSlot,
        updateTimeSlot,
        updateLegalText
      }}>
      {children}
    </UndoContext.Provider>
  )
}

export default UndoProvider
