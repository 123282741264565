import './InfoBar.scss'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const InfoBar = ({ children, className, ...props }) => {
  return <div className={clsx('InfoBar', className)}>{children}</div>
}

export default InfoBar

InfoBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}
