import './DeleteSmsModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

export const DeleteSmsModal = ({ handleDeleteSms, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Modal modalName="DeleteSmsModal">
      <Header>{t('sms.delete.title')}</Header>
      <Content>
        <p>{t('sms.delete.description')}</p>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
        rightActions={
          <Button onClick={handleDeleteSms} type="warning">
            {t('delete')}
          </Button>
        }
      />
    </Modal>
  )
}

export default DeleteSmsModal
