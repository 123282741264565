import './Days.scss'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { DATE_TODAY, getWeeksInMonth } from 'misc/calendar'

export const Days = ({ date, active, onClick }) => {
  const handleInteraction = dateTime => event => {
    // handles both enter and mouse click, split into two functions if further logic is needed
    if (event.keyCode === 13 || event.type === 'click') {
      onClick(
        moment
          .parseZone(dateTime)
          .add(active.hour(), 'hour')
          .add(active.minutes(), 'minutes')
          .format()
      )
    }
  }

  const weeks = getWeeksInMonth(date)

  return (
    <div className="Days">
      {weeks.map(currentWeek =>
        currentWeek.map((day, index) => {
          const handleSelection = handleInteraction(day)

          return (
            <button
              className="day"
              key={`day_${index}`}
              data-active={
                day.isSame(active, 'year') &&
                day.isSame(active, 'month') &&
                day.isSame(active, 'day')
              }
              data-different-month={!day.isSame(date, 'month')}
              disabled={day.isBefore(DATE_TODAY, 'day')}
              onClick={handleSelection}
              onKeyDown={handleSelection}>
              {day.format('DD')}
            </button>
          )
        })
      )}
    </div>
  )
}

export default Days

Days.propTypes = {
  date: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.object
}
