import React from 'react'
import Thumbnail from 'components/Thumbnail'

const Template = ({ id, name, chosen, handleChoice }) => (
  <div
    className="Template"
    data-chosen={chosen}
    onClick={() => handleChoice(id)}>
    <Thumbnail size={98} src="" />

    <div className="meta">
      <h3>{name}</h3>
      {/* <div className="tags">
        <span className="tag">Fun</span>
        <span className="tag">Safe</span>
        <span className="tag">Cool</span>
      </div> */}
    </div>
  </div>
)

const Templates = ({ templates, chosenTemplate, handleChoice }) => (
  <div className="TemplatesContainer">
    <div className="TemplatesContainerInner">
      {templates.map(({ id, name }) => (
        <Template
          key={id}
          id={id}
          name={name}
          chosen={chosenTemplate === id}
          handleChoice={handleChoice}
        />
      ))}
    </div>
  </div>
)

export default Templates
