import './DeleteEventModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

export const DeleteEventModal = ({ handleDeleteEvent, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Modal modalName="DeleteEventModal">
      <Header>{t('delete event')}</Header>
      <Content>
        <p>{t('deleteEventText')}</p>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
        rightActions={
          <Button onClick={handleDeleteEvent} type="warning">
            {t('delete')}
          </Button>
        }
      />
    </Modal>
  )
}

export default DeleteEventModal
