import './Textarea.scss'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const getBorderHeight = computedStyle =>
  parseInt(computedStyle.getPropertyValue('border-top-width'), 10) +
    parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10) || 0

const Textarea = ({ value = '', handleChange, handleBlur, ...props }) => {
  const inputElem = useRef(null)
  const inputElemStyle = useRef(null)
  const height = useRef(null)

  useLayoutEffect(() => {
    inputElemStyle.current = window.getComputedStyle(inputElem.current)
    resize()
  }, [])

  useEffect(() => {
    if (inputElem.current.scrollHeight !== inputElem.current.clientHeight) {
      resize()
    }
  })

  const resize = () => {
    height.current =
      inputElem.current.scrollHeight +
      getBorderHeight(inputElemStyle.current) +
      'px'
    inputElem.current.style.setProperty('height', height.current)
  }

  const handleInput = event => {
    // text was removed
    if (event.target.value.length < value.length) {
      inputElem.current.style.removeProperty('height')
    }

    if (inputElem.current.scrollHeight !== inputElem.current.clientHeight) {
      resize()
    }
  }

  return (
    <textarea
      {...props}
      className="Textarea"
      value={value}
      onChange={handleChange}
      onInput={handleInput}
      onBlur={handleBlur}
      ref={inputElem}
      style={{ height, resize: props.resize || null }}
    />
  )
}

export default Textarea

Textarea.propTypes = {
  id: PropTypes.string,
  resize: PropTypes.oneOf(['vertical', 'horizontal', 'both', 'none']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func
}
