import './DateTimePicker.scss'
import React, { useState } from 'react'
import moment from 'moment-timezone'

import DatePicker from './DatePicker'
import TimePicker from './TimePicker'

const DateTimePicker = ({
  value,
  readOnly,
  timezone,
  dayErrored,
  timeErrored,
  handleChange
}) => {
  const [dateOpen, setDateOpen] = useState(false)
  const [timeOpen, setTimeOpen] = useState(false)

  const currentValue = value || (timezone ? moment().tz(timezone) : moment())
  const activeDate = moment.parseZone(currentValue)

  return (
    <div className="DateTimePicker">
      <DatePicker
        activeDate={activeDate}
        calendarIsOpen={dateOpen}
        handleDatePickerClick={() => setDateOpen(true)}
        handleCloseDatePicker={() => setDateOpen(false)}
        handleDateClick={handleChange}
        readOnly={readOnly}
        errored={dayErrored}
      />
      <TimePicker
        activeDate={activeDate}
        timesIsOpen={timeOpen}
        handleTimePickerClick={() => setTimeOpen(true)}
        handleCloseTimePicker={() => setTimeOpen(false)}
        handleClickTime={handleChange}
        readOnly={readOnly}
        errored={timeErrored}
      />
    </div>
  )
}

export default DateTimePicker
