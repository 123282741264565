import './OverviewEvents.scss'
import React, { Component } from 'react'

import API from 'misc/api'
import { makeCancelable } from 'misc/api/util'

import { OrganizationContext } from 'components/Contexts/OrganizationProvider'

import Header from 'components/Header'
import Nav from 'components/Nav'
import UserSelect from 'components/UserSelect'
import Wrapper from 'components/Wrapper'
import OverviewGrid from 'components/OverviewGrid'
import Button from 'components/Button'
import SortableTable from 'components/SortableTable'
import InfoBar from 'components/InfoBar'
import Notification from 'components/Notifications'

import { withTranslation, Trans } from 'react-i18next'

const sortByLastModified = (a, b) =>
  new Date(a.last_modified).getTime() > new Date(b.last_modified).getTime()
    ? -1
    : 1

const eventTypeInfoKeys = {
  archived: 'eventArchivedListInfo'
}

class OverviewEvents extends Component {
  state = {
    events: []
  }

  cancelFetchEvents = null

  removeEvent = id =>
    this.setState({
      events: this.state.events.filter(event => event.event !== id)
    })

  fetchEvents = () => {
    const { cancel, promise } = makeCancelable(API.getDashboardInfo())

    promise
      .then(response => {
        this.setState({ events: response.data })
      })
      .catch(() => {
        this.cancelFetchEvents = null
      })

    this.cancelFetchEvents = cancel
  }

  componentDidMount() {
    this.fetchEvents()
  }

  componentWillUnmount() {
    if (typeof this.cancelFetchEvents === 'function') {
      this.cancelFetchEvents()
    }
  }

  render() {
    let { events } = this.state
    const { t } = this.props
    const { type } = this.props.match.params

    const activeStates = ['ACTIVE', 'CLOSED', 'FULL']

    const categorizedEvents = {
      drafts: events.filter(({ state }) => state === 'DRAFT'),
      active: events.filter(
        ({ anonymized_at, state }) =>
          activeStates.indexOf(state) !== -1 && !anonymized_at
      ),
      archived: events.filter(({ anonymized_at }) => anonymized_at != null)
    }

    const leftNav = (
      <Nav
        items={['start', ...Object.keys(categorizedEvents)].map(key => ({
          to: `/events/${key}`,
          label: t(key)
        }))}
      />
    )

    return (
      <div className="OverviewEvents">
        <Header left={leftNav} right={<UserSelect />} />
        <Wrapper className="OverviewWrapper">
          <Notification />
          {type === 'start' ? (
            Object.entries(categorizedEvents)
              .filter(
                ([eventType, items]) =>
                  eventType === 'drafts' || eventType === 'active'
              )
              .map(([eventType, items], i) => (
                <OverviewGrid
                  key={i}
                  title={eventType}
                  createEventButton={eventType === 'drafts'}
                  summary={eventType !== 'drafts'}
                  right={
                    items.length > (eventType === 'drafts' ? 3 : 4) ? (
                      <Button to={`/events/${eventType}`} type="secondary">
                        {t('show all')}
                      </Button>
                    ) : null
                  }
                  items={items.sort(sortByLastModified)}
                  removeEvent={this.removeEvent}
                  fetchEvents={this.fetchEvents}
                  className="OverviewEventsGrid"
                />
              ))
          ) : (
            <>
              <h1 className="OverviewEventsHeading h2">{t(type)}</h1>
              <span className="OverviewEventsCount">
                {categorizedEvents[type].length} {t('events')}
              </span>
              {eventTypeInfoKeys[type] ? (
                <OrganizationContext.Consumer>
                  {({ settings }) =>
                    settings &&
                    settings.anonymization &&
                    settings.anonymization.grace_period ? (
                      <InfoBar className="OverviewEventsInfo">
                        <span className="OverviewEventsInfoIcon">!</span>
                        <Trans
                          i18nKey={eventTypeInfoKeys[type]}
                          values={{
                            days: settings.anonymization.grace_period
                          }}>
                          <strong />
                        </Trans>
                      </InfoBar>
                    ) : null
                  }
                </OrganizationContext.Consumer>
              ) : null}
              <SortableTable
                title={type}
                pagination
                data={categorizedEvents[type]}
                className="OverviewEventsTable"
              />
            </>
          )}
        </Wrapper>
      </div>
    )
  }
}

export default withTranslation()(OverviewEvents)
