import React from 'react'
import './EventPlaceholderCard.scss'

const EventPlaceholderCard = () => (
  <div className="EventPlaceholderCard">
    <div className="card" />
  </div>
)

export default EventPlaceholderCard
