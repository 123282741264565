import './Button.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'

const addClassName = className => `Button${className ? ` ${className}` : ''}`

export default class Button extends React.PureComponent {
  render() {
    const {
      type,
      buttonType,
      title,
      to,
      children,
      disabled,
      onClick,
      className,
      loading
    } = this.props
    // If it is a link to another route
    if (to) {
      const handleOnClick = e => {
        if (disabled) {
          e.preventDefault()
        } else if (onClick) {
          onClick(e)
        }
      }

      return (
        <Link
          className={addClassName(className)}
          data-type={type}
          disabled={disabled}
          title={title}
          to={to}
          onClick={handleOnClick}>
          {children}
        </Link>
      )
    } else {
      // return an actual button
      return (
        <button
          onClick={onClick}
          className={addClassName(className)}
          data-type={type}
          title={title}
          type={buttonType}
          disabled={disabled}>
          {loading ? <Loader size="small" /> : children}
        </button>
      )
    }
  }
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf([
    'dark',
    'header',
    'primary',
    'secondary',
    'success',
    'toolbar',
    'warning'
  ]),
  buttonType: PropTypes.oneOf(['submit', 'button', 'reset']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}
