import './DraggableItem.scss'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import FieldComponent from 'components/FormFactory/FieldComponent'

import { DragIcon, DeleteIcon } from 'components/FormFactory/Icons'

export class DraggableItem extends React.Component {
  state = {
    active: false
  }

  fieldRef = React.createRef()

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick)
    document.addEventListener('keyup', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick)
    document.removeEventListener('keyup', this.handleClick)
  }

  handleClick = e => {
    const { active } = this.state

    if (!this.fieldRef.current.contains(e.target) && active) {
      this.setState({ active: false })
    }
  }

  handleFocus = () => {
    this.setState({ active: true })
  }

  render() {
    const {
      debug,
      fieldIndex,
      handleFactoryFieldDelete,
      handleInputBlur,
      parent,
      ...props
    } = this.props

    const { active } = this.state

    return (
      <Draggable
        draggableId={`drag-${parent}-${fieldIndex}`}
        index={fieldIndex}>
        {(provided, snapshot) => (
          <div
            className="DraggableItem"
            data-active={active}
            data-dragging={snapshot.isDragging}
            data-debug={debug}
            ref={provided.innerRef}
            {...provided.draggableProps}>
            <div className="container">
              <div {...provided.dragHandleProps} className="DraggableHandle">
                <DragIcon />
              </div>
              <FieldComponent
                {...props}
                fieldIndex={fieldIndex}
                handleFocus={this.handleFocus}
                handleBlur={handleInputBlur}
                active={active}
                dragging={snapshot.isDragging}
                fieldRef={this.fieldRef}
              />
              <div
                className="DraggableHandle"
                data-delete="true"
                onClick={() =>
                  handleFactoryFieldDelete({
                    fieldIndex,
                    factoryId: props.factoryId
                  })
                }>
                <DeleteIcon />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    )
  }
}

export default DraggableItem

DraggableItem.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  parent: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  factoryId: PropTypes.number.isRequired,
  handleFactoryFieldChange: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func.isRequired,
  debug: PropTypes.bool
}
