import React from 'react'

export const PlusIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" className="PlusIcon">
    <path d="M7.5 0H5.5V5.5H0V7.5H5.5V13H7.5V7.5H13V5.5H7.5V0Z" />
  </svg>
)

export const TrashCan = () => (
  <svg className="TrashCan" width="20px" height="20px" viewBox="0 0 20 20">
    <g>
      <path d="M8.07908007,5.45454545 L11.9209199,5.45454545 L11.9209199,3.04676155 C11.9209199,2.83764159 11.7550309,2.67112015 11.55521,2.67112015 L8.44479005,2.67112015 C8.24119893,2.67112015 8.07908007,2.84151418 8.07908007,3.04676155 L8.07908007,5.45454545 Z M6.36363636,5.45454545 L6.36363636,3.04676155 C6.36363636,1.86949366 7.29864744,0.909090909 8.44479005,0.909090909 L11.55521,0.909090909 C12.7013526,0.909090909 13.6363636,1.86949366 13.6363636,3.04676155 L13.6363636,5.45454545 L16.3636364,5.45454545 C16.8657134,5.45454545 17.2727273,5.86155932 17.2727273,6.36363636 C17.2727273,6.86571341 16.8657134,7.27272727 16.3636364,7.27272727 L15.4545455,7.27272727 L15.4545455,17.0909091 C15.4545455,18.1954786 14.559115,19.0909091 13.4545455,19.0909091 L6.54545455,19.0909091 C5.44088505,19.0909091 4.54545455,18.1954786 4.54545455,17.0909091 L4.54545455,7.27272727 L3.63636364,7.27272727 C3.13428659,7.27272727 2.72727273,6.86571341 2.72727273,6.36363636 C2.72727273,5.86155932 3.13428659,5.45454545 3.63636364,5.45454545 L6.36363636,5.45454545 Z" />
    </g>
  </svg>
)

export const EditIcon = () => (
  <svg className="EditIcon" width="20" height="20" viewBox="0 0 22 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18.4077C3 18.0805 3.2652 17.8153 3.59234 17.8153H16.6238C16.9509 17.8153 17.2161 18.0805 17.2161 18.4077C17.2161 18.7348 16.9509 19 16.6238 19H3.59234C3.2652 19 3 18.7348 3 18.4077Z"
    />
    <path d="M10.7004 14.2613L7.14636 14.901L7.7387 11.2996L15.7116 3.35044C15.8217 3.2394 15.9527 3.15127 16.0971 3.09112C16.2415 3.03098 16.3963 3.00002 16.5527 3.00002C16.7091 3.00002 16.8639 3.03098 17.0083 3.09112C17.1526 3.15127 17.2837 3.2394 17.3938 3.35044L18.6496 4.6062C18.7606 4.71633 18.8487 4.84735 18.9089 4.99172C18.969 5.13608 19 5.29092 19 5.44732C19 5.60371 18.969 5.75855 18.9089 5.90291C18.8487 6.04728 18.7606 6.1783 18.6496 6.28843L10.7004 14.2613Z" />
  </svg>
)

export const PreviewIcon = () => (
  <svg
    className="PreviewIcon"
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11C20 10.6287 19.8727 10.2706 19.6426 9.995C18.186 8.3 14.8704 5 11 5C7.12956 5 3.81403 8.3 2.35741 9.995C2.12735 10.2706 2 10.6287 2 11C2 11.3713 2.12735 11.7294 2.35741 12.005C3.81403 13.7 7.12956 17 11 17C14.8704 17 18.186 13.7 19.6426 12.005C19.8727 11.7294 20 11.3713 20 11ZM13.8636 11C13.8636 12.6569 12.5815 14 11 14C9.41846 14 8.13636 12.6569 8.13636 11C8.13636 9.34315 9.41846 8 11 8C12.5815 8 13.8636 9.34315 13.8636 11Z"
    />
  </svg>
)
