import {
  auth,
  organization,
  template,
  event,
  guestlist,
  party,
  dashboard,
  fields,
  exporting,
  duplicateDashboardInfo,
  extendedLandingPages,
  sms
} from 'misc/api/basic'

export {
  useEvent,
  useCheckIn,
  useExport,
  useExtendedLandingPages
} from 'misc/api/hooks'

export default {
  ...auth,
  ...organization,
  ...template,
  ...event,
  ...guestlist,
  ...party,
  ...dashboard,
  duplicateDashboardInfo,
  ...fields,
  ...exporting,
  ...extendedLandingPages,
  ...sms
}
