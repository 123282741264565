import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import DateTimePicker from 'components/DateTimePicker'
import Templates from 'components/Modals/CreateEventModal/View/Templates'
import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import Toggle from 'components/Toggle'

import { useTranslation } from 'react-i18next'

const NextOrCreate = ({
  templates,
  handleCreateEvent,
  handleNext,
  canPressNext,
  submitting
}) => {
  const { t } = useTranslation()

  if (templates.length !== 1) {
    return (
      <Button onClick={handleNext} type="primary" disabled={!canPressNext}>
        {t('next')}
      </Button>
    )
  }

  return (
    <Button
      onClick={handleCreateEvent}
      type="primary"
      disabled={!canPressNext || submitting}>
      {t('create')}
    </Button>
  )
}

export const BasicEventInfo = ({
  name,
  startTime,
  endTime,
  templates,
  handleClose,
  handleNext,
  handleNameChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleCreateEvent,
  submitting
}) => {
  const { t } = useTranslation()
  const canPressNext = name.length > 0

  return (
    <Modal size="large" modalName="CreateEventModal">
      <Header>{t('create a new event')}</Header>
      <Content>
        <InputContainer id="event_name" label={t('event name')}>
          <Text
            value={name}
            placeholder={t('event name')}
            id="event_name"
            handleChange={handleNameChange}
          />
        </InputContainer>
        <InputContainer label={t('start date')}>
          <DateTimePicker
            value={startTime}
            handleChange={handleStartTimeChange}
          />
        </InputContainer>
        <InputContainer label={t('end date')}>
          <DateTimePicker value={endTime} handleChange={handleEndTimeChange} />
        </InputContainer>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary" disabled={submitting}>
            {t('close')}
          </Button>
        }
        rightActions={
          <NextOrCreate
            templates={templates}
            handleNext={handleNext}
            canPressNext={canPressNext}
            handleCreateEvent={handleCreateEvent}
            submitting={submitting}
          />
        }
      />
    </Modal>
  )
}

export const ChooseTemplate = ({
  templates,
  chosenTemplate,
  enableExtendedLandingPages,
  handleTemplateChoice,
  handeEnableExtendedLandingPages,
  handleCreateEvent,
  handleClose,
  handlePrev,
  submitting
}) => {
  const { t } = useTranslation()

  const { settings } = React.useContext(OrganizationContext)
  const renderExtendedLandingPagesToggle =
    settings?.integrations?.extended_landing_pages?.enabled === true

  return (
    <Modal size="large" modalName="CreateEventModal">
      <Header>
        {t('choose a template')}
        {renderExtendedLandingPagesToggle && (
          <div className="ToggleExtendedLandingPages">
            <span className="Label">
              {t('extendedLandingPages.toggleCreateEvent')}
            </span>
            <Toggle
              size="medium"
              id="enableExtendedLandingPages"
              disabled={!enableExtendedLandingPages}
              handleChange={handeEnableExtendedLandingPages}
            />
          </div>
        )}
      </Header>
      <Content>
        <Templates
          templates={templates}
          chosenTemplate={chosenTemplate}
          handleChoice={handleTemplateChoice}
        />
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary" disabled={submitting}>
            {t('close')}
          </Button>
        }
        rightActions={
          <div className="ModalRightActions">
            <Button onClick={handlePrev} type="secondary">
              {t('back')}
            </Button>
            <Button
              onClick={handleCreateEvent}
              type="primary"
              disabled={!chosenTemplate || submitting}>
              {t('create')}
            </Button>
          </div>
        }
      />
    </Modal>
  )
}
