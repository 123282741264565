import './Calendar.scss'
import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import Months from 'components/DateTimePicker/Calendar/Months'
import Days from 'components/DateTimePicker/Calendar/Days'

const WeekDays = () => (
  <div className="WeekDays">
    {moment.weekdays(true).map((day, index) => (
      <div key={index} className="day">
        {day.substring(0, 2)}
      </div>
    ))}
  </div>
)

class Calendar extends React.Component {
  state = {
    date: moment(),
    prevActiveElement: null
  }

  UNSAFE_componentWillMount() {
    if (
      document.activeElement &&
      document.activeElement !== document.body &&
      document.activeElement !== document.documentElement
    ) {
      this.prevActiveElement = document.activeElement
    }
  }

  componentWillUnmount() {
    if (this.prevActiveElement) {
      this.prevActiveElement.focus()
    }
  }

  componentDidMount() {
    const { activeDate } = this.props
    activeDate && this.setState({ date: moment(activeDate) })
  }

  handleClickPrevMonth = () => {
    this.setState(({ date }) => ({ date: date.subtract(1, 'months') }))
  }

  handleClickNextMonth = () => {
    this.setState(({ date }) => ({ date: date.add(1, 'months') }))
  }

  render() {
    const { date } = this.state
    const { activeDate, handleDateClick } = this.props

    return (
      <div className="Calendar">
        <Months
          date={date}
          handleClickPrevMonth={this.handleClickPrevMonth}
          handleClickNextMonth={this.handleClickNextMonth}
        />
        <WeekDays />
        <Days date={date} onClick={handleDateClick} active={activeDate} />
      </div>
    )
  }
}

export default Calendar

Calendar.propTypes = {
  handleDateClick: PropTypes.func.isRequired,
  activeDate: PropTypes.object
}
