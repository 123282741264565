import './SmsDashboard.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import API from 'misc/api'
import { FIELDS_TYPE_KEYS } from 'misc/fieldsConstants'
import { useCheckIn } from 'misc/api'

import Button from 'components/Button'
import { PlusIcon } from 'components/Icons'
import DeleteSmsModal from 'components/Modals/DeleteSmsModal'
import PreviewSmsModal from 'components/Modals/PreviewSmsModal'
import { ScrollListModal } from 'components/Modals/ScrollListModal'

import SmsTable from './SmsTable'

const SmsDashboard = ({ eventId, event }) => {
  const { t } = useTranslation()

  const [smsList, setSmsList] = useState([])

  const [smsToDelete, setSmsToDelete] = useState(null)
  const [deleting, setDeleting] = useState(false)

  const [smsRecipients, setSmsRecipients] = useState([])
  const [showRecipientsListModal, setShowRecipientsListModal] = useState(false)

  const [smsToPreview, setSmsToPreview] = useState(null)

  const [{ parties }] = useCheckIn(event)

  const timezone = useMemo(() => {
    return event?.data?.timezone_location
  }, [event])

  const hasPhoneNumber = useMemo(() => {
    const phoneField = event?.fields.find(
      field => field.guest_type === 'leader' && field.type === 'PHONE_NUMBER'
    )
    return !!phoneField
  }, [event])

  useEffect(() => {
    fetchSmsList(eventId)
  }, [eventId])

  const openRecipientsListModal = recipientIds => {
    const firstNameKey = event.fields.find(
      field => field.type === FIELDS_TYPE_KEYS.FIRST_NAME
    )?.key
    const lastNameKey = event.fields.find(
      field => field.type === FIELDS_TYPE_KEYS.LAST_NAME
    )?.key
    const recipientsForThisSms = parties.reduce((acc, party) => {
      const guestRecievingSms = party.guests.find(guest =>
        recipientIds.includes(guest.id)
      )
      if (guestRecievingSms) {
        return [...acc, guestRecievingSms]
      }
      return acc
    }, [])

    const recipientsToText = recipientsForThisSms.map(recipient => {
      return `${recipient.guest_data[firstNameKey]} ${recipient.guest_data[lastNameKey]}`
    })

    setSmsRecipients(recipientsToText)
    setShowRecipientsListModal(true)
  }

  const fetchSmsList = async eventId => {
    try {
      const result = await API.getSmsList(eventId)
      setSmsList(result.data.data)
    } catch {
      setSmsList([])
    }
  }

  const handleDeleteSms = async sms => {
    if (!sms || deleting) {
      return
    }

    try {
      setDeleting(true)
      await API.deleteSms(sms.id)
      fetchSmsList(eventId)
    } catch {
    } finally {
      setDeleting(false)
    }

    setSmsToDelete(null)
  }

  return (
    <div className="SmsDashboard">
      <h2 className="Title">{t('sms.settings.title')}</h2>
      <p className="Description">{t('sms.settings.description')}</p>

      {!hasPhoneNumber && (
        <div className="NoPhone">
          <p>{t('sms.settings.noPhone.text')}</p>
          <Link className="NoPhoneLink" to={`/event/${eventId}/edit/signup`}>
            {t('sms.settings.noPhone.link')}
          </Link>
        </div>
      )}

      <div>
        <Button
          className="NewButton"
          to={`/event/${eventId}/sms`}
          type="primary"
          disabled={!hasPhoneNumber}>
          <PlusIcon />
          <span className="ButtonText">{t('sms.settings.newLink')}</span>
        </Button>
      </div>

      <SmsTable
        event={event}
        eventId={eventId}
        smsList={smsList}
        timezone={timezone}
        handleDeleteSms={sms => setSmsToDelete(sms)}
        handlePreviewSms={sms => setSmsToPreview(sms)}
        handleSmsRecipientsCountClick={sms =>
          openRecipientsListModal(sms.recipients)
        }
      />

      {smsToDelete && (
        <DeleteSmsModal
          handleDeleteSms={() => handleDeleteSms(smsToDelete)}
          handleClose={() => setSmsToDelete(null)}
        />
      )}

      {smsToPreview && (
        <PreviewSmsModal
          sms={smsToPreview}
          timezone={timezone}
          handleClose={() => setSmsToPreview(null)}
        />
      )}

      {showRecipientsListModal && (
        <ScrollListModal
          handleClose={() => setShowRecipientsListModal(false)}
          textEntries={smsRecipients}
          title={`${t('sms.settings.table.modal.recipients')} (${
            smsRecipients.length
          })`}></ScrollListModal>
      )}
    </div>
  )
}

export default SmsDashboard
