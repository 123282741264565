import './LocationPicker.scss'
import React, { useContext } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'
import { SearchIcon } from 'components/Search'
import { ErrorContext } from '../Contexts/ErrorProvider'

const Location = ({ suggestion, ...props }) => {
  if (
    typeof suggestion !== 'undefined' &&
    typeof suggestion.matchedSubstrings !== 'undefined' &&
    suggestion.matchedSubstrings.length > 0
  ) {
    const { length, offset } = suggestion.matchedSubstrings[0]
    return (
      <button {...props} data-active={suggestion.active} className="Location">
        {suggestion.description.slice(0, offset)}
        <b>{suggestion.description.slice(offset, offset + length)}</b>
        {suggestion.description.slice(offset + length)}
      </button>
    )
  } else {
    return (
      <button {...props} data-active={suggestion.active} className="Location">
        {suggestion.description}
      </button>
    )
  }
}

const LocationPicker = ({ handleChange, handleSelect, location }) => {
  const { t } = useTranslation()
  const { address = '' } = location
  const { setError } = useContext(ErrorContext)

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={e => setError(e.response)}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="LocationPicker">
          <input
            id="LocationInputField"
            className="LocationInput"
            {...getInputProps({
              placeholder: t('search-address')
            })}
          />
          <div
            className="DropDown"
            data-visible={loading || suggestions.length > 0}>
            <div className="DropDownInner">
              {suggestions.map((suggestion, i) => (
                <Location
                  {...getSuggestionItemProps(suggestion)}
                  key={i}
                  suggestion={suggestion}
                />
              ))}
            </div>
            {loading && <Loader size="small" />}
          </div>
          <label
            className="LocationPickerIconLabel"
            htmlFor="LocationInputField">
            <SearchIcon />
          </label>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationPicker

LocationPicker.propTypes = {
  location: PropTypes.object,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func
}

Location.propTypes = {
  suggestion: PropTypes.object
}
