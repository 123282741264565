import './GetLinkModal.scss'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import Text from 'components/Inputs/Text'
import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import { ExtendedLandingPagesContext } from 'components/Contexts/ExtendedLandingPagesProdiver'

export const GetLinkModal = ({ previewLink, eventName, handleClose }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const textRef = useRef(null)
  const { settings } = React.useContext(OrganizationContext)
  const extendedLandingPagesContext = React.useContext(
    ExtendedLandingPagesContext
  )

  const hasCampaignMemberId =
    settings?.integrations?.sf_integration?.campaign_member_id_url_toggle ===
    true
  let computedPreviewLink = `${previewLink}${
    hasCampaignMemberId ? '/signup?campaign_member_id=[[campaignMemberId]]' : ''
  }`

  if (extendedLandingPagesContext && extendedLandingPagesContext.website) {
    const { website } = extendedLandingPagesContext
    computedPreviewLink = website.public_url
  }

  const openInNewTab = () => window.open(computedPreviewLink)

  const copyToClipboard = e => {
    textRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setIsCopied(true)
  }

  // Has support in all major browsers but just to make sure
  // in case a too old browser is used
  const copyIsSupported =
    computedPreviewLink && document.queryCommandSupported('copy')

  if (isCopied) {
    setTimeout(() => setIsCopied(false), 1500)
  }

  return (
    <Modal modalName="GetLinkModal" size="medium">
      <Header>{eventName}</Header>
      <Content>
        {!isCopied ? (
          <Text
            value={computedPreviewLink}
            placeholder="No link available"
            id="preview_link"
            readOnly
            inputRef={textRef}
          />
        ) : (
          t('copied')
        )}
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
        rightActions={
          <div className="ModalRightActions">
            <Button
              onClick={openInNewTab}
              type="secondary"
              disabled={!previewLink}>
              {t('open in new tab')}
            </Button>
            <Button
              onClick={copyToClipboard}
              type="primary"
              disabled={!copyIsSupported}>
              {t('copy link')}
            </Button>
          </div>
        }
      />
    </Modal>
  )
}

export default GetLinkModal
