import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import './App.scss'
import './i18n.js'
import './misc/polyfill.js'
import { Helmet } from 'react-helmet'

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'

import OrganizationProvider, {
  OrganizationContext
} from 'components/Contexts/OrganizationProvider'
import AuthProvider from 'components/Contexts/AuthProvider'

// Route components
// Overview
import OverviewEvents from 'routes/OverviewEvents'

// Event Specific
import EventDetail from './routes/EventDetail'

// Login
import Login from './routes/Auth/Login'

// Sms creation/edit
import SmsEdit from './routes/SmsEdit'

// Authorization
import Authorization from './routes/Auth/Authorization'

// PrivateRoutes
import PrivateRoute from './routes/Auth/PrivateRoute'

// Error handling
import ErrorProvider from './components/Contexts/ErrorProvider'

export default class App extends React.Component {
  render() {
    return (
      <ErrorProvider>
        <OrganizationProvider>
          <AuthProvider>
            <OrganizationContext.Consumer>
              {({ organization }) => (
                <Helmet
                  titleTemplate={
                    organization ? `C5 - ${organization.name} - %s` : null
                  }
                  defaultTitle={
                    organization ? `C5 - ${organization.name}` : 'C5'
                  }
                />
              )}
            </OrganizationContext.Consumer>

            <Router>
              <Switch>
                <Route
                  exact
                  path={'/oauth/authorize'}
                  render={() => <Authorization />}
                />
                <Route path={'/login'} render={() => <Login />} />

                <PrivateRoute
                  exact
                  path="/"
                  render={() => <Redirect to={'/events/start'} />}
                />
                <PrivateRoute
                  exact
                  path="/events"
                  render={() => <Redirect to={'/events/start'} />}
                />
                <PrivateRoute
                  path={'/events/:type'}
                  render={({ match }) => <OverviewEvents match={match} />}
                />
                <PrivateRoute
                  path={'/event/:eventId/sms/:id?'}
                  render={() => <SmsEdit />}
                />
                <PrivateRoute
                  path={'/event/:id/:mode'}
                  render={() => <EventDetail />}
                />
                <PrivateRoute
                  path={'/event/:eventId/sms/:id?'}
                  render={() => <SmsEdit />}
                />
              </Switch>
            </Router>
          </AuthProvider>
        </OrganizationProvider>
      </ErrorProvider>
    )
  }
}
