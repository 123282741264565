import './UserSelect.scss'
import React, { useState, useContext } from 'react'
import API from 'misc/api'

import { OrganizationContext } from 'components/Contexts/OrganizationProvider'

const LogoutIcon = () => (
  <svg
    fill="currentColor"
    width="18"
    height="17"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.934 2.916a1.25 1.25 0 0 1 0 1.768 5.75 5.75 0 1 0 8.132 0 1.25 1.25 0 1 1 1.768-1.768 8.25 8.25 0 1 1-11.668 0 1.25 1.25 0 0 1 1.768 0zM9 0c.647 0 1.18.492 1.244 1.122l.006.128v6a1.25 1.25 0 0 1-2.494.128L7.75 7.25v-6C7.75.56 8.31 0 9 0z" />
  </svg>
)

const UserSelect = () => {
  const { organization } = useContext(OrganizationContext)

  const [loggingOut, setLoggingOut] = useState(false)

  const logout = () => {
    if (loggingOut) return

    setLoggingOut(true)

    API.logout()
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        setLoggingOut(false)
        console.error(err)
      })
  }

  return (
    <div className="UserSelect">
      {organization && (
        <div className="UserSelectName">{organization.name}</div>
      )}
      <button
        className="UserSelectButton"
        onClick={logout}
        disabled={loggingOut}>
        <LogoutIcon />
      </button>
    </div>
  )
}

export default UserSelect
