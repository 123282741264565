import da from './da'
import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import ja from './ja'
import nl from './nl'
import pt from './pt'
import sv from './sv'

const translations = {
  da,
  de,
  en,
  es,
  fr,
  ja,
  nl,
  pt,
  sv
}

export default translations
