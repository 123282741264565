import './PickBackgroundModal.scss'
import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import { Menu, MenuItem } from 'components/Menu'
import Button from 'components/Button'
import SelectableCard from 'components/SelectableCard'

const TAB_IMAGE = 'tab-image'
const TAB_VIDEO = 'tab-video'

export const PickBackgroundModal = ({
  title,
  backgrounds,
  selected,
  handlePick,
  handleClose
}) => {
  const [selectedBackground, setSelectedBackground] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      page: title,
      value: selected,
      fieldName: 'background'
    }
  )
  const [activeTab, setActiveTab] = useState(
    selectedBackground.value && selectedBackground.value.is_video
      ? TAB_VIDEO
      : TAB_IMAGE
  )

  const { t } = useTranslation()

  const handleSwitchTab = (e, tabKey) => {
    e.preventDefault()
    setActiveTab(tabKey)
  }

  const isSelected = item =>
    selectedBackground.value &&
    selectedBackground.value.is_video === item.is_video &&
    selectedBackground.value.value === item.value

  const { images, videos } = backgrounds
  const tabsEnabled = videos && videos.length > 0
  const tabTitle = {
    [TAB_IMAGE]: t('image'),
    [TAB_VIDEO]: t('video')
  }

  return (
    <Modal size="large" modalName="PickBackgroundModal">
      <Header>{t('pick background')}</Header>
      <Content fluid>
        {tabsEnabled && (
          <div className="tab-navigation">
            <Menu>
              {[TAB_IMAGE, TAB_VIDEO].map(tabKey => (
                <MenuItem
                  key={tabKey}
                  url={`#${tabKey}`}
                  onClick={e => {
                    handleSwitchTab(e, tabKey)
                  }}
                  isActive={activeTab === tabKey}>
                  {tabTitle[tabKey]}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        <div className="tabs-wrapper">
          <div
            className={`tab-content${
              activeTab === TAB_IMAGE ? ' active' : ''
            }`}>
            <div className="cards" data-page={title}>
              {images &&
                images.map(({ base_url, is_video, value }, index) => (
                  <SelectableCard
                    key={index}
                    src={base_url + value}
                    alt={value}
                    isSelected={isSelected({ value, is_video })}
                    onClick={() => {
                      setSelectedBackground({ value: { value, is_video } })
                    }}
                  />
                ))}
            </div>
          </div>
          <div
            className={`tab-content${
              activeTab === TAB_VIDEO ? ' active' : ''
            }`}>
            <div className="cards" data-page={title}>
              {videos &&
                videos.map(
                  ({ base_url, is_video, value, folder, poster }, index) => (
                    <SelectableCard
                      key={index}
                      src={`${base_url}/${folder}/${poster}`}
                      alt={value}
                      icon="play"
                      isSelected={isSelected({ value, is_video })}
                      onClick={() => {
                        setSelectedBackground({ value: { value, is_video } })
                      }}
                    />
                  )
                )}
            </div>
          </div>
        </div>
      </Content>
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
        rightActions={
          <Button
            onClick={() => {
              handlePick(selectedBackground)
            }}
            type="primary"
            disabled={selectedBackground.value == null}>
            {t('pick')}
          </Button>
        }
      />
    </Modal>
  )
}

export default PickBackgroundModal
