import './Modal.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const modalRoot = document.getElementById('modal-root')

export const Header = props => <div className="Header">{props.children}</div>
export const Content = props => (
  <div className={`Content${props.fluid ? ' fluid' : ''}`}>
    {props.children}
  </div>
)
export const Actions = ({ leftActions, rightActions }) => (
  <div
    className={clsx(['Actions', { ActionsFull: leftActions && rightActions }])}>
    {leftActions}
    {rightActions}
  </div>
)

export class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modalContainer = document.createElement('div')
  }

  componentDidMount() {
    document.documentElement.style.overflowY = 'hidden'

    if (!this.props.debug) {
      modalRoot.appendChild(this.modalContainer)
    }
  }

  componentWillUnmount() {
    document.documentElement.style.overflowY = null

    if (!this.props.debug) {
      modalRoot.removeChild(this.modalContainer)
    }
  }

  render() {
    const { children, size, debug, modalName, maxHeight } = this.props
    if (debug) {
      return (
        <div className="Modal" aria-modal="true">
          <div className="wrapper">
            <div
              className={clsx(['container', { Scrollable: !!maxHeight }])}
              data-size={size}
              style={{ maxHeight: maxHeight ? `${maxHeight}px` : null }}>
              {children}
            </div>
          </div>
        </div>
      )
    }
    return ReactDOM.createPortal(
      <div className="Modal" data-modal={modalName}>
        <div className="wrapper">
          <div
            className={clsx(['container', { Scrollable: !!maxHeight }])}
            data-size={size}
            style={{ maxHeight: maxHeight ? `${maxHeight}px` : null }}>
            {children}
          </div>
        </div>
      </div>,
      this.modalContainer
    )
  }
}

export default Modal

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.string
}

Actions.propTypes = {
  leftActions: PropTypes.object,
  rightActions: PropTypes.object
}
