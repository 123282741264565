import './GuestsSelector.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { groupBy } from 'lodash'

import Radiobutton from 'components/Inputs/Radiobutton'
import Search from 'components/Search'

const CheckIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g id="CheckIcon">
      <path
        d="M3.24882923,9.87529377 L17.7116356,9.87529377 C18.4848343,9.87529377 19.1116356,10.5020951 19.1116356,11.2752938 C19.1116356,12.0484925 18.4848343,12.6752938 17.7116356,12.6752938 L1.84496867,12.6752938 C1.07177,12.6752938 0.444968643,12.0484925 0.444968643,11.2752938 C0.444968643,11.2367214 0.446528556,11.1985134 0.449588738,11.1607293 L0.4457959,4.7419601 C0.445339288,3.96921832 1.07140012,3.34241718 1.8441419,3.34196057 C1.84441749,3.34196041 1.84469308,3.34196032 1.84496867,3.34196032 C2.61830111,3.34196032 3.24533899,3.96862779 3.24579595,4.7419601 L3.24882923,9.87529377 Z"
        transform="translate(9.778302, 8.008627) rotate(-47.000000) translate(-9.778302, -8.008627) "
      />
    </g>
  </svg>
)

const ListIcon = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    stroke="currentColor"
    fill="none">
    <path
      d="M12 3H13.6C13.9713 3 14.3274 3.14048 14.5899 3.39052C14.8525 3.64057 15 3.97971 15 4.33333V17.6667C15 18.0203 14.8525 18.3594 14.5899 18.6095C14.3274 18.8595 13.9713 19 13.6 19H2.4C2.0287 19 1.6726 18.8595 1.41005 18.6095C1.1475 18.3594 1 18.0203 1 17.6667V4.33333C1 3.97971 1.1475 3.64057 1.41005 3.39052C1.6726 3.14048 2.0287 3 2.4 3H4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4 1H5.6C4.71634 1 4 1.71634 4 2.6V3.4C4 4.28366 4.71634 5 5.6 5H10.4C11.2837 5 12 4.28366 12 3.4V2.6C12 1.71634 11.2837 1 10.4 1Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Checkbox = ({ label, partial, checked, readOnly, ...props }) => (
  <div
    className={clsx('CheckboxGuestsSelector', {
      'is-checked': checked,
      'is-readonly': readOnly,
      'is-partial': partial
    })}>
    <input {...props} type="checkbox" checked={checked} disabled={readOnly} />
    <label htmlFor={props.id}>
      <div className="box">
        <CheckIcon />
      </div>
      <span>{label}</span>
    </label>
  </div>
)

const Guest = ({
  guestList,
  guests,
  guestsSelected,
  guestsFilteredWithPhone,
  handleGuestListSelection
}) => {
  const { t } = useTranslation()
  const [guestsChecked, setGuestsChecked] = useState(false)
  const [allGuestsChecked, setAllGuestsChecked] = useState(false)

  useEffect(() => {
    setGuestsChecked(
      guestsFilteredWithPhone.some(
        guest =>
          guest.guestList === guestList.id && guestsSelected.includes(guest.id)
      )
    )

    setAllGuestsChecked(
      guestsFilteredWithPhone.some(guest => guest.guestList === guestList.id) &&
        guestsFilteredWithPhone.every(
          guest =>
            guest.guestList !== guestList.id ||
            guestsSelected.includes(guest.id)
        )
    )
  }, [guestList.id, guestsSelected, guestsFilteredWithPhone])

  const guestsCount = useMemo(() => {
    return guests.filter(guest => guest.guestList === guestList.id).length
  }, [guestList.id, guests])

  const getCountGuestList = (guestListId, guests, guestsSelected) => {
    const guestsCount = guests.filter(guest => guest.guestList === guestListId)
      .length
    const guestsFilteredCount = guests.filter(
      guest =>
        guest.guestList === guestListId && guestsSelected.includes(guest.id)
    ).length
    return guestsCount !== guestsFilteredCount
      ? `${guestsFilteredCount}/${guestsCount}`
      : guestsCount
  }

  return (
    <Checkbox
      name="guestList"
      id={guestList.id}
      value={guestList.id}
      checked={allGuestsChecked}
      partial={guestsChecked && !allGuestsChecked}
      label={t('guestsSelector.guestlist.timeslot', {
        count: getCountGuestList(guestList.id, guests, guestsSelected),
        name: guestList.name
      })}
      readOnly={guestsCount === 0}
      onChange={handleGuestListSelection}
    />
  )
}

const GuestsList = ({ guests, guestsSelected, handleSelectGuest }) => {
  const [searchedGuest, setSearchedGuest] = useState('')

  const guestsFiltered = useMemo(() => {
    if (!searchedGuest) {
      return guests
    }

    return guests.filter(guest => {
      return guest.fullName.match(new RegExp(searchedGuest, 'i'))
    })
  }, [guests, searchedGuest])

  const guestsByFirstLetter = useMemo(() => {
    return groupBy(guestsFiltered, guest => {
      return guest.fullName ? guest.fullName.substr(0, 1).toUpperCase() : '#'
    })
  }, [guestsFiltered])

  const guestsLetters = useMemo(() => {
    return Object.keys(guestsByFirstLetter).sort()
  }, [guestsByFirstLetter])

  const handleSearchGuest = e => {
    setSearchedGuest(e.target.value)
  }

  return (
    <div className="GuestsListContainer">
      <Search handleInputChange={handleSearchGuest} />
      <dl>
        {guestsLetters.map(letter => (
          <React.Fragment key={letter}>
            <dt className="GuestListSubtitle">{letter}</dt>
            {guestsByFirstLetter[letter].map(guest => (
              <dd className="GuestsListItem" key={guest.id}>
                <Checkbox
                  name="guests"
                  id={guest.id}
                  label={guest.fullName}
                  checked={guestsSelected.includes(guest.id)}
                  value={guest.id}
                  readOnly={!guest.phone || guest.canceled}
                  onChange={handleSelectGuest}
                />
              </dd>
            ))}
          </React.Fragment>
        ))}
      </dl>
    </div>
  )
}

const GuestsSelector = ({
  value,
  parties,
  guestLists,
  fields,
  handleChange
}) => {
  const { t } = useTranslation()
  const [guestsMap, setGuestsMap] = useState()
  const [statusFilter, setStatusFilter] = useState('none')
  const [guestsSelected, setGuestsSelected] = useState([])
  const [showFullList, setShowFullList] = useState(false)

  const guests = useMemo(() => {
    return guestsMap ? Array.from(guestsMap, ([key, value]) => value) : []
  }, [guestsMap])

  const guestsSelectable = useMemo(() => {
    return guests.filter(guest => guest.phone && !guest.canceled)
  }, [guests])

  const guestsFiltered = useMemo(() => {
    return guests.filter(guest => {
      let result = true

      if (statusFilter === 'checkedIn') {
        result = guest.checkedIn
      } else if (statusFilter === 'notCheckedIn') {
        result = !guest.checkedIn
      }
      return result
    })
  }, [guests, statusFilter])

  const guestsFilteredSelectable = useMemo(() => {
    return guestsFiltered.filter(guest => guest.phone && !guest.canceled)
  }, [guestsFiltered])

  const [checkedIn, notCheckedIn] = useMemo(() => {
    let checkedIn = 0,
      notCheckedIn = 0

    guestsSelectable.forEach(guest => {
      if (guest.checkedIn) {
        checkedIn++
      } else {
        notCheckedIn++
      }
    })

    return [checkedIn, notCheckedIn]
  }, [guestsSelectable])

  useEffect(() => {
    setGuestsSelected(value ? value : [])
  }, [value])

  useEffect(() => {
    const list = new Map()

    parties.forEach(party => {
      party.guests.forEach(guest => {
        const guestData = {
          id: guest.id,
          firstName: null,
          lastName: null,
          fullName: '',
          phone: null,
          canceled: guest.canceled,
          checkedIn: guest.checked_in,
          guestList: party.guestlist
        }

        Object.keys(guest.guest_data).forEach(key => {
          const field = fields?.find(field => field.key === key)
          if (!field) {
            return
          }

          switch (field.type) {
            case 'FIRST_NAME':
              guestData.firstName = guest.guest_data[key]
              break

            case 'LAST_NAME':
              guestData.lastName = guest.guest_data[key]
              break

            case 'PHONE_NUMBER':
              guestData.phone = guest.guest_data[key]
              break

            default:
              break
          }
        })

        guestData.fullName = guestData.firstName
          ? `${guestData.firstName} ${guestData.lastName}`
          : guestData.lastName

        list.set(guest.id, guestData)
      })
    })

    setGuestsMap(list)
  }, [parties, fields])

  const changeGuestsSelected = guests => {
    setGuestsSelected(guests)
    handleChange(guests)
  }

  const handleGuestListSelection = e => {
    let value = e.target.value
    const checked = e.target.checked

    if (value === 'all') {
      if (checked) {
        const newList = new Set([
          ...guestsSelected,
          ...guestsFilteredSelectable.map(guest => guest.id)
        ])
        changeGuestsSelected([...newList])
      } else {
        changeGuestsSelected([])
      }
    } else {
      value = Number(value)
      if (checked) {
        const newList = new Set([
          ...guestsSelected,
          ...guestsFilteredSelectable
            .filter(guest => guest.guestList === value)
            .map(guest => guest.id)
        ])
        changeGuestsSelected([...newList])
      } else {
        const newList = guestsSelected.filter(guestId => {
          const guest = guestsMap.get(guestId)
          return guest.guestList !== value
        })
        changeGuestsSelected([...newList])
      }
    }
  }

  const handleStatusFilter = e => {
    const status = e.target.id

    const newList = guestsSelected.filter(guestId => {
      const guest = guestsMap.get(guestId)

      if (status === 'checkedIn') {
        return guest.checkedIn
      } else if (status === 'notCheckedIn') {
        return !guest.checkedIn
      } else {
        return true
      }
    })
    changeGuestsSelected([...newList])

    setStatusFilter(status)
  }

  const handleSelectGuest = e => {
    if (e.target.checked) {
      changeGuestsSelected([...guestsSelected, Number(e.target.value)])
    } else {
      const index = guestsSelected.indexOf(Number(e.target.value))
      guestsSelected.splice(index, 1)
      changeGuestsSelected([...guestsSelected])
    }
  }

  return (
    <div className="GuestsSelector">
      <h3 className="GuestsSelectorTitle">
        {t('guestsSelector.guestlist.title')}
      </h3>
      <div className="GuestListsSelect">
        <Checkbox
          name="guestList"
          id="all"
          value="all"
          checked={
            guestsFilteredSelectable.length > 0 &&
            guestsFilteredSelectable.every(guest =>
              guestsSelected.includes(guest.id)
            )
          }
          label={t('guestsSelector.guestlist.all', {
            count:
              guestsSelectable.length !== guestsSelected.length
                ? `${guestsSelected.length}/${guestsSelectable.length}`
                : guestsSelectable.length
          })}
          readOnly={guests.length === 0}
          onChange={handleGuestListSelection}
        />

        {guestLists?.length > 1 &&
          guestLists.map(guestList => (
            <Guest
              key={guestList.id}
              guestList={guestList}
              guests={guestsSelectable}
              guestsFilteredWithPhone={guestsFilteredSelectable}
              guestsSelected={guestsSelected}
              handleGuestListSelection={handleGuestListSelection}
            />
          ))}
      </div>
      {guests.length === 0 && (
        <p className="Error">{t('guestsSelector.guestlist.empty')}</p>
      )}

      <h3 className="GuestsSelectorTitle">
        {t('guestsSelector.statusFilter.title')}
      </h3>
      <div className="RadioButtons">
        <Radiobutton
          id="none"
          name="statusFilter"
          value={t('guestsSelector.statusFilter.none')}
          checked={statusFilter === 'none'}
          handleChange={handleStatusFilter}
        />

        <Radiobutton
          id="checkedIn"
          name="statusFilter"
          value={t('guestsSelector.statusFilter.checkedIn', {
            count: checkedIn
          })}
          checked={statusFilter === 'checkedIn'}
          handleChange={handleStatusFilter}
        />

        <Radiobutton
          id="notCheckedIn"
          name="statusFilter"
          value={t('guestsSelector.statusFilter.notCheckedIn', {
            count: notCheckedIn
          })}
          checked={statusFilter === 'notCheckedIn'}
          handleChange={handleStatusFilter}
        />
      </div>

      <div className="GuestListSection">
        <button
          className="GuestsListToggle"
          type="button"
          onClick={() => setShowFullList(!showFullList)}>
          <ListIcon />
          <span>
            {t(
              showFullList
                ? 'guestsSelector.fullList.hide'
                : 'guestsSelector.fullList.show'
            )}
          </span>
        </button>
        {showFullList && (
          <GuestsList
            guests={guests}
            guestsSelected={guestsSelected}
            handleSelectGuest={handleSelectGuest}
          />
        )}
      </div>
    </div>
  )
}

export default GuestsSelector

GuestsSelector.propTypes = {
  value: PropTypes.array,
  parties: PropTypes.array.isRequired,
  fields: PropTypes.array,
  guestLists: PropTypes.array,
  handleChange: PropTypes.func.isRequired
}
