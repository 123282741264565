const inputMap = {
  keydown: 'keyboard',
  mousemove: 'mouse',
  touchstart: 'touch'
}

const inputTypes = Object.keys(inputMap)

const ignoreKeys = [13, 16, 17, 18, 27, 91, 93] // enter, shift, ctrl, alt, esc, cmd (left), cmd (right)

let unsetListeners

const setInput = event => {
  if (
    inputMap[event.type] === 'keyboard' &&
    ignoreKeys.indexOf(event.which) !== -1
  ) {
    return
  }

  document.body.setAttribute('data-input-type', inputMap[event.type])

  unsetListeners()

  unsetListeners = setListeners(
    inputTypes.filter(type => inputMap[type] !== inputMap[event.type])
  )
}

const opts = { passive: true }

const setListeners = listeners => {
  listeners.forEach(listener =>
    window.addEventListener(listener, setInput, opts)
  )
  return () =>
    listeners.forEach(listener =>
      window.removeEventListener(listener, setInput, opts)
    )
}

const init = () => {
  unsetListeners = setListeners(inputTypes)
  return unsetListeners
}

export default init
