import ContentEditorImageWorkerManifest from 'assets/contentEditor/contenteditor-imageworker.manifest.json'
import ContentEditorManifest from 'assets/contentEditor/contenteditor.manifest.json'

let contentEditorStatus = 'unset'
let contentEditorCallbacks = []

const imageworkerPath =
  '/vendor/contentEditor/' + ContentEditorImageWorkerManifest.entry

export function loadContentEditor(callback) {
  if (contentEditorStatus === 'unset') {
    contentEditorStatus = 'loading'
    contentEditorCallbacks.push(callback)

    let elem = document.createElement('script')
    elem.src = '/vendor/contentEditor/' + ContentEditorManifest.entry
    elem.addEventListener('load', () => {
      window.contentEditorImageResampleTaskScheduler = window.ContentEditor.createImageResampleTaskScheduler(
        new Worker(imageworkerPath)
      )

      contentEditorStatus = 'loaded'

      for (let callback of contentEditorCallbacks) {
        callback(true)
      }

      contentEditorCallbacks = null
    })
    elem.addEventListener('error', () => {
      document.head.removeChild(elem)
      contentEditorStatus = 'unset'

      for (let callback of contentEditorCallbacks) {
        callback(false)
      }

      contentEditorCallbacks = null
    })

    document.head.appendChild(elem)

    return () => {
      if (contentEditorCallbacks === null) {
        return
      }

      let i = contentEditorCallbacks.indexOf(callback)
      contentEditorCallbacks.splice(i, 1)
    }
  }

  if (contentEditorStatus === 'loading') {
    return () => {}
  }

  if (contentEditorStatus === 'loaded') {
    callback(true)
    return () => {}
  }

  throw new Error()
}
