import './CreateEventCard.scss'
import React, { useState } from 'react'

import CreateEventModal from 'components/Modals/CreateEventModal'

import { useTranslation } from 'react-i18next'

const PlusIcon = () => (
  <svg width="38px" height="38px" viewBox="0 0 20 20" className="PlusIcon">
    <g>
      <path d="M10.5263158,9.47368421 L20,9.47368421 L20,10.5263158 L10.5263158,10.5263158 L10.5263158,20 L9.47368421,20 L9.47368421,10.5263158 L0,10.5263158 L0,9.47368421 L9.47368421,9.47368421 L9.47368421,0 L10.5263158,0 L10.5263158,9.47368421 Z" />
    </g>
  </svg>
)

const CreateEventCard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="CreateEventCard">
      <button
        onClick={() => setModalIsOpen(true)}
        className="CreateEventButton">
        <PlusIcon />
      </button>
      {modalIsOpen && (
        <CreateEventModal handleClose={() => setModalIsOpen(false)} />
      )}
      <div className="meta">
        <h3 className="title">{t('create new')}</h3>
      </div>
    </div>
  )
}

export default CreateEventCard
