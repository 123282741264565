import './SmsEdit.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import clsx from 'clsx'

import API, { useEvent, useCheckIn } from 'misc/api'

import {
  CloseButton,
  SaveButton,
  ScheduleButton,
  SendButton,
  ResetChangesButton,
  CancelButton
} from './Buttons'

import { OrganizationContext } from 'components/Contexts/OrganizationProvider'
import DateTimePicker from 'components/DateTimePicker'
import Header from 'components/Header'
import InputContainer from 'components/Inputs/Container'
import Radiobutton from 'components/Inputs/Radiobutton'
import Text from 'components/Inputs/Text'
import Textarea from 'components/Inputs/Textarea'
import GuestsSelector from 'components/GuestsSelector'
import SmsFooter from 'components/SmsFooter'
import ErrorSmsModal from 'components/Modals/ErrorSmsModal'
import SendSmsModal from 'components/Modals/SendSmsModal'

const REGEX_FROM = /^[a-zA-Z0-9\s]*$/

const SmsEdit = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id, eventId } = props.match.params

  const { settings } = React.useContext(OrganizationContext)
  const [{ event }] = useEvent(eventId)
  const [{ parties }] = useCheckIn(event)

  const [sms, setSms] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [sending, setSending] = useState(false)
  const [scheduling, setScheduling] = useState(false)
  const [canceling, setCanceling] = useState(false)

  const [hasChanges, setHasChanges] = useState(false)
  const [from, setFrom] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [type, setType] = useState()
  const [date, setDate] = useState()
  const [recipients, setRecipients] = useState([])

  const [errors, setErrors] = useState({
    fromNameErrored: false,
    dayErrored: false,
    timeErrored: false
  })
  const [errorModal, setErrorModal] = useState(null)

  const [reportingModalOpen, setReportingModalOpen] = useState(false)

  const timezone = useMemo(() => {
    return event?.data?.timezone_location
  }, [event])

  useEffect(() => {
    if (id) {
      fetchSms(id)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (sms) {
      setFrom(sms.from_alphanumeric || '')
      setName(sms.name)
      setMessage(sms.message)
      setType(sms.type)
      let date = sms.date ? moment.parseZone(sms.date) : moment()
      if (timezone) {
        date = date.tz(timezone)
      }
      setDate(date.format())
      setRecipients(sms.recipients)
    }
  }, [sms, timezone])

  useEffect(() => {
    if (!id) {
      setFrom(settings?.sms?.from_alphanumeric || '')
    }
  }, [id, settings])

  const fetchSms = async id => {
    setLoading(true)
    try {
      const { data } = await API.getSmsDetail(id)
      setSms(data.data)
    } catch (error) {
      if (error.response.status === 404) {
        history.goBack()
        return
      }
      handleError(error)
    }
    setLoading(false)
  }

  const resetErrors = () => {
    setErrors({
      dayErrored: false,
      timeErrored: false
    })
  }

  const handleChangeFrom = event => {
    setFrom(event.target.value)
    setHasChanges(true)
  }

  const handleChangeName = event => {
    setName(event.target.value)
    setHasChanges(true)
  }

  const handleChangeMessage = event => {
    setMessage(event.target.value)
    setHasChanges(true)
  }

  const handleChangeType = e => {
    const value = e.target.id
    setType(value)
    setHasChanges(true)
  }

  const handleDateChange = value => {
    setDate(value)
    setHasChanges(true)
  }

  const handleGuestsChange = guests => {
    setRecipients(guests)
    setHasChanges(true)
  }

  const dateAsUtc = date =>
    moment(date)
      .utc()
      .format()

  const handleCreate = async () => {
    setSaving(true)
    try {
      const { data } = await API.createSms({
        event_id: Number(eventId),
        from_alphanumeric: from || null,
        name: name,
        message: message,
        recipients: recipients,
        type: type,
        date: type === 'schedule' ? dateAsUtc(date) : null
      })

      const newSms = data.data
      setSms(newSms)
      setHasChanges(false)
      history.replace(`/event/${eventId}/sms/${newSms.id}`)
    } catch (error) {
      handleError(error)
    }
    setSaving(false)
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const { data } = await API.saveSms(sms.id, {
        from_alphanumeric: from || null,
        name: name,
        message: message,
        recipients: recipients,
        type: type,
        date: type === 'schedule' ? dateAsUtc(date) : null
      })

      setSms(data.data)
      setHasChanges(false)
    } catch (error) {
      handleError(error)
    }
    setSaving(false)
  }

  const validate = () => {
    // Check from name
    if (from && !REGEX_FROM.test(from)) {
      setErrors({
        fromNameErrored: true,
        dayErrored: false,
        timeErrored: false
      })
      return false
    }

    // Check date is not in past
    const dateParsed =
      type === 'schedule' && date ? moment.parseZone(date) : null
    const now = moment()
    if (dateParsed && dateParsed.isBefore(now)) {
      setErrors({
        fromNameErrored: false,
        dayErrored: dateParsed.diff(now, 'days') < 0,
        timeErrored: true
      })
      return false
    }

    return true
  }

  const handleSubmit = event => {
    event.preventDefault()

    // Clear errors
    resetErrors()
    if (!validate()) {
      return
    }

    if (!sms) {
      handleCreate()
    } else {
      handleSave()
    }
  }

  const handleConfirmSendOrSchedule = () => {
    if (!sms) {
      return
    }

    setReportingModalOpen(false)
    if (sms.type === 'schedule') {
      handleOnSchedule()
    } else {
      handleOnSend()
    }
  }

  const handleOnSend = async () => {
    setSending(true)
    try {
      const { data } = await API.sendSms(sms.id)
      setSms(data.data)

      setSending(false)

      // Redirect to sms dashboard
      history.push(`/event/${eventId}/settings/sms`)
    } catch (error) {
      handleError(error)
      setSending(false)
    }
  }

  const handleOnSchedule = async () => {
    setScheduling(true)
    try {
      const { data } = await API.scheduleSms(sms.id)
      setSms(data.data)
    } catch (error) {
      handleError(error)
    }
    setScheduling(false)
  }

  const handleResetChanges = () => {
    setSms({ ...sms })
    resetErrors()
    setHasChanges(false)
  }

  const handleCancel = async () => {
    setCanceling(true)
    try {
      const { data } = await API.cancelSms(sms.id)
      setSms(data.data)
    } catch (error) {
      handleError(error)
    }
    setCanceling(false)
  }

  const handleError = error => {
    const data = error?.response?.data
    if (data) {
      setErrorModal({
        title: t('sms.edit.error.validation'),
        messages: data.message ? [] : Object.keys(data).map(key => data[key])
      })
    } else {
      setErrorModal({})
    }
  }

  const SmsToolBar = ({ sms }) => {
    if (sms.status === 'sent') {
      return null
    } else if (hasChanges) {
      return (
        <>
          <ResetChangesButton
            buttonType="button"
            onClick={handleResetChanges}
          />
          <SaveButton buttonType="submit" loading={saving} disabled={saving} />
        </>
      )
    } else if (sms.status === 'scheduled') {
      return (
        <CancelButton
          buttonType="button"
          loading={canceling}
          onClick={handleCancel}
        />
      )
    } else {
      return sms.type === 'schedule' ? (
        <ScheduleButton
          buttonType="button"
          loading={scheduling}
          disabled={scheduling || recipients.length === 0}
          onClick={() => setReportingModalOpen(true)}
        />
      ) : (
        <SendButton
          buttonType="button"
          loading={sending}
          disabled={sending || recipients.length === 0}
          onClick={() => setReportingModalOpen(true)}
        />
      )
    }
  }

  const rightNav = (
    <div className="SmsEditToolbar">
      {sms ? (
        <SmsToolBar sms={sms} />
      ) : (
        <SaveButton buttonType="submit" loading={saving} disabled={saving} />
      )}
      <CloseButton
        to={`/event/${eventId}/settings/sms`}
        disabled={sms && hasChanges}
      />
    </div>
  )

  return (
    <form className="SmsEdit" onSubmit={handleSubmit}>
      <Header
        dark
        left={
          <div className="SmsEditTitle" title={t('sms.edit.title')}>
            {t('sms.edit.headerTitle')}
          </div>
        }
        right={rightNav}
      />

      <div className={clsx('Container', { 'is-loading': loading })}>
        <h1>{t('sms.edit.title')}</h1>

        <InputContainer label={t('sms.edit.from')}>
          <Text
            label={t('sms.edit.from')}
            placeholder={t('sms.edit.from')}
            value={from}
            maxLength={11}
            errored={errors.fromNameErrored}
            handleChange={handleChangeFrom}
          />
          {errors.fromNameErrored && (
            <p className="SmsEditError">{t('sms.edit.error.from')}</p>
          )}
        </InputContainer>

        <InputContainer label={t('sms.edit.name')}>
          <Text
            label={t('sms.edit.name')}
            placeholder={t('sms.edit.name')}
            value={name}
            handleChange={handleChangeName}
            maxLength={255}
            required
          />
        </InputContainer>

        <InputContainer label={t('sms.edit.message')}>
          <Textarea
            label={t('sms.edit.message')}
            placeholder={t('sms.edit.message')}
            value={message}
            handleChange={handleChangeMessage}
            maxLength={120}
            required
          />
          <p className="CharacterCount">
            {t('sms.edit.messageLength', { count: message.length, max: 120 })}
          </p>
        </InputContainer>

        <h2 className="Subtitle">{t('sms.edit.scheduleTitle')}</h2>
        <div className="RadioButtons">
          <Radiobutton
            id="send"
            name="type"
            value={t('sms.edit.send')}
            checked={type === 'send'}
            disabled={sms && sms.status !== 'draft'}
            handleChange={handleChangeType}
            required
          />

          <Radiobutton
            id="schedule"
            name="type"
            value={t('sms.edit.schedule')}
            checked={type === 'schedule'}
            disabled={sms && sms.status !== 'draft'}
            handleChange={handleChangeType}
            required
          />
        </div>

        <DateTimePicker
          value={date}
          timezone={timezone}
          readOnly={type !== 'schedule' || (sms && sms.status !== 'draft')}
          dayErrored={errors.dayErrored}
          timeErrored={errors.timeErrored}
          handleChange={handleDateChange}
        />
        {errors.timeErrored && (
          <p className="SmsEditError">{t('sms.edit.error.time')}</p>
        )}

        <h2 className="Subtitle">{t('sms.edit.recipients')}</h2>
        <GuestsSelector
          value={recipients}
          parties={parties}
          fields={event?.fields}
          guestLists={event?.guestlists}
          handleChange={handleGuestsChange}
        />
      </div>

      <SmsFooter
        recipients={recipients}
        type={type}
        date={date}
        timezone={timezone}
      />

      {reportingModalOpen && (
        <SendSmsModal
          title={t(
            sms.type === 'schedule'
              ? 'sms.edit.reporting.schedule.title'
              : 'sms.edit.reporting.send.title'
          )}
          description={t(
            sms.type === 'schedule'
              ? 'sms.edit.reporting.schedule.description'
              : 'sms.edit.reporting.send.description',
            { count: recipients.length }
          )}
          handleClose={() => setReportingModalOpen(false)}
          handleConfirm={handleConfirmSendOrSchedule}
        />
      )}

      {errorModal && (
        <ErrorSmsModal
          title={errorModal.title || t('sms.edit.error.unexpected')}
          descriptions={
            errorModal.messages?.length
              ? errorModal.messages
              : [t('sms.edit.error.message')]
          }
          handleClose={() => setErrorModal(false)}
        />
      )}
    </form>
  )
}

export default withRouter(SmsEdit)
