import React from 'react'

const CopyIcon = props => (
  <svg
    {...props}
    height="18"
    viewBox="0 0 18 18"
    width="18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m9 0c1.1045695 0 2 .8954305 2 2v4h-2v-4h-7v10h5v2h-5c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2z" />
    <path d="m7 4h7c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-7c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2zm0 2v10h7v-10z" />
  </svg>
)

export default CopyIcon
