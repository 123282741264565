import './TimezonePicker.scss'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SearchIcon } from 'components/Search'

function searchTimezones(timezones, search) {
  const searchResult = []

  if (search) {
    for (let i = 0; i < timezones.length; i++) {
      if (timezones[i].toLowerCase().indexOf(search) !== -1) {
        if (searchResult.push(timezones[i]) >= 5) {
          break
        }
      }
    }
  }

  return searchResult
}

const TimezoneItem = ({ className, timezone, isSelected, ...props }) => {
  const parsedZone = moment.parseZone(moment.tz(timezone))
  const timezoneArea = parsedZone.format('z')
  const timezoneOffset = parsedZone.format('Z')

  const city = timezone.split('/').pop()

  return (
    <button
      {...props}
      type="button"
      className={clsx('TimezoneItem', className, { isSelected })}>
      <span className="TimezoneItemBody">
        {timezoneArea && (
          <span className="TimezoneItemArea">{timezoneArea}</span>
        )}
        {city && <span>{city.replace('_', ' ')}</span>}
      </span>
      <span>{timezoneOffset}</span>
    </button>
  )
}

const TimezoneList = ({
  handleSelect,
  search,
  selectedIndex,
  setDropdownItems
}) => {
  const { t } = useTranslation()

  const dropdownRef = React.createRef()

  const searchResult = searchTimezones(
    moment.tz.names(),
    search.replace(' ', '_')
  )

  useEffect(() => {
    setDropdownItems(searchResult)
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = timezone => () => handleSelect(timezone)

  return (
    <div className="TimezoneList" ref={dropdownRef}>
      {searchResult.length > 0 ? (
        searchResult.map((timezone, i) => (
          <TimezoneItem
            isSelected={selectedIndex === i}
            key={i}
            timezone={timezone}
            onClick={handleClick(timezone)}
          />
        ))
      ) : (
        <div className="TimezoneEmpty">{t('no results')}</div>
      )}
    </div>
  )
}

const TimezonePicker = ({
  value,
  isOpen,
  handleLocationChange,
  handleSelect,
  selectedIndex,
  setDropdownItems
}) => {
  const { t } = useTranslation()

  return (
    <div className="Timezone">
      <input
        type="text"
        value={value}
        onChange={handleLocationChange}
        placeholder={t('change timezone')}
        onFocus={event => event.target.select()}
        className={clsx('TimezoneInput', {
          isOpen: isOpen && value
        })}
      />

      <SearchIcon className="TimezoneIcon" />

      {isOpen && value && (
        <TimezoneList
          search={value.toLowerCase()}
          handleSelect={handleSelect}
          selectedIndex={selectedIndex}
          setDropdownItems={setDropdownItems}
        />
      )}
    </div>
  )
}

export default TimezonePicker

TimezonePicker.propTypes = {
  location: PropTypes.string,
  isOpen: PropTypes.bool,
  handleLocationChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired
}
