import './Actions.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Components
import Button from 'components/Button'
import {
  IconCheckmark,
  IconCancel,
  IconDelete,
  IconUndo,
  IconGroupCancel,
  IconGroupEdit,
  IconGroupRestore
} from 'components/AccordionTable/Accordion/Guest/icons'

// Modals
import { ManagePartyModal } from 'components/Modals/ManagePartyModal'

// Translation
import { useTranslation } from 'react-i18next'

const ManageParty = ({ party, leader, leaderFields, followerFields }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <Button type="secondary" onClick={() => setModalIsOpen(true)}>
        <IconGroupEdit className="AccordionPartyIcon" />
        {t('manage party')}
      </Button>

      {modalIsOpen && (
        <ManagePartyModal
          handleCloseModal={() => setModalIsOpen(false)}
          leader={leader}
          party={party}
          leaderFields={leaderFields}
          followerFields={followerFields}
        />
      )}
    </div>
  )
}

export const PartyActions = ({
  handleCancelParty,
  partyId,
  party,
  leader,
  leaderFields,
  followerFields,
  canceled,
  handleRestoreParty,
  canRestore
}) => {
  const { t } = useTranslation()

  return (
    <div className="PartyActions">
      <ManageParty
        party={party}
        leader={leader}
        leaderFields={leaderFields}
        followerFields={followerFields}
      />

      <Button
        type="secondary"
        disabled={canceled ? !canRestore : false}
        onClick={(canceled ? handleRestoreParty : handleCancelParty).bind(
          null,
          partyId
        )}>
        {canceled ? (
          <IconGroupRestore className="AccordionPartyIcon" />
        ) : (
          <IconGroupCancel className="AccordionPartyIcon" />
        )}

        {t(canceled ? 'restore party' : 'cancel party')}
      </Button>
    </div>
  )
}

export const GuestActions = ({
  eventFull,
  guest,
  guestId,
  partyId,
  handleDeleteGuest,
  handleToggleGuestCancel
}) => {
  const { t } = useTranslation()
  const { canceled, checked_in, guest_type } = guest

  return (
    <div className="GuestActions">
      <div className="checkin">{checked_in && <IconCheckmark />}</div>
      <div className="actions">
        {canceled ? (
          <CanceledActions
            handleDeleteGuest={handleDeleteGuest}
            handleToggleGuestCancel={handleToggleGuestCancel}
            leader={guest_type === 'leader'}
            guestId={guestId}
            partyId={partyId}
            eventFull={eventFull}
            data-width="full"
          />
        ) : (
          <button
            className="ActionsButton"
            onClick={() => handleToggleGuestCancel(guestId, partyId)}
            title={t('cancelGuest')}>
            <IconCancel />
          </button>
        )}
      </div>
    </div>
  )
}

const CanceledActions = ({
  eventFull,
  guestId,
  handleDeleteGuest,
  handleToggleGuestCancel,
  leader,
  partyId
}) => {
  const { t } = useTranslation()

  return (
    <div className="CanceledActions">
      {!leader && (
        <IconDelete handleClick={() => handleDeleteGuest(guestId, partyId)} />
      )}
      <button
        className={clsx(['ActionsButton', { 'is-disabled': eventFull }])}
        disabled={eventFull}
        onClick={() => handleToggleGuestCancel(guestId, partyId)}
        title={t('restoreGuest')}>
        <IconUndo />
      </button>
    </div>
  )
}

ManageParty.propTypes = {
  party: PropTypes.array.isRequired,
  leader: PropTypes.object.isRequired,
  leaderFields: PropTypes.array.isRequired,
  guestFields: PropTypes.array
}
PartyActions.propTypes = {
  partyId: PropTypes.number.isRequired,
  handleCancelParty: PropTypes.func.isRequired,
  party: PropTypes.array.isRequired,
  leader: PropTypes.object.isRequired,
  leaderFields: PropTypes.array.isRequired,
  guestFields: PropTypes.array
}

GuestActions.propTypes = {
  guest: PropTypes.object.isRequired,
  guestId: PropTypes.number.isRequired,
  partyId: PropTypes.number.isRequired,
  handleDeleteGuest: PropTypes.func,
  handleToggleGuestCancel: PropTypes.func.isRequired
}

CanceledActions.propTypes = {
  leader: PropTypes.bool,
  guestId: PropTypes.number.isRequired,
  partyId: PropTypes.number.isRequired,
  handleDeleteGuest: PropTypes.func,
  handleToggleGuestCancel: PropTypes.func.isRequired
}
