import './GuestToggle.scss'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Toggle from 'components/Toggle'

const UserIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" className="UserIcon">
    <g>
      <path d="M8.46423494,13.1133333 C10.4098626,12.6352222 11.5456041,12.2170556 10.6678314,10.4541111 C7.99853341,5.09016667 9.96045383,2.22222222 12.779781,2.22222222 C15.6547751,2.22222222 17.5684961,5.20066667 14.8910517,10.4541111 C13.9868033,12.2271667 15.1877158,12.6453333 17.0919327,13.1133333 C18.777555,13.5278889 18.8888889,14.4039444 18.8888889,15.9220556 L18.8888889,16.6666667 L6.66931536,16.6666667 C6.66931536,14.6841667 6.51996502,13.5914444 8.46423494,13.1133333 Z M0,16.6666667 L5.40121535,16.6666667 C5.37700706,10.6096296 7.77777778,12.7311111 7.77777778,8.42444444 C7.77777778,6.56444444 6.64413814,5.55555556 5.18541574,5.55555556 C3.03087792,5.55555556 1.53203893,7.76222222 3.57176029,11.8881481 C4.24198409,13.2437037 2.85865323,13.5659259 1.37157255,13.9333333 C0.0836915172,14.2518519 3.07161155e-16,14.9259259 3.07161155e-16,16.0940741 L0,16.6666667 Z" />
    </g>
  </svg>
)

export const GuestCount = ({
  className,
  value,
  disabled,
  handleChange,
  handleBlur,
  name
}) => {
  const handleFocus = event => event.target.select()
  return (
    <div className={clsx('GuestCount', className)} data-disabled={disabled}>
      <div className="icon-container">
        <UserIcon />
      </div>
      <input
        disabled={disabled}
        type="text"
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
      />
    </div>
  )
}

const GuestToggle = ({
  id,
  maxNumberOfFollowers,
  guestIsEnabled,
  handleToggleGuest,
  handleGuestCountChange,
  handleOnBlur
}) => {
  return (
    <div className="GuestToggle">
      <Toggle
        size="large"
        id={`${id}_toggle`}
        handleChange={handleToggleGuest}
        disabled={!guestIsEnabled}
      />
      <GuestCount
        disabled={!guestIsEnabled}
        handleChange={handleGuestCountChange}
        value={maxNumberOfFollowers}
        handleBlur={handleOnBlur}
        className="GuestToggleCount"
      />
    </div>
  )
}

export default GuestToggle

GuestToggle.propTypes = {
  id: PropTypes.string.isRequired,
  maxNumberOfFollowers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  guestIsEnabled: PropTypes.bool.isRequired,
  handleToggleGuest: PropTypes.func.isRequired,
  handleGuestCountChange: PropTypes.func.isRequired
}
