import { useReducer, useEffect } from 'react'
import API from 'misc/api'

const reducer = (state, newState) => ({ ...state, ...newState })

export const useExport = guestlistId => {
  const [state, setState] = useReducer(reducer, {
    all: [],
    checkedIn: [],
    notCheckedIn: []
  })

  const fetchEmails = (stateKey, params = {}) => {
    API.getEmails(guestlistId, params)
      .then(({ data, status }) => {
        if (status === 200) {
          setState({ [stateKey]: data })
        }
      })
      .catch(error => console.error(error))
  }

  const fetchAllEmails = () => {
    fetchEmails('all', {
      canceled: false
    })
  }

  const fetchCheckedInEmails = () => {
    fetchEmails('checkedIn', {
      checkin: true,
      canceled: false
    })
  }

  const fetchNotCheckedInEmails = () => {
    fetchEmails('notCheckedIn', {
      checkin: false,
      canceled: false
    })
  }

  const handlers = {
    fetchAllEmails,
    fetchCheckedInEmails,
    fetchNotCheckedInEmails
  }

  useEffect(() => {
    if (guestlistId != null) {
      Object.values(handlers).forEach(fn => fn())
    }
  }, [guestlistId]) // eslint-disable-line react-hooks/exhaustive-deps

  return [state, handlers]
}
