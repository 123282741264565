import React from 'react'
import PropTypes from 'prop-types'
import { loadContentEditor } from 'misc/contentEditor'

const headerHeight = 120

const ContentEditor = ({
  contentData,
  editorConfig,
  engineData,
  themeOptions,
  languageCode,
  onChange,
  onEditorReady,
  onOpenExternalMediaDialog,
  requestHeaders
}) => {
  const editorRef = React.createRef()
  const [editorLoaded, setEditorLoaded] = React.useState(false)
  const [editor, setEditor] = React.useState(null)

  React.useEffect(() => {
    if (!editorLoaded) {
      loadContentEditor(loaded => {
        setEditorLoaded(loaded)
      })
    }
  }, [editorLoaded])

  React.useEffect(() => {
    if (editorLoaded) {
      window.ContentEditor.prepareInitData(
        {
          languageCode: languageCode,
          baseRequestPath: engineData['api_options']['base_request_path'],
          assetPath: '',
          templateOptions: themeOptions,
          contentData: JSON.parse(contentData),
          requestHeaders: requestHeaders
        },
        function(err, init) {
          if (err) {
            throw err
          }

          try {
            const editor = new window.ContentEditor(init, {
              ...editorConfig,
              imageResampleTaskScheduler:
                window.contentEditorImageResampleTaskScheduler,
              openLinkDialog: null,
              openExternalMediaDialog: onOpenExternalMediaDialog
            })

            editor.node.style.setProperty(
              'height',
              `calc(100vh - ${headerHeight}px)`
            )
            editor.node.style.setProperty('width', `100%`)
            // editor.node.style.setProperty('minWidth', `${maxWidth}px`)

            if (editorRef && editorRef.current) {
              editorRef.current.appendChild(editor.node)
            }

            editor.afterInsert()
            setEditor(editor)

            if (onChange) {
              editor.onChange = onChange
            }

            // Handle change of content data from outside
            editor.replaceContentData = newContentData => {
              window.ContentEditor.prepareInitData(
                {
                  languageCode: languageCode,
                  baseRequestPath:
                    engineData['api_options']['base_request_path'],
                  assetPath: '',
                  templateOptions: themeOptions,
                  contentData: JSON.parse(newContentData),
                  requestHeaders: requestHeaders
                },
                function(err, init) {
                  if (err) {
                    throw err
                  }
                  editor.replaceInitData(init)
                }
              )
            }

            if (onEditorReady) {
              onEditorReady(editor /*, handleReset*/)
            }
          } catch {
            setEditor(null)
          }
        }
      )
    }
    // TODO: Implement destructing of editor, below might be what we need but disabled for now
    return () => {
      if (editor) {
        editor.beforeRemove() // Will just hide not also kill so can be used when toggling ie. settings to keep change history in editor
        editor.destroy() // Kill it completely (when exiting document screen
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorLoaded, setEditorLoaded])

  if (!editorLoaded && !editor) {
    return <div>loading...</div>
  }

  return <div className="Editor" ref={editorRef}></div>
}

export default ContentEditor

ContentEditor.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  engineData: PropTypes.object.isRequired,
  themeOptions: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired
}
