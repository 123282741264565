import './PrivateRoute.scss'
import React, { useState, useEffect, useContext } from 'react'

import { Redirect, Route } from 'react-router-dom'

import { OrganizationContext } from 'components/Contexts/OrganizationProvider'

import API from 'misc/api'

import Loader from 'components/Loader'

export const PrivateRoute = ({ render, ...rest }) => {
  const [state, setState] = useState({
    loading: true,
    isAuthenticated: false
  })

  const context = useContext(OrganizationContext)

  useEffect(() => {
    API.getCurrentOrganization()
      .then(({ data, status }) => {
        setState({
          ...state,
          loading: false,
          isAuthenticated: true
        })
        context.setOrganization(data)
      })
      .catch(e => {
        setState({
          ...state,
          loading: false
        })
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, isAuthenticated } = state

  if (loading) {
    return (
      <div className="PrivateRoute">
        <Loader size="medium" />
      </div>
    )
  }

  return (
    <Route
      {...rest}
      render={isAuthenticated ? render : () => <Redirect to="/login" />}
    />
  )
}

export default PrivateRoute
