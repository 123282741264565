import './EventCard.scss'
import React from 'react'
import { withTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Thumbnail from 'components/Thumbnail'
import Countdown from 'components/Countdown'
import RestoreEventModal from 'components/Modals/RestoreEventModal'
import StateIndicator from 'components/StateIndicator'

const IconUser = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <path
      d="M10.13125,10.2282328 C8.74375,10.2282328 7.61875,9.14719828 7.61875,7.82047414 
      C7.61875,6.48760776 8.74375,5.40657328 10.13125,5.40657328 C11.51875,5.40657328 
      12.64375,6.48760776 12.64375,7.82047414 C12.64375,9.14719828 11.51875,10.2282328 
      10.13125,10.2282328 Z M16.25625,16.4626078 C16.13125,13.9811422 14.7125,11.7945043 
      12.6125,10.793319 C13.55,10.0685345 14.1,8.98135776 14.1,7.82047414 C14.1,5.71368534 
      12.31875,4 10.13125,4 C7.94375,4 6.1625,5.71368534 6.1625,7.82047414 C6.1625,8.98135776 
      6.7125,10.0685345 7.64375,10.793319 C5.55,11.7945043 4.13125,13.9811422 4.00625,16.4626078 
      L4,16.6530172 L5.46875,16.6530172 L5.475,16.4810345 C5.625,13.7600216 7.66875,11.634806 
      10.13125,11.634806 C12.59375,11.634806 14.6375,13.7600216 14.7875,16.4810345 
      L14.79375,16.6530172 L16.26875,16.6530172 L16.25625,16.4626078 Z"
      fill="#000000"
    />
  </svg>
)

const IconMax = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <path
      d="M17.5,16.0560345 L2.5,16.0560345 C1.12125,16.0560345 0,14.9541164 
      0,13.5991379 L0,7.45689655 C0,6.1019181 1.12125,5 2.5,5 L17.5,5 
      C18.87875,5 20,6.1019181 20,7.45689655 L20,13.5991379 C20,14.9541164 
      18.87875,16.0560345 17.5,16.0560345 Z M2.5,6.22844828 C1.810625,6.22844828 
      1.25,6.77940733 1.25,7.45689655 L1.25,13.5991379 C1.25,14.2766272 
      1.810625,14.8275862 2.5,14.8275862 L17.5,14.8275862 
      C18.189375,14.8275862 18.75,14.2766272 18.75,13.5991379 
      L18.75,7.45689655 C18.75,6.77940733 18.189375,6.22844828 
      17.5,6.22844828 L2.5,6.22844828 Z M7.157,12.636403 L7.157,9.75139224 
      L6.001375,12.636403 L5.202,12.636403 L4.04575,9.78763147 L4.04575,12.636403 
      L3.108875,12.636403 L3.108875,8.28401078 L4.39575,8.28401078 L5.613875,11.2243017 
      L6.7695,8.28401078 L8.125125,8.28401078 L8.125125,12.636403 L7.157,12.636403 Z 
      M11.7390625,11.7522888 L10.0271875,11.7522888 L9.7090625,12.6361573 
      L8.6721875,12.6361573 L10.3396875,8.28376509 L11.4834375,8.28376509 
      L13.1321875,12.6361573 L12.0578125,12.6361573 L11.7390625,11.7522888 Z 
      M10.3521875,10.8684203 L11.4271875,10.8684203 L10.8959375,9.36418534 
      L10.3521875,10.8684203 Z M15.8141875,10.4695431 L17.2504375,12.6365259 
      L16.0510625,12.6365259 L15.1766875,11.2244246 L14.3085625,12.6365259 
      L13.1460625,12.6365259 L14.5773125,10.4572586 L13.1460625,8.28413362 
      L14.3335625,8.28413362 L15.2079375,9.69562069 L16.0829375,8.28413362 
      L17.2379375,8.28413362 L15.8141875,10.4695431 Z"
      fill="#000000"
    />
  </svg>
)

const IconCheck = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <path
      d="M15.4,6.1c-0.3-0.3-0.7-0.3-1,0l-6.2,6.2L5.6,9.7c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l3.1,3.1
        c0.3,0.3,0.7,0.3,1,0l6.7-6.7C15.7,6.9,15.7,6.4,15.4,6.1z"
    />
  </svg>
)

const EventCardSummary = ({ nrOfGuests, guestMaxLimit, checkins }) => (
  <div className="EventCardSummary">
    <div className="guests">
      <IconUser />
      <span>{nrOfGuests}</span>
    </div>
    <div className="max">
      <IconMax />
      <span>{guestMaxLimit ? guestMaxLimit : '-'}</span>
    </div>
    <div className="checkedIn">
      <IconCheck />
      <span>{checkins}</span>
    </div>
  </div>
)

class EventCard extends React.PureComponent {
  state = {
    restoreModalOpen: false
  }

  setRestoreModalOpen = restoreModalOpen => this.setState({ restoreModalOpen })

  formatDate = date => moment.parseZone(date).format('Do MMMM YYYY')

  render() {
    const {
      t,
      to,
      event,
      src,
      removeEvent,
      fetchEvents,
      delete_grace_period = 300,
      summary
    } = this.props

    const {
      name,
      start_time: startTime,
      nr_of_guests: nrOfGuests,
      event: id,
      guest_max_limit: guestMaxLimit,
      checkins,
      deleted_at: deletedAt,
      state
    } = event

    const deletionDate = deletedAt
      ? moment(deletedAt).add(delete_grace_period, 'seconds')
      : null

    const EventCardOuter = ({ children }) =>
      deletionDate ? (
        <button
          type="button"
          className="EventCard EventCardButton"
          onClick={() => this.setRestoreModalOpen(true)}>
          {children}
        </button>
      ) : (
        <Link to={to} className="EventCard">
          {children}
        </Link>
      )

    return (
      <>
        <EventCardOuter>
          <div className="eventThumbnail">
            <div
              className={clsx('eventThumbnailInner', {
                hasSummary: Boolean(summary)
              })}>
              <Thumbnail src={src} />
              {deletionDate ? (
                <Countdown
                  className="EventCardDeleted"
                  to={deletionDate.milliseconds(0)}
                  label={t('will be removed in')}
                  onEnd={() => {
                    removeEvent(id)
                    this.setRestoreModalOpen(false)
                  }}
                />
              ) : null}
              {summary ? (
                <StateIndicator state={state} className="EventCardStatus" />
              ) : null}
            </div>
            {summary && (
              <EventCardSummary
                nrOfGuests={nrOfGuests}
                guestMaxLimit={guestMaxLimit}
                checkins={checkins}
              />
            )}
          </div>
          <div className="meta">
            <h3 className="title">{name}</h3>
            <div className="date">{this.formatDate(startTime)}</div>
          </div>
        </EventCardOuter>

        {this.state.restoreModalOpen ? (
          <RestoreEventModal
            handleClose={() => this.setRestoreModalOpen(false)}
            event={event}
            onRestore={() => {
              fetchEvents()
              this.setRestoreModalOpen(false)
            }}
          />
        ) : null}
      </>
    )
  }
}

export default withTranslation()(EventCard)

EventCard.propTypes = {
  to: PropTypes.string.isRequired,
  summary: PropTypes.bool,
  src: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired
}
