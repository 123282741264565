import React from 'react'
import clsx from 'clsx'
import moment from 'moment-timezone'
import useCountdown from 'misc/useCountdown'

import Thumbnail from 'components/Thumbnail'
import StateIndicator from 'components/StateIndicator'

import { useTranslation } from 'react-i18next'

const Date = ({ date, format = 'Do MMMM YYYY', label }) => {
  const dateObj = moment.parseZone(date)

  return (
    <time dateTime={dateObj.format()} className="Date">
      {label ? <strong>{label}</strong> : null}
      {dateObj.format(format)}
    </time>
  )
}

const NumberCell = ({ number }) => <div className="NumberCell">{number}</div>

const DeleteCountdown = ({ to, removeEvent, id }) => {
  const { t } = useTranslation()

  const [timeLeft] = useCountdown(to, () => {
    removeEvent(id)
  })

  return (
    <div className="EventInfoDeleteLabel">
      {t('will be removed in')} {timeLeft.humanize()}
    </div>
  )
}

const EventInfo = ({
  deletionDate,
  id,
  title,
  thumbnail,
  created,
  updated,
  removeEvent
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('EventInfo', { 'is-deleted': deletionDate })}>
      <Thumbnail
        width={94}
        height={64}
        size={64}
        src={thumbnail}
        className="EventInfoThumbnail"
      />
      <div className="meta">
        <div className="title">{t(title)}</div>
        {deletionDate ? (
          <DeleteCountdown
            to={deletionDate.milliseconds(0)}
            removeEvent={removeEvent}
            id={id}
          />
        ) : (
          <Date label={`${t('created')}:`} date={created} />
        )}
        <Date label={`${t('last change')}:`} date={updated} />
      </div>
    </div>
  )
}

const Dates = ({ startTime, endTime }) => (
  <div className="Dates">
    <div className="content">
      <Date date={startTime} format="YYYY-MM-DD, HH:mm" />
      <Date date={endTime} format="YYYY-MM-DD, HH:mm" />
    </div>
  </div>
)

const StateCell = props => (
  <div className="StateCell" data-state={props.original.state}>
    <StateIndicator
      state={props.original.state}
      className="StateCellIndicator"
    />
  </div>
)

const EventInfoColumn = props => ({
  Header: 'event info',
  accessor: 'name',
  minWidth: 200,
  Cell: row => {
    const {
      name,
      preview_image,
      created_at,
      last_modified,
      deleted_at,
      delete_grace_period = 300,
      event
    } = row.original

    return (
      <EventInfo
        {...props}
        title={name}
        thumbnail={preview_image}
        id={event}
        created={created_at}
        updated={last_modified}
        deletionDate={
          deleted_at
            ? moment(deleted_at).add(delete_grace_period, 'seconds')
            : null
        }
      />
    )
  }
})

const DateColumn = () => ({
  Header: 'date',
  id: 'date',
  accessor: 'start_time',
  Cell: row => {
    const { start_time, end_time } = row.original
    return <Dates startTime={start_time} endTime={end_time} />
  }
})

const GuestCountColumn = () => ({
  Header: 'guests',
  id: 'guestsCount',
  accessor: 'nr_of_guests',
  maxWidth: 150,
  Cell: row => {
    const { nr_of_guests } = row.original
    return <NumberCell number={nr_of_guests} />
  }
})

const GuestLimitColumn = () => ({
  Header: 'guest limit',
  id: 'guestsLimit',
  accessor: 'guest_max_limit',
  maxWidth: 150,
  Cell: row => {
    const { guest_max_limit } = row.original
    return <NumberCell number={guest_max_limit} />
  }
})

const CheckedInColumn = () => ({
  Header: 'checked in',
  id: 'guestsCheckedIn',
  accessor: 'checkins',
  maxWidth: 150,
  Cell: row => {
    const { checkins } = row.original
    return <NumberCell number={checkins} />
  }
})

const StateColumn = () => ({
  Header: 'state',
  id: 'eventStatus',
  accessor: 'state',
  maxWidth: 200,
  Cell: StateCell
})

export const initialStateConfig = {
  table: {
    pageSize: 10,
    page: 0,
    pageSizeOptions: [5, 10, 20, 25, 50, 100, 'ALL'],
    minRows: undefined
  },
  loading: false
}

export const activeColumns = props =>
  [
    EventInfoColumn,
    DateColumn,
    StateColumn,
    GuestCountColumn,
    GuestLimitColumn,
    CheckedInColumn
  ].map(fn => fn(props))

export const draftsColumns = props =>
  [EventInfoColumn, DateColumn, GuestLimitColumn].map(fn => fn(props))
