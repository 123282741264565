import React, { createContext, useContext, useState } from 'react'
import axios from 'axios'
import API from 'misc/api'

export const AuthContext = createContext(null)

export const useAuth = () => useContext(AuthContext)

const setAuthorizationHeader = resp => {
  const { token_type, token } = resp.data

  if (token_type && token) {
    axios.defaults.headers.common['Authorization'] = `${token_type} ${token}`
  }

  return resp
}

const AuthProvider = props => {
  const [bearerToken, setBearerToken] = useState(null)

  const fetchBearerToken = params =>
    API.authorize(params)
      .then(setAuthorizationHeader)
      .then(resp => {
        setBearerToken(resp.data.token)
        return resp
      })
      .catch(console.error)

  return (
    <AuthContext.Provider
      {...props}
      value={{ bearerToken, fetchBearerToken }}
    />
  )
}

export default AuthProvider
