import { useState, useEffect } from 'react'
import API from 'misc/api'

const noop = () => {}

export const useCheckIn = (event, updateCallback = noop) => {
  const [parties, setParties] = useState([])
  const [orgId, setOrgId] = useState(null)
  const [checkinUrl, setCheckinUrl] = useState(null)

  const fetchOrgId = () =>
    API.getCurrentOrganization()
      .then(({ data, status }) => {
        if (status === 200) {
          setOrgId(data.id)
        }
      })
      .catch(error => console.error(error))

  const fetchParties = () =>
    API.getAllParties(event.event)
      .then(({ data, status }) => {
        if (status === 200) {
          data.sort((current, next) => (current.added < next.added ? -1 : 1))
          setParties(data)
          updateCallback()
        }
      })
      .catch(error => console.error(error))

  const updateParty = party =>
    API.putParty(party.guestlist, party.id, party)
      .then(({ status }) => {
        if (status === 200) {
          fetchParties()
        }
      })
      .catch(error => console.error(error))

  const cancelParty = party =>
    API.cancelParty(party.guestlist, party.id, party)
      .then(({ status }) => {
        if (status === 200) {
          fetchParties()
        }
      })
      .catch(error => console.error(error))

  const fetchCheckinUrl = () =>
    orgId &&
    API.getCheckInAppUrlGuests(event.event)
      .then(({ data, status }) => {
        if (status === 200) {
          setCheckinUrl(data.relative)
        }
      })
      .catch(error => console.error(error))

  useEffect(() => {
    if (event) {
      fetchParties()
      fetchOrgId()
    }
  }, [event?.event]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    event && fetchCheckinUrl()
  }, [orgId]) // eslint-disable-line react-hooks/exhaustive-deps

  const fields = event && event.fields

  const state = { parties, checkinUrl, fields }
  const handlers = { updateParty, cancelParty }
  return [state, handlers]
}
