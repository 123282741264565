import './FileInput.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useRefWithCallback } from 'misc/hooks'

const UploadIcon = () => (
  <svg width="100" height="65" viewBox="0 0 100 65">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.7316 26.6294C73.7269 26.825 73.7201 27.02 73.7112 27.2144C75.8253 26.429 78.1125 26 80.5 26C91.2695 26 100 34.7305 100 45.5C100 56.2695 91.2695 65 80.5 65C80.4043 65 80.3086 64.9993 80.2131 64.9978C80.142 64.9968 80.0709 64.9954 80 64.9937V65H50.4232V35.0049L62.5533 47.2871C63.5706 48.3171 65.2198 48.3171 66.2371 47.2871C67.2543 46.2571 67.2543 44.5872 66.2371 43.5571L49.6603 26.7725C48.6431 25.7424 46.9938 25.7424 45.9766 26.7725L29.3998 43.5571C28.3826 44.5872 28.3826 46.2571 29.3998 47.2871C30.417 48.3171 32.0663 48.3171 33.0835 47.2871L45.2136 35.0049V65H23.5H23V64.9949C10.2522 64.7288 0 54.3115 0 41.5C0 28.6914 10.2476 18.2761 22.9913 18.0054C26.3639 7.55762 36.1689 0 47.7391 0C62.0985 0 73.7391 11.6406 73.7391 26C73.7391 26.2104 73.7366 26.4202 73.7316 26.6294Z"
    />
  </svg>
)

const FileInput = ({ file, accept, maxSize, onFileChange }) => {
  const { t } = useTranslation()

  const [previewImage, setPreviewImage] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (file) {
      previewFile(file)
    }
  }, [file, setPreviewImage])

  const handeFileInputChange = event => {
    const files = Array.from(event.target.files)
    if (files.length > 0) {
      changeFile(files[0])
    }
  }

  const onDocumentDragEnter = event => {
    event.target.classList.add('dragover')
    event.preventDefault()
  }

  const onDocumentDragLeave = event => {
    event.target.classList.remove('dragover')
    event.preventDefault()
  }

  const onDocumentDragOver = event => {
    event.preventDefault()
  }

  const onDocumentDrop = event => {
    event.target.classList.remove('dragover')
    event.preventDefault()

    const dt = event.dataTransfer
    const files = dt.files

    if (files.length > 0) {
      changeFile(files[0])
    }
  }

  const changeFile = file => {
    if (!accept.includes(file.type)) {
      setError(t('file.formatError'))
      return
    }

    if (maxSize && maxSize < file.size) {
      setError(t('file.sizeError'))
      return
    }

    setError(null)
    previewFile(file)
    onFileChange(file)
  }

  const previewFile = file => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPreviewImage(reader.result)
    }
  }

  const dropZoneRef = useRefWithCallback(
    node => {
      node.addEventListener('dragenter', onDocumentDragEnter)
      node.addEventListener('dragleave', onDocumentDragLeave)
      node.addEventListener('dragover', onDocumentDragOver)
      node.addEventListener('drop', onDocumentDrop)
    },
    node => {
      node.removeEventListener('dragover', onDocumentDragEnter)
      node.removeEventListener('dragleave', onDocumentDragLeave)
      node.addEventListener('dragover', onDocumentDragOver)
      node.removeEventListener('drop', onDocumentDrop)
    }
  )

  return (
    <label
      className={clsx(['FileInput', { 'with-file': !!previewImage }])}
      ref={dropZoneRef}>
      <div className="content">
        <UploadIcon />
        <span className="text">{t('file.drop')}</span>
        {error && <span className="FileInputError">{error}</span>}
      </div>

      {previewImage && <img src={previewImage} alt="Preview header" />}
      <input
        type="file"
        accept={accept.join(',')}
        onChange={handeFileInputChange}
      />
    </label>
  )
}

export default FileInput

FileInput.propTypes = {
  file: PropTypes.object,
  accept: PropTypes.array.isRequired,
  onFileChange: PropTypes.func.isRequired
}
