import React from 'react'

const ExportIcon = props => (
  <svg
    {...props}
    height="18"
    viewBox="0 0 18 18"
    width="18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m8 0-.00085425 8h4.65770845l-5.6568542 5.6568542-5.65685425-5.6568542h4.656l.00085425-8z" />
    <path d="m0 16h14v2h-14z" />
  </svg>
)

export default ExportIcon
