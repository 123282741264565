import './CopyEmailsModal.scss'
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { useExport } from 'misc/api'
import { useTranslation } from 'react-i18next'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'
import Radiobutton from 'components/Inputs/Radiobutton'
import Text from 'components/Inputs/Text'

const EmailsContainer = ({ exportData, containerRef }) => {
  return (
    <Text
      inputRef={containerRef}
      value={exportData.map(data => data['E-mail']).join(', ')}
      readOnly={true}
    />
  )
}

const CopyEmailsModal = ({ eventId, handleCloseModal }) => {
  const [exportData] = useExport(eventId)
  const [currentEmails, setCurrentEmails] = useState([])
  const [isCopied, setIsCopied] = useState(false)
  const containerRef = useRef(null)
  const { t } = useTranslation()

  const handleExportChange = e => {
    const exportMethods = {
      copyAll: exportData.all,
      copyCheckedIn: exportData.checkedIn,
      copyNotCheckedIn: exportData.notCheckedIn
    }
    setCurrentEmails(exportMethods[e.target.id])
  }

  const copyToClipboard = e => {
    containerRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setIsCopied(true)
  }

  // Has support in all major browsers but just to make sure
  // in case a too old browser is used
  const copyIsSupported =
    currentEmails.length > 0 && document.queryCommandSupported('copy')

  if (isCopied) {
    setTimeout(() => setIsCopied(false), 1500)
  }

  return (
    <Modal modalName="CopyEmailsModal">
      <Header>{t('copy email adresses')}</Header>
      <Content>
        <p>{t('copyEmailsText')}</p>
        <div className="inputs">
          <Radiobutton
            id="copyAll"
            name="copyRadio"
            disabled={exportData.all.length === 0}
            value={t('copy guests', { value: exportData.all.length })}
            handleChange={handleExportChange}
          />
          <Radiobutton
            id="copyCheckedIn"
            name="copyRadio"
            disabled={exportData.checkedIn.length === 0}
            value={t('copy checked in', {
              value: exportData.checkedIn.length
            })}
            handleChange={handleExportChange}
          />
          <Radiobutton
            id="copyNotCheckedIn"
            name="copyRadio"
            disabled={exportData.notCheckedIn.length === 0}
            value={t('copy not checked in', {
              value: exportData.notCheckedIn.length
            })}
            handleChange={handleExportChange}
          />
        </div>
        <EmailsContainer
          exportData={currentEmails}
          containerRef={containerRef}
        />
      </Content>
      <Actions
        leftActions={
          <Button type="secondary" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        }
        rightActions={
          <Button
            type="primary"
            onClick={copyToClipboard}
            disabled={!copyIsSupported}>
            {isCopied ? t('copied') : t('copy')}
          </Button>
        }
      />
    </Modal>
  )
}

export default CopyEmailsModal

CopyEmailsModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired
}
