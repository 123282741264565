import './StatusIndicator.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const StatusIndicator = ({ className, state }) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('StatusIndicator', className)} data-state={state}>
      {t(`eventCard.${state.toLowerCase()}`)}
    </div>
  )
}

export default StatusIndicator
