import './Buttons.scss'
import React from 'react'
import clsx from 'clsx'

import Button from 'components/Button'
import LoadingDots from 'components/LoadingDots'

import { useTranslation } from 'react-i18next'

const CancelIcon = () =>
  // prettier-ignore
  <svg
    className="CancelIcon"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <g>
      <path d="M10,8.67768595 L15.9504132,2.72727273 L17.2727273,4.04958678 L11.322314,10 L17.2727273,15.9504132 L15.9504132,17.2727273 L10,11.322314 L4.04958678,17.2727273 L2.72727273,15.9504132 L8.67768595,10 L2.72727273,4.04958678 L4.04958678,2.72727273 L10,8.67768595 Z"/>
    </g>
  </svg>

export const SaveButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button {...props} type="primary" className={clsx('SaveButton', className)}>
      {loading ? <LoadingDots /> : t('save')}
    </Button>
  )
}

export const SendButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button {...props} type="success" className={clsx('SendButton', className)}>
      {loading ? <LoadingDots /> : t('sms.edit.buttons.send')}
    </Button>
  )
}

export const ScheduleButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      type="success"
      className={clsx('ScheduleButton', className)}>
      {loading ? <LoadingDots /> : t('sms.edit.buttons.schedule')}
    </Button>
  )
}

export const CloseButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button {...props} className={clsx('CloseButton', className)}>
      {t('close')}
    </Button>
  )
}

export const ResetChangesButton = ({ className, ...props }) => (
  <Button
    {...props}
    type="header"
    className={clsx('ResetChangesButton', className)}>
    <CancelIcon />
  </Button>
)

export const CancelButton = ({ className, loading, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      type="warning"
      className={clsx('CancelButton', className)}>
      {loading ? <LoadingDots /> : t('cancel')}
    </Button>
  )
}
