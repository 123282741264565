import './EditForm.scss'
import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import Textarea from 'components/Inputs/Textarea'
import FormFactory from 'components/FormFactory'
import PickBackgroundModal from 'components/Modals/PickBackgroundModal'

import { useTranslation } from 'react-i18next'

const FormDefault = ({ form, handleInputBlur, handleChange, title }) => {
  const { t } = useTranslation()

  return (
    <div className="FormDefault">
      <InputContainer id="header" label={t('header')}>
        <Text
          placeholder={t('header')}
          id="header"
          value={form.header || ''}
          handleBlur={handleInputBlur}
          handleChange={handleChange(title)}
        />
      </InputContainer>
      <InputContainer id="content" label={t('text')}>
        <Textarea
          placeholder={t('add text')}
          id="content"
          value={form.content || ''}
          handleBlur={handleInputBlur}
          handleChange={handleChange(title)}
        />
      </InputContainer>
    </div>
  )
}

const EditForm = ({
  title,
  currentState: { formFactorys, max_number_of_followers, data },
  backgrounds,
  handleChange,
  handleBackgroundChange,
  handleDragEnd,
  compareHistoryStates,
  updateHistory,
  backgroundModalIsOpen,
  setBackgroundModalIsOpen,
  ...props
}) => {
  const form = data[title] || {}

  // Drag and drop events
  const onBeforeDragStart = e =>
    compareHistoryStates() ? false : updateHistory()

  // Render methods
  const renderFactories = () =>
    formFactorys.map((form, i) => (
      <FormFactory
        {...props}
        key={i}
        id={`factory-${i}`}
        factoryId={i}
        title={form.title}
        fields={form.fields}
        isPrimaryGuest={i === 0}
        maxNumberOfFollowers={max_number_of_followers}
      />
    ))

  return (
    <div className="EditForm">
      <FormDefault
        handleInputBlur={props.handleInputBlur}
        handleChange={handleChange}
        form={form}
        title={title}
      />

      {backgroundModalIsOpen && (
        <PickBackgroundModal
          title={title}
          backgrounds={backgrounds}
          selected={form.background}
          handleClose={() => setBackgroundModalIsOpen(false)}
          handlePick={background => {
            handleBackgroundChange(background)
            setBackgroundModalIsOpen(false)
          }}
        />
      )}

      {title === 'signup' && (
        <DragDropContext
          onBeforeDragStart={onBeforeDragStart}
          onDragEnd={handleDragEnd}>
          {renderFactories()}
        </DragDropContext>
      )}
    </div>
  )
}

export default EditForm
