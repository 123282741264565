import React from 'react'
import './Notifications.scss'
import { OrganizationContext } from '../Contexts/OrganizationProvider'

const CrossIcon = () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g
      id="Buttons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="Button-states" transform="translate(-578.000000, -291.000000)">
        <g id="Group-18" transform="translate(48.000000, 50.000000)">
          <rect
            id="Rectangle"
            fill="transparent"
            x="0"
            y="0"
            width="1133"
            height="490"
          />
          <g id="Group-12-Copy-4" transform="translate(514.000000, 226.000000)">
            <rect
              id="Rectangle"
              fill="transparent"
              x="0"
              y="0"
              width="46"
              height="44"
              rx="4"
            />
            <path
              d="M16.2928932,15.2928932 C16.6834175,14.9023689 17.3165825,14.9023689 17.7071068,15.2928932 L17.7071068,15.2928932 L23,20.585 L28.2928932,15.2928932 C28.6533772,14.9324093 29.2206082,14.9046797 29.6128994,15.2097046 L29.7071068,15.2928932 C30.0976311,15.6834175 30.0976311,16.3165825 29.7071068,16.7071068 L29.7071068,16.7071068 L24.415,22 L29.7071068,27.2928932 C30.0675907,27.6533772 30.0953203,28.2206082 29.7902954,28.6128994 L29.7071068,28.7071068 C29.3165825,29.0976311 28.6834175,29.0976311 28.2928932,28.7071068 L28.2928932,28.7071068 L23,23.415 L17.7071068,28.7071068 C17.3466228,29.0675907 16.7793918,29.0953203 16.3871006,28.7902954 L16.2928932,28.7071068 C15.9023689,28.3165825 15.9023689,27.6834175 16.2928932,27.2928932 L16.2928932,27.2928932 L21.585,22 L16.2928932,16.7071068 C15.9324093,16.3466228 15.9046797,15.7793918 16.2097046,15.3871006 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const WarningIconWarningColor = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width="18px"
    height="18px">
    <path
      d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm1.33,6.19L11.9,12.6H10.16L9.73,6.19ZM11,16.11a1.28,1.28,0,0,1-1.34-1.27A1.26,1.26,0,0,1,11,13.59a1.26,1.26,0,0,1,1.35,1.25A1.29,1.29,0,0,1,11,16.11Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      d="M12.38,14.84A1.29,1.29,0,0,1,11,16.11a1.28,1.28,0,0,1-1.34-1.27A1.26,1.26,0,0,1,11,13.59,1.26,1.26,0,0,1,12.38,14.84Z"
      fill="white"
      fillRule="evenodd"
    />
    <polygon
      points="10.16 12.6 9.73 6.19 12.33 6.19 11.9 12.6 10.16 12.6"
      fill="white"
      fillRule="evenodd"
    />
    <g style={{ isolation: 'isolate' }}>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M9.73,6.19l.43,6.41H11.9l.43-6.41Zm1.3,7.4a1.26,1.26,0,0,0-1.34,1.25A1.28,1.28,0,0,0,11,16.11a1.29,1.29,0,0,0,1.35-1.27A1.26,1.26,0,0,0,11,13.59Z"
          fill="red"
        />
      </g>
    </g>
  </svg>
)

const WarningIconSecondaryColor = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width="18px"
    height="18px">
    <path
      d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm1.33,6.19L11.9,12.6H10.16L9.73,6.19ZM11,16.11a1.28,1.28,0,0,1-1.34-1.27A1.26,1.26,0,0,1,11,13.59a1.26,1.26,0,0,1,1.35,1.25A1.29,1.29,0,0,1,11,16.11Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      d="M12.38,14.84A1.29,1.29,0,0,1,11,16.11a1.28,1.28,0,0,1-1.34-1.27A1.26,1.26,0,0,1,11,13.59,1.26,1.26,0,0,1,12.38,14.84Z"
      fill="white"
      fillRule="evenodd"
    />
    <polygon
      points="10.16 12.6 9.73 6.19 12.33 6.19 11.9 12.6 10.16 12.6"
      fill="white"
      fillRule="evenodd"
    />
    <g style={{ isolation: 'isolate' }}>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M9.73,6.19l.43,6.41H11.9l.43-6.41Zm1.3,7.4a1.26,1.26,0,0,0-1.34,1.25A1.28,1.28,0,0,0,11,16.11a1.29,1.29,0,0,0,1.35-1.27A1.26,1.26,0,0,0,11,13.59Z"
          fill="#224b78"
        />
      </g>
    </g>
  </svg>
)

export default class Notifications extends React.Component {
  static contextType = OrganizationContext

  hideNotification(id) {
    let { hiddenNotifications, setHiddenNotifications } = this.context

    if (hiddenNotifications.indexOf(id) === -1) {
      const updatedHiddenNotifications = [...hiddenNotifications, id]
      setHiddenNotifications(updatedHiddenNotifications)
    }
  }

  getDisplayableNotifications() {
    let { notifications, hiddenNotifications } = this.context
    return notifications.filter(
      notification => hiddenNotifications.indexOf(notification.id) === -1
    )
  }

  render() {
    let notificationsToDisplay = this.getDisplayableNotifications()
    if (!notificationsToDisplay) {
      return null
    }

    return (
      <div className="NotificationsWrapper">
        {notificationsToDisplay &&
          notificationsToDisplay.map(notification => {
            if (notification.type === 'warning') {
              return (
                <section key={notification.id} className="Notifications">
                  <article className="NotificationWarning">
                    <div className="NotificationIcon">
                      <WarningIconWarningColor />
                    </div>
                    <div className="NotificationMessage">
                      {notification.message}
                    </div>
                    <div
                      className="NotificationDismiss"
                      onClick={this.hideNotification.bind(
                        this,
                        notification.id
                      )}>
                      <CrossIcon />
                    </div>
                  </article>
                </section>
              )
            } else if (notification.type === 'info') {
              return (
                <section key={notification.id} className="Notifications">
                  <article className="NotificationInfo">
                    <div className="NotificationIcon">
                      <WarningIconSecondaryColor />
                    </div>
                    <div className="NotificationMessage">
                      {notification.message}
                    </div>
                    <div
                      className="NotificationDismiss"
                      onClick={this.hideNotification.bind(
                        this,
                        notification.id
                      )}>
                      <CrossIcon />
                    </div>
                  </article>
                </section>
              )
            } else {
              return (
                <section key={notification.id} className="Notifications">
                  <article className="Notification">
                    <div className="NotificationIcon">
                      <WarningIconSecondaryColor />
                    </div>
                    <div className="NotificationMessage">
                      {notification.message}
                    </div>
                    <div
                      className="NotificationDismiss"
                      onClick={this.hideNotification.bind(
                        this,
                        notification.id
                      )}>
                      <CrossIcon />
                    </div>
                  </article>
                </section>
              )
            }
          })}
      </div>
    )
  }
}
