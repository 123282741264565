import React, { createContext, useState, useEffect } from 'react'
import API from 'misc/api'
import { setLanguage, setTimezone } from '../../../i18n.js'

export const OrganizationContext = createContext(null)

const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null)
  const [settings, setSettings] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [hiddenNotifications, setHiddenNotifications] = useState([])

  const fetchSettings = () =>
    API.getOrganizationSettings(organization.id)
      .then(({ data, status }) => {
        if (status === 200) {
          setTimezone(data.time_preferences.timezone)
          setLanguage(data.lang_preferences.language)
          setSettings(data)
        }
      })
      .catch(error => console.error(error))

  const updateSetting = (key, data) =>
    API.putOrganizationSetting(organization.id, key, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setSettings({
            ...settings,
            key: data
          })
        }
      })
      .catch(error => console.log(error))

  const fetchNotifications = () =>
    API.getOrganizationNotifications()
      .then(({ data, status }) => {
        if (status === 200) {
          setNotifications(data.notifications)
        }
      })
      .catch(error => console.error(error))

  useEffect(() => {
    organization && fetchSettings() && fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        settings,
        updateSetting,
        notifications,
        setNotifications,
        hiddenNotifications,
        setHiddenNotifications
      }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
