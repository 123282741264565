import './Radiobutton.scss'
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

export const Radiobutton = ({
  className,
  disabled,
  id,
  name,
  handleChange,
  value,
  required,
  checked
}) => (
  <div className={clsx('Radiobutton', className, { isDisabled: disabled })}>
    <input
      id={id}
      type="radio"
      name={name}
      disabled={disabled}
      onChange={handleChange}
      required={required}
      checked={checked}
    />
    <label htmlFor={id}>
      <div className="box">
        <div className="inner" />
      </div>
      <span>{value}</span>
    </label>
  </div>
)

export default Radiobutton

Radiobutton.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  checked: PropTypes.bool
}
