import React, { createContext } from 'react'
import { useExtendedLandingPages } from 'misc/api'

export const ExtendedLandingPagesContext = createContext()

const ExtendedLandingPagesProvider = ({ children, eventId }) => {
  const [
    { website, page, hasPageChanges, newContentPage, isLoading },
    {
      fetchWebsite,
      fetchPage,
      handleChangePage,
      savePage,
      resetPageChanges,
      saveWebsitePagesSettings,
      setWebsiteHeader
    }
  ] = useExtendedLandingPages(eventId)

  return (
    <ExtendedLandingPagesContext.Provider
      value={{
        website,
        page,
        hasPageChanges,
        newContentPage,
        isLoading,

        fetchWebsite,
        fetchPage,
        handleChangePage,
        savePage,
        resetPageChanges,
        saveWebsitePagesSettings,
        setWebsiteHeader
      }}>
      {children}
    </ExtendedLandingPagesContext.Provider>
  )
}

export default ExtendedLandingPagesProvider
