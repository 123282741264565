import './ExtendedLandingPage.scss'

import React from 'react'
import { debounce, isEqual } from 'lodash'

import { getCancelSource } from 'misc/api/util'

import ContentEditor from 'components/ContentEditor'
import { useAuth } from 'components/Contexts/AuthProvider'
import { ExtendedLandingPagesContext } from 'components/Contexts/ExtendedLandingPagesProdiver'
import EmbedVideoModal from 'components/ExtendedLandingPages/EmbedVideoModal'

const initialContentEditorProps = {
  contentData: '{}',
  editorConfig: {},
  engineData: {
    api_options: {
      base_request_path: ''
    }
  },
  themeOptions: {},
  languageCode: 'en'
}

const ExtendedLandingPage = ({ pageId }) => {
  const { bearerToken } = useAuth()
  const {
    page,
    newContentPage,
    fetchPage,
    handleChangePage
  } = React.useContext(ExtendedLandingPagesContext)
  const [contentEditorProps, setContentEditorProps] = React.useState(
    initialContentEditorProps
  )
  const [contentEditorHeaders, setContentEditorHeaders] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [isEditorLoaded, setIsEditorLoaded] = React.useState(false)
  const editorRef = React.useRef()
  const [modalVideo, setModalVideo] = React.useState(null)

  React.useEffect(() => {
    if (bearerToken) {
      setContentEditorHeaders([
        { key: 'Authorization', value: `Bearer ${bearerToken}` }
      ])
    }
  }, [bearerToken])

  React.useEffect(() => {
    const cancelSource = getCancelSource()

    async function fetch() {
      setLoading(true)
      setIsEditorLoaded(false)
      editorRef.current = null

      try {
        await fetchPage(pageId, true, cancelSource.token)
      } catch {}

      setLoading(false)
    }
    fetch()

    return cancelSource.cancel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId])

  React.useEffect(() => {
    if (page) {
      let contentEditor = page.content_editor

      if (isEditorLoaded && editorRef.current) {
        // Fix because content editor cannot handle template change
        if (
          isEqual(
            editorRef.current.lastTemplateOptions.templateOptions,
            page.content_editor.themeOptions
          )
        ) {
          editorRef.current.replaceContentData(page.content_editor.contentData)
        } else {
          setLoading(true)
          setIsEditorLoaded(false)
          editorRef.current = null

          if (newContentPage) {
            contentEditor.contentData = JSON.stringify(newContentPage)
          }

          setTimeout(() => setLoading(false))
        }
      }

      setContentEditorProps(contentEditor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const handleOnChange = debounce(() => {
    const content = editorRef.current.getSerializedData()
    handleChangePage(content)

    if (editorRef.current?.isUploading()) {
      waitForEditorImageUpload()
    }
  }, 250)

  const waitForEditorImageUpload = () => {
    setTimeout(() => {
      if (!editorRef.current) {
        return
      }

      if (editorRef.current.isUploading()) {
        waitForEditorImageUpload()
      } else {
        const content = editorRef.current.getSerializedData()
        handleChangePage(content)
      }
    }, 100)
  }

  const handleOnEditorReady = editorInstance => {
    editorRef.current = editorInstance
    setIsEditorLoaded(true)
  }

  const handleOnOpenExternalMediaDialog = (done, cancel, data) => {
    setModalVideo({
      video: data,
      handleSubmitModal: embedVideo => {
        done(
          embedVideo
            ? {
                uri: embedVideo.uri,
                aspectRatio: embedVideo.aspectRatio
              }
            : null
        )
        setModalVideo(null)
      },
      handleCloseModal: () => {
        cancel()
        setModalVideo(null)
      }
    })
  }

  return (
    <div className="ExtendedLandingPage" data-loaded={isEditorLoaded}>
      <div className="loading" />

      {!loading && (
        <ContentEditor
          {...contentEditorProps}
          requestHeaders={contentEditorHeaders}
          onChange={handleOnChange}
          onEditorReady={handleOnEditorReady}
          onOpenExternalMediaDialog={handleOnOpenExternalMediaDialog}
        />
      )}

      {modalVideo && <EmbedVideoModal {...modalVideo} />}
    </div>
  )
}
export default ExtendedLandingPage
