import './LoadingDots.scss'
import React from 'react'

export const LoadingDots = () => (
  <div className="LoadingDots">
    <svg width="36" height="8" xmlns="http://www.w3.org/2000/svg">
      <circle className="LoadingDot" cx="4" cy="4" r="4" />
      <circle className="LoadingDot" cx="18" cy="4" r="4" />
      <circle className="LoadingDot" cx="32" cy="4" r="4" />
    </svg>
  </div>
)

export default LoadingDots
