import './FieldComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'

import { renderIcon } from 'components/FormFactory/Icons'

import Text from 'components/Inputs/Text'
import OptionsArea from 'components/Inputs/OptionsArea'
import Checkbox from 'components/Inputs/Checkbox'

import { useTranslation } from 'react-i18next'

const isMultiChoice = field =>
  ['SELECT_MULTIPLE_CHOICE', 'SELECT_SINGLE_CHOICE'].includes(field.type)

const getPlaceholder = (fieldType, visualPlacing) => {
  if (fieldType === 'SELECT_MULTIPLE_CHOICE' && visualPlacing === 'BOOL') {
    return 'multiple selection headline'
  }
  const placeholderMap = {
    TEXT: 'textfield',
    FIRST_NAME: 'first name',
    LAST_NAME: 'last name',
    COMPANY_NAME: 'company name',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE_NUMBER: 'phone number',
    BOOL: 'checkbox headline',
    SELECT_BOX: 'dropdown headline',
    SELECT_MULTIPLE_BOX: 'multiple selection headline',
    RADIO_SELECTION: 'radio selection headline'
  }

  return placeholderMap[visualPlacing]
}

const FieldComponent = ({
  field,
  factoryId,
  fieldIndex,
  handleFactoryFieldChange,
  handleFactoryFieldRequiredChange,
  handleFactoryFieldTextareaChange,
  handleFocus,
  handleBlur,
  active,
  dragging,
  fieldRef
}) => {
  const { t } = useTranslation()

  const id = `${factoryId}-${fieldIndex}-${field.key}`

  const handleCheckboxChange = event =>
    handleFactoryFieldRequiredChange({
      checked: event.target.checked,
      factoryId,
      fieldIndex
    })

  const handleInputChange = event =>
    handleFactoryFieldChange({
      value: event.target.value,
      factoryId,
      fieldIndex
    })

  const handleOptionsChange = event =>
    handleFactoryFieldTextareaChange({
      fieldOptionsList: event.target.value
        .split('\n')
        .map(value => ({ value })),
      factoryId,
      fieldIndex
    })

  return (
    <div
      className="FieldComponent"
      data-active={active}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={fieldRef}>
      <Text
        id={id}
        name={id}
        key={fieldIndex}
        handleChange={handleInputChange}
        placeholder={t(getPlaceholder(field.type, field.visual_placing))}
        value={field.label}
        icon={renderIcon(field.type, field.visual_placing)}
      />
      {active && !dragging && (
        <div className="meta">
          {field.data && isMultiChoice(field) && (
            <OptionsArea
              id={`${id}-textarea`}
              name={`${id}-textarea`}
              placeholder={t('option row 1')}
              handleChange={handleOptionsChange}
              value={field.data.options}
            />
          )}
          <Checkbox
            name={`${id}-rq`}
            id={`${id}-rq`}
            label={t('required')}
            checked={field.required}
            onChange={handleCheckboxChange}
          />
        </div>
      )}
    </div>
  )
}

export default FieldComponent

FieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  factoryId: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  handleFactoryFieldChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFactoryFieldRequiredChange: PropTypes.func,
  handleFactoryFieldTextareaChange: PropTypes.func,
  active: PropTypes.bool,
  dragging: PropTypes.bool,
  fieldRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}
