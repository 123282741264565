import React from 'react'
import PropTypes from 'prop-types'
import './Checkbox.scss'

const CheckIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g id="CheckIcon">
      <path
        d="M3.24882923,9.87529377 L17.7116356,9.87529377 C18.4848343,9.87529377 19.1116356,10.5020951 19.1116356,11.2752938 C19.1116356,12.0484925 18.4848343,12.6752938 17.7116356,12.6752938 L1.84496867,12.6752938 C1.07177,12.6752938 0.444968643,12.0484925 0.444968643,11.2752938 C0.444968643,11.2367214 0.446528556,11.1985134 0.449588738,11.1607293 L0.4457959,4.7419601 C0.445339288,3.96921832 1.07140012,3.34241718 1.8441419,3.34196057 C1.84441749,3.34196041 1.84469308,3.34196032 1.84496867,3.34196032 C2.61830111,3.34196032 3.24533899,3.96862779 3.24579595,4.7419601 L3.24882923,9.87529377 Z"
        transform="translate(9.778302, 8.008627) rotate(-47.000000) translate(-9.778302, -8.008627) "
      />
    </g>
  </svg>
)

export const Checkbox = ({ label, ...props }) => (
  <div className="Checkbox">
    <input {...props} type="checkbox" />
    <label htmlFor={props.id}>
      <div className="box">
        <CheckIcon />
      </div>
      <span>{label}</span>
    </label>
  </div>
)

export default Checkbox

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool
}
