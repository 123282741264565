import './General.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TimeSlots from 'components/TimeSlots'
import GuestLimit from 'components/Settings/General/GuestLimit'
import EventTime from 'components/Settings/General/EventTime'
import EventLocation from 'components/Settings/General/EventLocation'
import { SettingsModule } from 'components/Settings/SettingsModule'
import Text from 'components/Inputs/Text'
import CampaignId from 'components/Settings/General/CampaignId'
import LegalText from 'components/Settings/General/LegalText'
import { OrganizationContext } from 'components/Contexts/OrganizationProvider'

const GeneralSettings = ({
  currentState: {
    name,
    guest_max_limit,
    start_time,
    end_time,
    timeslots_enabled,
    guestlists,
    data: { campaign_id, location, timezone_location, legal_text }
  },
  addTimeSlot,
  removeTimeSlot,
  updateCampaignId,
  updateTimeSlot,
  updateEventName,
  updateLegalText,
  updateEventLocation,
  updateGuestMaxLimit,
  updateStartTime,
  updateEndTime,
  updateTimeZoneLocation
}) => {
  const { t } = useTranslation()
  const { settings } = React.useContext(OrganizationContext)
  const renderCampaignComponent =
    settings &&
    settings.integrations &&
    settings.integrations.sf_integration &&
    settings.integrations.sf_integration.campaign_integration_on === true
  const legalTextAllowed =
    settings &&
    settings.default_event_text &&
    !!settings.default_event_text.legal_text

  return (
    <div className="GeneralSettings">
      <SettingsModule
        title={t('event name')}
        description={t('helpTexts.eventName')}>
        <Text
          value={name}
          handleChange={({ target }) => updateEventName(target.value)}
          placeholder={t('event name')}
          id="eventName"
        />
      </SettingsModule>

      {renderCampaignComponent && (
        <CampaignId
          campaignId={campaign_id}
          updateCampaignId={updateCampaignId}
        />
      )}

      {!timeslots_enabled && (
        <GuestLimit
          guestLimit={guest_max_limit}
          handleGuestCountUpdate={updateGuestMaxLimit}
        />
      )}

      <EventTime
        startTime={start_time}
        endTime={end_time}
        timeZoneLocation={timezone_location}
        handleStartTimeUpdate={updateStartTime}
        handleEndTimeUpdate={updateEndTime}
        handleTimeZoneLocationUpdate={updateTimeZoneLocation}
      />

      {timeslots_enabled && (
        <SettingsModule
          title={t('timeslots')}
          description={t('timeslots description')}>
          <TimeSlots
            guestlists={guestlists}
            addTimeSlot={addTimeSlot}
            removeTimeSlot={removeTimeSlot}
            updateTimeSlot={updateTimeSlot}
          />
        </SettingsModule>
      )}

      <EventLocation
        location={location}
        handleLocationChange={updateEventLocation}
      />

      {legalTextAllowed && (
        <LegalText value={legal_text} updateHandler={updateLegalText} />
      )}
    </div>
  )
}
export default GeneralSettings
