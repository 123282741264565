import React from 'react'

const CheckInIcon = props => (
  <svg
    {...props}
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m9 18c4.9705627 0 9-4.0294373 9-9 0-4.97056275-4.0294373-9-9-9-4.97056275 0-9 4.02943725-9 9 0 4.9705627 4.02943725 9 9 9zm0-2c-3.86599325 0-7-3.1340068-7-7 0-3.86599325 3.13400675-7 7-7 3.8659932 0 7 3.13400675 7 7 0 3.8659932-3.1340068 7-7 7z" />

    <path
      d="m6.5 4.8890873v4h7v2h-9v-6z"
      transform="matrix(.70710678 -.70710678 .70710678 .70710678 -2.942388 8.674621)"
    />
  </svg>
)

export default CheckInIcon
