import React from 'react'
import { isEqual } from 'lodash'

import API from 'misc/api'
import { getCancelSource } from 'misc/api/util'

export const useExtendedLandingPages = eventId => {
  const [website, setWebsite] = React.useState(null)
  const [page, setPage] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasPageChanges, setHasPageChanges] = React.useState(false)
  const [newContentPage, setNewContentPage] = React.useState(null)

  React.useEffect(() => {
    const cancelSource = getCancelSource()

    async function fetchWebsite() {
      try {
        const { data } = await API.getWebsite(eventId, cancelSource.token)
        setWebsite(data?.data || null)
      } catch {}
    }
    fetchWebsite()

    return cancelSource.cancel
  }, [eventId])

  const fetchWebsite = async eventId => {
    const { data } = await API.getWebsite(eventId)
    setWebsite(data?.data || null)

    return data
  }

  const fetchPage = async (
    pageId,
    refreshContent = true,
    cancelToken = undefined
  ) => {
    const { data } = await API.getPage(pageId, cancelToken)

    if (refreshContent) {
      setHasPageChanges(false)
      setNewContentPage(null)
    }

    setPage(data)

    return data
  }

  const handleChangePage = async content => {
    const hasChanges = !isEqual(
      JSON.parse(page.content_editor.contentData),
      content
    )
    setHasPageChanges(hasChanges)
    setNewContentPage(hasChanges ? content : null)
  }

  const savePage = async () => {
    try {
      setIsLoading(true)
      const newContent = JSON.stringify(newContentPage)
      await API.savePageContent(page.page_id, newContent)
      page.content_editor.contentData = newContent

      setHasPageChanges(false)
      setNewContentPage(null)
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  const saveWebsitePagesSettings = async (pages, deleted) => {
    const pagesCreated = pages
      .filter(page => page.id === null)
      .map(page => {
        return {
          page_id: null,
          title: page.title,
          website_id: website.id,
          index: page.index
        }
      })

    const pagesSaved = pages
      .filter(page => page.id !== null)
      .map(page => {
        return {
          page_id: page.id,
          title: page.title,
          index: page.index
        }
      })

    const pagesDeleted = deleted.map(page => {
      return {
        page_id: page.id
      }
    })

    const { data } = await API.syncWebsitePages(
      website.id,
      pagesCreated,
      pagesSaved,
      pagesDeleted
    )

    setWebsite(data?.data || null)
  }

  const resetPageChanges = async () => {
    setPage({ ...page })
    setHasPageChanges(false)
    setNewContentPage(null)
  }

  const setWebsiteHeader = async file => {
    const { data } = await API.setWebsiteHeader(website.id, file)
    setWebsite(data?.data || null)
  }

  const state = {
    website,
    page,
    hasPageChanges,
    newContentPage,
    isLoading
  }

  const handlers = {
    fetchWebsite,
    fetchPage,
    savePage,
    handleChangePage,
    resetPageChanges,
    saveWebsitePagesSettings,
    setWebsiteHeader
  }

  return [state, handlers]
}
