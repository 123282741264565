import './RestoreEventModal.scss'
import React from 'react'
import API from 'misc/api'
import moment from 'moment-timezone'

import useCountdown from 'misc/useCountdown'
import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

const ModalContent = ({ to }) => {
  const { t } = useTranslation()
  const [timeLeft] = useCountdown(to)

  return (
    <Content>
      <p className="tnum">
        {t('restoreEventText', { value: timeLeft.humanize() })}
      </p>
    </Content>
  )
}

export const RestoreEventModal = ({
  delete_grace_period = 300,
  event,
  handleClose,
  onRestore
}) => {
  const { t } = useTranslation()

  const deletedAt = moment(event.deleted_at).add(delete_grace_period, 'seconds')

  const handleRestore = () => {
    API.restoreEvent(event.event)
      .then(({ status }) => {
        if (status === 200 && typeof onRestore === 'function') {
          onRestore()
        }
      })
      .catch(error => console.error(error))
  }

  return (
    <Modal modalName="RestoreEventModal">
      <Header>{t('restore event')}</Header>
      <ModalContent to={deletedAt} />
      <Actions
        leftActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
        rightActions={
          <Button onClick={handleRestore} type="success">
            {t('restore')}
          </Button>
        }
      />
    </Modal>
  )
}

export default RestoreEventModal
