import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import detectInputType from './misc/detectInputType'

detectInputType()

const {
  REACT_APP_BUGSNAG_API_KEY,
  REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES,
  REACT_APP_BUGSNAG_RELEASE_STAGE
} = process.env

Bugsnag.start({
  apiKey: REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES.split(','),
  releaseStage: REACT_APP_BUGSNAG_RELEASE_STAGE
})

const ErrorBoundary = Bugsnag.getPlugin('react')
const domContainer = document.getElementById('root')

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  domContainer
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
