import './Menu.scss'
import React from 'react'

export const MenuItem = ({ isActive, children, url, title, ...props }) => {
  return (
    <li className={`MenuItem${isActive ? ' active' : ''}`}>
      <a href={url} title={title} {...props}>
        {children || title}
      </a>
    </li>
  )
}

export const Menu = ({ children }) => <ul className="Menu">{children}</ul>

export default Menu
