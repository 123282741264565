import './EmbedVideoModal.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isNumber } from 'lodash'

import Button from 'components/Button'
import Textarea from 'components/Inputs/Textarea'
import { Modal, Header, Content, Actions } from 'components/Modals/Modal'

const EmbedVideoModal = ({ video, handleSubmitModal, handleCloseModal }) => {
  const { t } = useTranslation()
  const [embedVideo, setEmbedVideo] = React.useState('')
  const [error, setError] = React.useState(null)

  useEffect(() => {
    if (video && video.uri && isNumber(video.aspectRatio)) {
      const width = 560
      const height = Math.floor(width / video.aspectRatio)
      setEmbedVideo(
        `<iframe width="${width}" height="${height}" src="${video.uri}"></iframe>`
      )
    } else {
      setEmbedVideo('')
    }
  }, [video])

  const handleSubmit = event => {
    event.preventDefault()

    if (embedVideo === '') {
      handleSubmitModal(null)
      return
    }

    try {
      const parser = new DOMParser()
      const content = parser.parseFromString(embedVideo, 'text/html')
      const iframe = content.querySelector('iframe')

      const uri = iframe.getAttribute('src')
      const width = iframe.getAttribute('width')
      const height = iframe.getAttribute('height')
      const aspectRatio = +width / +height

      handleSubmitModal({
        uri,
        aspectRatio
      })
    } catch {
      setError(t('extendedLandingPages.modalVideo.error'))
    }
  }

  return (
    <Modal modalName="EmbedVideoModal">
      <form className="EmbedVideoModal" onSubmit={handleSubmit}>
        <Header>{t('extendedLandingPages.modalVideo.title')}</Header>
        <Content>
          <p className="EmbedVideoModalDescription">
            {t('extendedLandingPages.modalVideo.description')}
          </p>
          <Textarea
            height={200}
            value={embedVideo}
            handleChange={({ target }) => setEmbedVideo(target.value)}
          />
          {error && <p className="EmbedVideoModalError">{error}</p>}
        </Content>
        <Actions
          leftActions={
            <Button
              type="secondary"
              buttonType="button"
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          }
          rightActions={
            <Button type="primary" buttonType="submit">
              {t('save')}
            </Button>
          }
        />
      </form>
    </Modal>
  )
}

export default EmbedVideoModal

EmbedVideoModal.propTypes = {
  handleSubmitModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired
}
