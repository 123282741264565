import './OverviewGrid.scss'
import React from 'react'
import { withRouter } from 'react-router'
import clsx from 'clsx'

import EventCard from 'components/Cards/EventCard'
import CreateEventCard from 'components/Cards/CreateEventCard'
import EventPlaceholderCard from 'components/Cards/EventPlaceholderCard'

import { useTranslation } from 'react-i18next'

const nrOfDrafts = 3
const nrOfActive = 4

const createEventCards = (eventItems, summary, componentProps) =>
  eventItems.map(event => (
    <EventCard
      event={event}
      to={`/event/${event.event}/${
        event.state === 'DRAFT' ? 'edit' : 'result'
      }`}
      summary={summary}
      src={`${event.preview_image}?modified=${new Date(
        event.last_modified
      ).getTime()}`}
      {...componentProps}
    />
  ))

const createPlaceholderCards = quantity =>
  Array(quantity).fill(<EventPlaceholderCard />)

const OverviewGrid = ({
  className,
  items,
  title,
  right,
  summary,
  createEventButton,
  removeEvent,
  fetchEvents
}) => {
  const eventCards = createEventCards(
    createEventButton ? items.slice(0, nrOfDrafts) : items,
    summary,
    {
      removeEvent,
      fetchEvents
    }
  )
  const placeholderCards = createPlaceholderCards(
    Math.max(
      0,
      (createEventButton ? nrOfDrafts : nrOfActive) - eventCards.length
    )
  )
  const cards = [...eventCards, ...placeholderCards]
  const { t } = useTranslation()

  if (createEventButton) cards.unshift(<CreateEventCard />)

  return (
    <div className={clsx('OverviewGrid', title.toLowerCase(), className)}>
      <div className="OverviewGridHeader">
        <h2 className="OverviewGridHeading">{t(title)}</h2>
        <div className="OverviewGridChildren">{right}</div>
      </div>
      {cards && cards.length > 0 ? (
        <ul className="OverviewGridList">
          {cards.map((card, i) => (
            <li key={i} className="OverviewGridItem">
              {card}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default withRouter(OverviewGrid)
