import './EventDetail.scss'
import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import moment from 'moment-timezone'

import { useEvent } from 'misc/api'

// Components
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import Nav from 'components/Nav'
import EventEdit from 'routes/EventEdit'
import EventPages from 'routes/EventPages'
import EventSettings from 'routes/EventSettings'
import EventResult from 'routes/EventResult'
import EventToolbar from 'components/EventToolbar'
import ChevronIcon from './icons/Chevron'

// Context
import UndoProvider, { UndoContext } from 'components/Contexts/UndoProvider'
import ExtendedLandingPagesProdiver, {
  ExtendedLandingPagesContext
} from 'components/Contexts/ExtendedLandingPagesProdiver'

const EventDetail = props => {
  const { t } = useTranslation()
  const { id, mode } = props.match.params
  const [
    {
      event,
      backgrounds,
      previewLink,
      token,
      isLoading,
      isPublished,
      isDeleted,
      lead_capture_token
    },
    {
      publishEvent,
      duplicateEvent,
      deactivateEvent,
      deleteEvent,
      fetchEvent,
      updateEvent
    }
  ] = useEvent(id)

  const renderExtendedLandingPages =
    event?.extended_landing_pages_enabled === true

  const eventIsDraft = event && event.state === 'DRAFT'

  const navRoutes = [
    {
      to: `/event/${id}/edit`,
      label: t('edit'),
      component: (
        <EventEdit
          id={id}
          backgrounds={backgrounds}
          previewUrls={previewLink && previewLink.preview}
          isEventLoading={isLoading}
        />
      )
    },
    {
      to: `/event/${id}/settings`,
      label: t('settings'),
      component: <EventSettings id={id} event={event} />
    },
    ...(renderExtendedLandingPages
      ? [
          {
            to: `/event/${id}/pages`,
            label: t('pages'),
            component: <EventPages id={id} />
          }
        ]
      : []),
    {
      to: `/event/${id}/result`,
      label: event && t(event.state === 'DRAFT' ? 'activate' : 'result'),
      component: event && (
        <EventResult
          {...{
            id,
            event,
            token,
            lead_capture_token,
            eventIsDraft,
            publishEvent,
            deactivateEvent,
            fetchEvent
          }}
        />
      )
    }
  ]

  const rightNav = (
    <UndoContext.Consumer>
      {props => (
        <EventToolbar
          currentTab={mode}
          isPublished={isPublished}
          event={event}
          previewLink={previewLink && previewLink.external}
          handlePublishEvent={publishEvent}
          handleUnpublishEvent={deactivateEvent}
          handleDeleteEvent={deleteEvent}
          duplicateEvent={duplicateEvent}
          {...props}
        />
      )}
    </UndoContext.Consumer>
  )

  if (isDeleted) {
    return <Redirect to="/" />
  }

  const content = (
    <div className="EventDetail">
      <Header
        dark
        left={
          <div className="EventDetailTitle" title={event && event.name}>
            {event && event.name}
          </div>
        }
        right={rightNav}
      />
      <nav className="EventDetailNavBar">
        <Wrapper>
          <div className="EventDetailContain">
            <Nav
              className={clsx('EventDetailNav', {
                isMuted: !event || event.state === 'DRAFT',
                isLoading: !event
              })}
              items={navRoutes}
              separator={
                event && event.state === 'DRAFT' ? (
                  <ChevronIcon className="EventDetailNavSeparator fadeIn" />
                ) : null
              }
            />
            {event &&
            moment(event.last_modified).diff(
              moment(event.created_at),
              'seconds'
            ) > 0 ? (
              <time
                dateTime={moment(event.last_modified).format()}
                className="EventDetailTime">
                {t('eventEdited', {
                  date: moment
                    .duration(moment(event.last_modified).diff(moment()))
                    .humanize(true, {
                      ss: 60
                    })
                })}
              </time>
            ) : null}
          </div>
        </Wrapper>
      </nav>
      <Switch>
        {navRoutes &&
          navRoutes
            .filter(({ disabled }) => !disabled)
            .map(route => (
              <Route
                key={route.to}
                path={route.to}
                render={() => route.component}
              />
            ))}

        <Redirect to={`/event/${id}/edit`} />
      </Switch>
    </div>
  )

  if (renderExtendedLandingPages) {
    return (
      <ExtendedLandingPagesProdiver eventId={id}>
        <ExtendedLandingPagesContext.Consumer>
          {props => (
            <UndoProvider
              event={event}
              updateEvent={updateEvent}
              isEventLoading={isLoading}
              extendedLandingPages={props}>
              {content}
            </UndoProvider>
          )}
        </ExtendedLandingPagesContext.Consumer>
      </ExtendedLandingPagesProdiver>
    )
  } else {
    return (
      <UndoProvider
        event={event}
        updateEvent={updateEvent}
        isEventLoading={isLoading}>
        {content}
      </UndoProvider>
    )
  }
}

export default withRouter(EventDetail)
