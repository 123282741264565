import './ManagePartyModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

const formatedFields = (guest, fields) => {
  // Connnect fields and guest_data, and sort result after the order_index
  const connectedFields = fields
    .map(field => ({
      ...field,
      value: guest.guest_data[field.key]
    }))
    .sort((curr, next) => curr.order_index - next.order_index)

  // Checks if the title fields exist
  const titleFieldTypes = ['FIRST_NAME', 'LAST_NAME']
  const titleFields = connectedFields.filter(field =>
    titleFieldTypes.includes(field.type)
  )

  // Sort out fields that is not part of the title
  const dataFields = connectedFields.filter(
    field => titleFieldTypes.includes(field.type) === false
  )

  // Connect title string
  const title = titleFields
    .reduce((prev, field) => `${prev} ${field.value}`, '')
    .trim()

  return {
    title,
    dataFields
  }
}

const Guest = ({ guest }) => (
  <div className="Guest">
    {guest.title && <div className="title">{guest.title}</div>}
    <div className="fields">
      {guest.dataFields.map((field, index) => {
        const { label, value = 'No response' } = field
        let parsedValue = value

        if (field.type === 'BOOL') {
          if (field.value === true) {
            parsedValue = 'yes'
          } else {
            parsedValue = 'no'
          }
        }
        return (
          <div className="field" key={index}>
            <div className="label">{label}</div>
            <div className="value">{parsedValue}</div>
          </div>
        )
      })}
    </div>
  </div>
)

export const ManagePartyModal = ({
  leader,
  party,
  leaderFields,
  followerFields,
  handleCloseModal
}) => {
  const leaderData = formatedFields(leader, leaderFields)
  const { t } = useTranslation()

  return (
    <Modal size="medium">
      <div className="ManagePartyModal">
        <Header>{t('manage party')}</Header>
        <Content>
          <Guest guest={leaderData} />

          {party.map((guest, index) => {
            const currentGuest = formatedFields(guest, followerFields)
            return <Guest key={index} guest={currentGuest} />
          })}
        </Content>
        <Actions
          leftActions={
            <Button type="secondary" onClick={handleCloseModal}>
              {t('close')}
            </Button>
          }
        />
      </div>
    </Modal>
  )
}

export default ManagePartyModal
