import './ScrollListModal.scss'
import React from 'react'

import { Modal, Header, Content, Actions } from 'components/Modals/Modal'
import Button from 'components/Button'

import { useTranslation } from 'react-i18next'

const ModalContent = ({ textEntries }) => {
  return (
    <Content>
      <ul className="scroll_container">
        {textEntries.map((entry, index) => {
          return (
            <li className="tnum" key={index}>
              {entry}
            </li>
          )
        })}
      </ul>
    </Content>
  )
}

export const ScrollListModal = ({ title, textEntries, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Modal maxHeight="800" modalName="ScrollListModal">
      <Header>{title}</Header>
      <ModalContent textEntries={textEntries} />
      <Actions
        rightActions={
          <Button onClick={handleClose} type="secondary">
            {t('close')}
          </Button>
        }
      />
    </Modal>
  )
}

export default ScrollListModal
