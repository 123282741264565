import React from 'react'
import clsx from 'clsx'

export const IconCheckmark = ({ handleClick }) => (
  <svg
    onClick={handleClick}
    className={clsx(['IconCheckmark', { 'is-disabled': !handleClick }])}
    data-color="success"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <path
      d="M7.79525947,11.7146105 L16.9119385,2 L19.3960716,4.64705882 L7.80345039,17 
          L7.53434287,16.7132427 L7.50991604,16.7379211 L0.5,9.65579666 
          L3.12871851,7 L7.79525947,11.7146105 Z"
    />
  </svg>
)

export const IconCancel = ({ fill }) => (
  <svg
    data-color="default"
    fill={fill}
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <path
      d="M10,8.67768595 L15.9504132,2.72727273 L17.2727273,4.04958678 L11.322314,10 
          L17.2727273,15.9504132 L15.9504132,17.2727273 L10,11.322314 L4.04958678,17.2727273 
          L2.72727273,15.9504132 L8.67768595,10 L2.72727273,4.04958678 
          L4.04958678,2.72727273 L10,8.67768595 Z"
    />
  </svg>
)

export const IconDelete = ({ handleClick }) => (
  <svg
    onClick={handleClick}
    className={!handleClick ? 'is-disabled' : null}
    data-color="warning"
    width="20px"
    height="20px"
    viewBox="0 0 20 20">
    <path
      d="M6.5,8 L6.5,16.3333333 
          L13.1666667,16.3333333 L13.1666667,8 
          L6.5,8 Z M4.83333333,8 L4,8 L4,6.33333333 L6.5,6.33333333 
          L6.5,4.66666667 C6.5,3.74619208 7.24619208,3 8.16666667,3 
          L11.5,3 C12.4204746,3 13.1666667,3.74619208 13.1666667,4.66666667 
          L13.1666667,6.33333333 L15.6666667,6.33333333 L15.6666667,8 L14.8333333,8 
          L14.8333333,16.3333333 C14.8333333,17.2538079 14.0871412,18 13.1666667,18 
          L6.5,18 C5.57952542,18 4.83333333,17.2538079 4.83333333,16.3333333 L4.83333333,8 
          Z M8.16666667,4.66666667 L8.16666667,6.33333333 L11.5,6.33333333 L11.5,4.66666667 
          L8.16666667,4.66666667 Z"
    />
  </svg>
)

export const IconUndo = () => (
  <svg data-color="default" width="20px" height="20px" viewBox="0 0 20 20">
    <path
      d="M11.0342485,2 C7.61225631,2 4.69688544,4.17901953 3.58347184,7.23276172 
          L2.45293204,5.2630625 L1,6.1068125 L2.85242718,9.33412109 L3.69126214,10.7955664
          L5.14419417,9.95178125 L8.35266796,8.0885 L7.51383301,6.62708984 L5.26271456,7.934375 
          C5.5623534,7.07716016 6.05167379,6.29152344 6.70935534,5.63023438 
          C7.86453592,4.46796875 9.40061748,3.828125 11.0342485,3.828125 
          C12.6678796,3.828125 14.2039961,4.46796875 15.3591417,5.63023438 
          C16.5146369,6.79214844 17.1507534,8.33726563 17.1507534,9.98046875 
          C17.1507534,11.6236719 16.5146369,13.1687891 15.3591417,14.3307031 
          C14.2039961,15.4929688 12.6678796,16.1328125 11.0342485,16.1328125 
          L11.0342485,17.9609375 C15.4161126,17.9609375 18.9682291,14.3880078 
          18.9682291,9.98046875 C18.9682291,5.57292969 15.4161126,2 11.0342485,2"
    />
  </svg>
)

export const IconGroupEdit = props => (
  <svg
    fill="currentColor"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m1.25250693 10.1809647c.63773102.5314802 1.47156072.8526615 2.38385671.8526615.2969719 0 .58562918-.0340337.86193724-.098244-.08946462.5409512-.13466452 1.098681-.13466452 1.6646873 0 1.4454996.30619235 2.5725966.94710834 3.3774042-.500213.038313-1.07008294.0163895-1.67438106.0163895-2.00830818 0-3.63636364.2421407-3.63636364-2.3155955 0-1.3962603.48516615-2.6482031 1.25250693-3.497303zm12.88519447-2.60823905.109635-.09310558c1.0737876 1.24339983 1.7526636 3.07631133 1.7526636 5.12044943 0 3.665841-2.1833189 3.4041767-4.9121831 3.3913825h-.357452c-2.72886417.0127942-4.91218308.2744585-4.91218308-3.3913825 0-2.0441381.67887596-3.8770496 1.75266356-5.12044943.8933036.77716938 2.06083094 1.24765266 3.33824552 1.24765266 1.225583 0 2.3500152-.43307774 3.2286105-1.15454708l.109635-.09310558zm-10.5377014-2.48181656c1.40581573 0 2.54545455 1.13963882 2.54545455 2.54545455 0 1.40581572-1.13963882 2.54545456-2.54545455 2.54545456s-2.54545455-1.13963884-2.54545455-2.54545456c0-1.40581573 1.13963882-2.54545455 2.54545455-2.54545455zm7.3090909-5.09090909c2.0083082 0 3.6363636 1.62805546 3.6363636 3.63636364s-1.6280554 3.63636363-3.6363636 3.63636363c-2.00830817 0-3.63636363-1.62805545-3.63636363-3.63636363s1.62805546-3.63636364 3.63636363-3.63636364z" />
  </svg>
)

export const IconGroupCancel = props => (
  <svg
    fill="currentColor"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m1.26721897 10.1818736c.64671982.5304692 1.49093934.8516188 2.41459911.8516188.30061115 0 .59280782-.0340168.87250796-.098196-.09032338.5402148-.13614434 1.0982743-.13614434 1.6646204 0 1.4454821.31001974 2.5725654.95894717 3.3773632-.50674054.0383021-1.0836047.0163894-1.69531079.0163894-2.03341198 0-3.68181808.2421377-3.68181808-2.3155675 0-1.3956777.49083271-2.6471565 1.26721897-3.4962283zm6.39826178-2.7023442c.90422636.77706305 2.08645805 1.24763754 3.37997355 1.24763754.5618552 0 1.1027144-.08878385 1.6089793-.25292138-1.1416916 1.052201-1.8544339 2.55237344-1.8544339 4.21695854 0 1.2294801.3888333 2.3692656 1.0516732 3.3059486-.2619311-.0030327-.5315663-.0063332-.8062186-.0063332l-.1809601.0004387c-2.76297492.012794-4.97358527.2744551-4.97358527-3.3913414 0-2.0441134.6873619-3.87700264 1.77457182-5.1203874zm-4.02048085-2.38868202c1.42338839 0 2.57727266 1.13962501 2.57727266 2.54542369 0 1.40579869-1.15388427 2.54542373-2.57727266 2.54542373-1.42338838 0-2.57727266-1.13962504-2.57727266-2.54542373 0-1.40579868 1.15388428-2.54542369 2.57727266-2.54542369zm7.4004544-5.09084738c2.0334119 0 3.681818 1.62803572 3.681818 3.63631956 0 2.00828383-1.6484061 3.63631956-3.681818 3.63631956-2.03341203 0-3.68181813-1.62803573-3.68181813-3.63631956 0-2.00828384 1.6484061-3.63631956 3.68181813-3.63631956z" />
    <path
      d="m18.6499995 9.34 1.2727922 1.2727922-2.0572206 2.0562078 2.0572206 2.0574284-1.2727922 1.2727922-2.0574284-2.0572206-2.0562078 2.0572206-1.2727922-1.2727922 2.057-2.0574284-2.057-2.0562078 1.2727922-1.2727922 2.0562078 2.057z"
      fill="#d13753"
    />
  </svg>
)

export const IconGroupRestore = props => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m1.26721897 10.1818736c.64671982.5304692 1.49093934.8516188 2.41459911.8516188.30061115 0 .59280782-.0340168.87250796-.098196-.09032338.5402148-.13614434 1.0982743-.13614434 1.6646204 0 1.4454821.31001974 2.5725654.95894717 3.3773632-.50674054.0383021-1.0836047.0163894-1.69531079.0163894-2.03341198 0-3.68181808.2421377-3.68181808-2.3155675 0-1.3956777.49083271-2.6471565 1.26721897-3.4962283zm6.39826178-2.7023442c.90422636.77706305 2.08645805 1.24763754 3.37997355 1.24763754.5618552 0 1.1027144-.08878385 1.6089793-.25292138-1.1416916 1.052201-1.8544339 2.55237344-1.8544339 4.21695854 0 1.2294801.3888333 2.3692656 1.0516732 3.3059486-.2619311-.0030327-.5315663-.0063332-.8062186-.0063332l-.1809601.0004387c-2.76297492.012794-4.97358527.2744551-4.97358527-3.3913414 0-2.0441134.6873619-3.87700264 1.77457182-5.1203874zm-4.02048085-2.38868202c1.42338839 0 2.57727266 1.13962501 2.57727266 2.54542369 0 1.40579869-1.15388427 2.54542373-2.57727266 2.54542373-1.42338838 0-2.57727266-1.13962504-2.57727266-2.54542373 0-1.40579868 1.15388428-2.54542369 2.57727266-2.54542369zm7.4004544-5.09084738c2.0334119 0 3.681818 1.62803572 3.681818 3.63631956 0 2.00828383-1.6484061 3.63631956-3.681818 3.63631956-2.03341203 0-3.68181813-1.62803573-3.68181813-3.63631956 0-2.00828384 1.6484061-3.63631956 3.68181813-3.63631956z"
      fill="#547497"
    />
    <path
      d="m16.2928932 9.29289322 3.5355339 3.53553388-3.5355339 3.5355339-.0008932-2.5360678-3.292.0005339v-2l3.292-.0005339z"
      fill="#1aa753"
    />
  </svg>
)
