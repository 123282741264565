import './TableHeader.scss'
import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const TableHeader = ({ fields }) => {
  const { t } = useTranslation()
  return (
    <div className="TableHeader">
      <div className="left">
        {fields &&
          fields.map((field, index) => (
            <div key={index} className="label" title={field.label}>
              {field.label}
            </div>
          ))}
      </div>

      <div className="right">
        <div className="date">{t('date')}</div>
        <div className="checkin">{t('checked in')}</div>
        <div className="guests">{t('guests')}</div>
      </div>
    </div>
  )
}

export default TableHeader

TableHeader.propTypes = {
  fields: PropTypes.array.isRequired
}
