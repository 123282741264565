export const formFactorys = [
  {
    title: 'primary guest',
    optional: false,
    fields: []
  },
  {
    title: 'secondary guests',
    optional: true,
    fields: []
  }
]

export const availableFields = [
  // First Name
  {
    key: 'first_name',
    type: 'FIRST_NAME',
    visual_placing: 'FIRST_NAME',
    label: 'first name'
  },
  // Last Name
  {
    key: 'last_name',
    type: 'LAST_NAME',
    visual_placing: 'LAST_NAME',
    label: 'last name'
  },
  // Company Name
  {
    key: 'company_name',
    type: 'COMPANY_NAME',
    visual_placing: 'COMPANY_NAME',
    label: 'company name'
  },
  // E-mail
  {
    key: 'email',
    type: 'EMAIL',
    visual_placing: 'EMAIL',
    label: 'email'
  },
  // Phone Number
  {
    key: 'phone_number',
    type: 'PHONE_NUMBER',
    visual_placing: 'PHONE_NUMBER',
    label: 'phone number'
  },
  // Text
  {
    key: 'text',
    type: 'TEXT',
    visual_placing: 'TEXT',
    label: 'textfield'
  },
  // Number
  {
    key: 'number',
    type: 'NUMBER',
    visual_placing: 'NUMBER',
    label: 'number'
  },
  // Checkbox
  {
    key: 'checkbox',
    type: 'BOOL',
    visual_placing: 'BOOL',
    label: 'checkbox'
  },
  // Multiple Selection Checkbox
  {
    key: 'multiple_selection_checkbox',
    type: 'SELECT_MULTIPLE_CHOICE',
    visual_placing: 'BOOL',
    label: 'multiple selection',
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  },
  // Dropdown
  {
    key: 'dropdown',
    type: 'SELECT_SINGLE_CHOICE',
    visual_placing: 'SELECT_BOX',
    label: 'dropdown',
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  },
  // Radiobutton
  {
    key: 'radiobutton',
    type: 'SELECT_SINGLE_CHOICE',
    visual_placing: 'RADIO_SELECTION',
    label: 'radio buttons',
    data: {
      options: [{ value: 'option' }, { value: 'option' }]
    }
  }
]
