import React from 'react'
import './Login.scss'
import { Redirect } from 'react-router-dom'
import API from 'misc/api'

import ErrorPage from '../ErrorPage'
import Loader from 'components/Loader'

export class Login extends React.Component {
  state = {
    isAuthenticated: false,
    loading: true
  }

  componentDidMount() {
    API.getCurrentOrganization()
      .then(response => {
        this.setState({
          isAuthenticated: true,
          loading: false
        })
      })
      .catch(e => {
        if (e.response) {
          const { status, data = {} } = e.response
          this.setState({
            loading: false,
            errorStatus: status,
            errorMessage: data ? data.detail : null,
            hasSeeOther: Boolean(data ? data.see_other : false)
          })
        }
      })
  }

  render() {
    const {
      isAuthenticated,
      loading,
      errorMessage,
      errorStatus,
      hasSeeOther
    } = this.state

    // when hasSeeOther is true, an interceptor should already have initiated a redirect
    if (loading || hasSeeOther) {
      return (
        <div className="Login">
          <Loader size="medium" />
        </div>
      )
    }

    if (isAuthenticated) {
      return <Redirect to={'/'} />
    }

    return <ErrorPage message={errorMessage} status={errorStatus} />
  }
}

export default Login
